import Autocomplete from 'components/_common/Autocomplete';
import { useEffect, useState } from 'react';
import { fetchProxyUsersDropdown } from 'store/slices/proxyUsers';

const ProxyUsersDropdown = ({ value, onChange }) => {
    const [usersOptions, setUsersOptions] = useState([]);

    useEffect(() => {
        (async () => {
            const users = await fetchProxyUsersDropdown();
            setUsersOptions(users);
            const def = users.find(i => i.showLabel);
            if (def?.value) {
                onChange(def);
            }
        })();
    }, []);

    return (
        <Autocomplete
            fullWidth
            withFormLabel
            label="Proxy User"
            value={value}
            options={usersOptions}
            onChange={onChange}
            fetchData={query => fetchProxyUsersDropdown({ like_proxy_id: `%${query}%` })}
        />
    );
};

export default ProxyUsersDropdown;
