const List = ({ items }) => (
    <div className="invoice-list">
        {items.map((item, index) => (
            <div className="invoice-list__item" key={index}>
                {item}
            </div>
        ))}
    </div>
);

export default List;
