import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import dayjs from 'dayjs';
import { ReactComponent as Icon } from 'icons/calendar-due.svg';

const CustomDateRangePicker = ({
    value,
    onChange,
    minDateTime,
    maxDateTime,
    onError,
    className = 'custom-date-picker',
}) => {
    const shortcuts = [
        { label: 'Today', getValue: () => [dayjs(), dayjs()] },
        { label: 'Month To Date', getValue: () => [dayjs().startOf('month'), dayjs()] },
        { label: 'Last 24 Hours', keephours: 'yes', getValue: () => [dayjs().subtract(24, 'hours'), dayjs()] },
        { label: 'Last 7 Days', getValue: () => [dayjs().subtract(6, 'day'), dayjs()] },
        { label: 'Last 30 Days', getValue: () => [dayjs().subtract(29, 'day'), dayjs()] },
        { label: 'Last 365 Days', getValue: () => [dayjs().subtract(364, 'day'), dayjs()] },
    ];

    const commonStyle = {
        borderRadius: '4px',
    };

    const handleChange = (values, context) => {
        if (context?.shortcut?.keephours === 'yes') {
            return onChange(values);
        }
        const [start, end] = values;
        onChange([start ? dayjs(start).startOf('day') : null, end ? dayjs(end).endOf('day') : null]);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
                value={value}
                onChange={handleChange}
                minDate={minDateTime ? dayjs(minDateTime) : undefined}
                maxDate={maxDateTime ? dayjs(maxDateTime) : undefined}
                onError={onError}
                slots={{
                    field: SingleInputDateRangeField,
                    openPickerIcon: Icon,
                }}
                slotProps={{
                    textField: {
                        variant: 'outlined',
                        size: 'small',
                        className: `${className} responsive-date-picker`,
                    },
                    shortcuts: { items: shortcuts },
                    popper: {
                        sx: {
                            '.MuiChip-root': commonStyle,
                            '.Mui-selected': commonStyle,
                            '.MuiDateRangePickerDay-dayOutsideRangeInterval': commonStyle,
                            '.MuiDateRangePickerDay-rangeIntervalDayPreviewEnd': commonStyle,
                            '.MuiDateRangePickerDay-rangeIntervalDayPreview': commonStyle,
                            '.MuiDateRangePickerDay-root:last-of-type .MuiDateRangePickerDay-rangeIntervalDayPreview':
                                commonStyle,
                            '.MuiDateRangePickerDay-root:first-of-type .MuiDateRangePickerDay-rangeIntervalDayPreview':
                                commonStyle,
                            '.MuiDateRangePickerDay-day': commonStyle,
                            '.MuiDateRangePickerDay-root:last-of-type, .MuiDateRangePickerDay-lastVisibleCell': {
                                borderTopRightRadius: '4px',
                                borderBottomRightRadius: '4px',
                            },
                            '.MuiDateRangePickerDay-root:first-of-type, .MuiDateRangePickerDay-firstVisibleCell': {
                                borderTopLeftRadius: '4px',
                                borderBottomLeftRadius: '4px',
                            },
                        },
                    },
                }}
                sx={{
                    width: '100%',
                    minWidth: '200px',
                    '& .MuiInputBase-root': {
                        fontSize: '0.75rem',
                        padding: 0,
                    },
                    '& .MuiInputBase-input': {
                        padding: '8px 14px 8px 14px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        textAlign: 'center',
                    },
                    '& .MuiInputBase-input::placeholder': {
                        fontSize: '0.75rem',
                        opacity: 1,
                    },
                    '& .MuiInputAdornment-root': {
                        marginLeft: '0', // Reduce space between text and icon
                    },
                    '& .MuiSvgIcon-root': {
                        fontSize: '1.2rem', // Slightly reduce icon size
                    },
                }}
                format="MM/DD/YY"
            />
        </LocalizationProvider>
    );
};

export default CustomDateRangePicker;
