import ServicesService from 'api/services/ServicesService';
import ConfirmButton from 'components/Buttons/ConfirmButton';
import showMessage from 'components/NoticeProvider/showMessage';
import ControledDialog from 'components/_common/Dialog/ControledDialog';
import React, { useState } from 'react';

const ModalPauseUnpauseService = ({
    open,
    setOpen,
    service_id,
    customContent = null,
    onClick = () => {},
    isPause = false,
}) => {
    const [loading, setLoading] = useState(false);

    const handlePauseUnpause = async () => {
        try {
            setLoading(true);
            await ServicesService.edit({ service_id, service_subscription_is_paused: isPause ? true : false })
                .then(response => {
                    showMessage(response.data.message, response.status);
                })
                .then(() => {
                    setOpen(false);
                    onClick();
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            return false;
        }
    };

    return (
        <ControledDialog
            title={`${isPause ? 'Pause' : 'Unpause'} service`}
            Submit={<ConfirmButton onClick={handlePauseUnpause} loading={loading} />}
            setOpen={setOpen}
            open={open}
        >
            {customContent ||
                (isPause ? (
                    <div>
                        <h5 className="mb-24">Are you sure you want to pause this subscription?</h5>
                        <p className="mb-24">
                            Once paused, new subscription top ups will be paused and subscription invoices will be
                            automatically voided.
                        </p>
                        <p>Data already on your account will be untouched and available to use.</p>
                    </div>
                ) : (
                    <div>
                        <h5>Are you sure you want to unpause this subscription?</h5>
                    </div>
                ))}
        </ControledDialog>
    );
};

export default ModalPauseUnpauseService;
