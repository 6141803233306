import { convertBandwidth } from 'helpers';
import '../styles.scss';
import { GB, MB } from 'helpers/constants';
import Label from 'components/_common/Label';

const getColor = (value, limit) => {
    if (value / limit >= 0.7) {
        return 'red';
    }
    if (value / limit >= 0.5) {
        return 'orange';
    }
    return 'green';
};

const ProxyUserBandwidthUsageCell = ({ value, limit }) => {
    const width = limit > 0 ? (value / limit) * 100 : 0;
    const color = limit > 0 ? getColor() : '';

    const isMB = limit < 100000000 && limit !== null;
    const isLimitSmall = limit < 1000000 && limit !== null;

    if (!limit && limit !== null) {
        return <Label color="red" text="No Residential Access" />;
    }

    return (
        <div className="log-chart-cell">
            <div className={limit === null || isLimitSmall ? 'usage-no-limit' : 'usage'}>
                {isLimitSmall ? (
                    <div className="usage__value">0</div>
                ) : (
                    <>
                        <div className="usage__value">
                            {convertBandwidth(value, isMB ? MB : GB, 'byte', 2)} {isMB ? MB : GB}
                        </div>
                        <div className="usage__divider">/</div>
                        <div className="usage__limit">
                            {limit === null ? (
                                <span>∞</span>
                            ) : (
                                `${convertBandwidth(limit, isMB ? MB : GB, 'byte', 2)} ${isMB ? MB : GB}`
                            )}
                        </div>
                    </>
                )}
            </div>
            {!isLimitSmall && limit !== null && (
                <div className="progress-bar">
                    <div className={`progress-bar__value ${color}`} style={{ width: `${width}%` }}></div>
                </div>
            )}
        </div>
    );
};

export default ProxyUserBandwidthUsageCell;
