import TextInput from 'components/_common/TextInput';
import { useState } from 'react';
import AccountSettingsCard from '../Items/AccountSettingsCard';
import { useDispatch, useSelector } from 'react-redux';
import { customerRetrieve, selectCustomer } from 'store/slices/customer';
import CustomerService from 'api/services/CustomerService';
import showMessage from 'components/NoticeProvider/showMessage';
import { useFormik } from 'formik';
import { editUserInfoValidationSchema } from 'helpers/validations';
import Modal2FA from 'components/Modals/Modal2FA';
import LanguagesDropdown from '../Items/LanguagesDropdown';
import ActionButtons from '../Items/ActionButtons';
import { getChangedParams } from 'helpers';
import _ from 'lodash';

const General = () => {
    const dispatch = useDispatch();
    const { customer_id, customer_email_address, customer_first_name, customer_last_name, customer_iso_language_code } =
        useSelector(selectCustomer);
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState('');
    const [fields, setFields] = useState(null);
    const [open, setOpen] = useState(false);

    const initialValues = {
        customer_email_address,
        customer_first_name,
        customer_last_name,
        customer_iso_language_code,
    };

    const formik = useFormik({
        initialValues,
        validationSchema: editUserInfoValidationSchema,
        onSubmit: async () => {
            const params = getChangedParams(initialValues, formik.values);
            try {
                setLoading(true);
                if (params.hasOwnProperty('customer_email_address')) {
                    await CustomerService.edit(params)
                        .catch(({ response }) => {
                            const { two_factor_authentication_public_key } = response.data.data;
                            setLoading(false);
                            if (response.status === 499) {
                                setFields({ ...params, two_factor_authentication_public_key });
                                setOpen(true);
                            }
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                } else {
                    await CustomerService.edit(params)
                        .then(response => {
                            setLoading(false);
                            showMessage(response.data.message, response.status);
                        })
                        .then(() => {
                            // @ts-ignore
                            dispatch(customerRetrieve());
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                }
            } catch (e) {
                return false;
            }
        },
    });

    const disabled = _.isEqual(initialValues, formik.values);

    const desc = `Customer ID: ${customer_id}`;

    const handleFinalSubmit = async () => {
        const params = { ...fields, two_factor_authentication_private_key: otp };
        try {
            setLoading(true);
            await CustomerService.edit(params)
                .then(response => {
                    setLoading(false);
                    showMessage(response.data.message, response.status);
                    setOpen(false);
                    // @ts-ignore
                    dispatch(customerRetrieve());
                })
                .catch(() => {
                    setOtp('');
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            return false;
        }
    };
    const handleDiscardChanges = () => {
        formik.resetForm();
    };

    const handleSubmit = () => formik.handleSubmit();

    return (
        <form onSubmit={formik.handleSubmit} className="content-container">
            <AccountSettingsCard title="Account details" desc={desc}>
                <div className="inputs-row">
                    <TextInput
                        withFormik
                        name="customer_first_name"
                        label="First name"
                        value={formik.values.customer_first_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.customer_first_name && Boolean(formik.errors.customer_first_name)}
                        helperText={formik.touched.customer_first_name && formik.errors.customer_first_name}
                    />
                    <TextInput
                        withFormik
                        name="customer_last_name"
                        label="Last name"
                        value={formik.values.customer_last_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.customer_last_name && Boolean(formik.errors.customer_last_name)}
                        helperText={formik.touched.customer_last_name && formik.errors.customer_last_name}
                    />
                    <TextInput
                        withFormik
                        name="customer_email_address"
                        label="Email"
                        value={formik.values.customer_email_address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.customer_email_address && Boolean(formik.errors.customer_email_address)}
                        helperText={formik.touched.customer_email_address && formik.errors.customer_email_address}
                    />
                    <LanguagesDropdown name="customer_iso_language_code" value={formik.values.customer_iso_language_code} onChange={formik.handleChange} />
                </div>
            </AccountSettingsCard>

            <ActionButtons
                loading={loading}
                handleSubmit={handleSubmit}
                disabled={disabled}
                handleDiscardChanges={handleDiscardChanges}
            />

            <Modal2FA
                title="Confirm email changing"
                text="Are you sure you want to change email?"
                otp={otp}
                open={open}
                setOtp={setOtp}
                loading={loading}
                setOpen={setOpen}
                onSubmit={handleFinalSubmit}
            />
        </form>
    );
};

export default General;
