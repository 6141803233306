import Tabs from 'components/_common/Tabs';
import { useState } from 'react';
import PageHeader from 'components/PageHeader';
import SecurityTab from './Tabs/Security';
import { useSelector } from 'react-redux';
import { selectIsUser } from 'store/slices/customer';
import Spinner from 'components/_common/Spinner';
import General from './Tabs/General';

const tabsData = [
    { value: '1', label: 'General', content: <General /> },
    { value: '2', label: 'Security', content: <SecurityTab /> },
];

const AccountSettings = () => {
    const isUser = useSelector(selectIsUser);
    const [value, setActiveTab] = useState(tabsData[0].value);

    const handleChange = (event, newValue) => setActiveTab(newValue);

    return (
        <div className="account-settings-content">
            <PageHeader title="Account settings" />
            {isUser ? (
                <Tabs tabs={tabsData} onChange={handleChange} value={value} className="without-padding" />
            ) : (
                <Spinner />
            )}
        </div>
    );
};

export default AccountSettings;
