import { createTheme } from '@mui/material';

const theme = createTheme({
    typography: {
        fontFamily: ['Space Grotesk', 'sans-serif'].join(','),
    },
    palette: {
        primary: {
            light: '#8d84f2',
            main: '#776cef',
            dark: '#3c32a7',
            contrastText: '#fff',
        },
        error: {
            light: '#F27A72',
            main: '#EB392D',
            dark: '#A72820',
            contrastText: '#fff',
        },
        // secondary: {
        //     light: '#ff7961',
        //     main: '#f44336',
        //     dark: '#ba000d',
        //     contrastText: '#000',
        // },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                sizeLarge: {
                    height: '40px',
                    fontSize: '16px',
                    fontWeight: 700,
                    justifyContent: 'center',
                },
                sizeMedium: {
                    height: '32px',
                    fontSize: '14px',
                    fontWeight: 700,
                    justifyContent: 'center',
                },
                sizeSmall: {
                    height: '28px',
                    fontSize: '14px',
                    fontWeight: 700,
                    justifyContent: 'center',
                },
                root: {
                    fontSize: 'inherit',
                    letterSpacing: 0,
                    minHeight: 'auto',
                    textTransform: 'inherit',
                    justifyContent: 'flex-start',
                    color: '#1F2A37',
                    minWidth: 'auto',
                    lineHeight: 1,
                    padding: '0 16px',
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: 'inherit',
                    backgroundColor: 'var(--ping-Brand-brand-900)',
                    padding: 0,
                },
                arrow: {
                    color: 'var(--ping-Brand-brand-900)',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                },
            },
        },
        MuiBadge: {
            styleOverrides: {
                // Name of the slot
                badge: {
                    fontSize: '12px',
                    letterSpacing: 0,
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    minHeight: '36px',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    minHeight: '36px',
                    padding: '0',
                    minWidth: 'auto',
                    textTransform: 'inherit',
                    fontSize: 'inherit',
                    letterSpacing: 0,
                },
            },
        },
        MuiButtonBase: {
            defaultProps: {
                // The props to apply
                // disableRipple: false, // No more ripple, on the whole application 💣!
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    alignItems: 'center',
                    padding: '8px 12px',
                    textTransform: 'inherit',
                    fontSize: '14px',
                    letterSpacing: '-0.2px',
                    fontWeight: 500,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    alignItems: 'center',
                    color: 'inherit',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                inputSizeSmall: {
                    fontSize: '14px',
                },
                root: {
                    fontSize: 'inherit',
                    lineHeight: 'inherit',
                    letterSpacing: 0,
                    color: 'inherit',
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    backgroundColor: '#ffffff',
                    fontWeight: 500,
                    paddingTop: '6px',
                    paddingBottom: '6px',
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {},
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    color: 'var(--ping-Grey-grey-800)',
                    fontWeight: 700,
                    letterSpacing: '0.2px',
                    marginBottom: '4px',
                    display: 'block',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    paddingTop: 0,
                    paddingBottom: 0,
                    color: 'var(--ping-Grey-grey-800)',
                    fontWeight: 500,
                    fontSize: '16px',
                    '::placeholder': {
                        fontSize: '16px',
                        fontWeight: 500,
                        color: 'var(--ping-Grey-grey-500)',
                    },
                },
                inputSizeSmall: {
                    height: '28px',
                    fontSize: '14px',
                    fontWeight: 500,
                    paddingTop: 0,
                    paddingBottom: 0,
                },
            },
        },
        MuiTextField: {
            styleOverrides: {},
        },
        MuiDialog: {
            styleOverrides: {
                paperWidthMd: '',
                root: {},
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {},
            },
        },
    },
});

export default theme;
