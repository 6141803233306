import React from 'react';
import './styles.scss'
import { getServiceIcon } from 'helpers/services';

const ServiceDataLine = ({ type, name, id = null }) => {
    const icon = getServiceIcon(type);
    const ID = `ID: ${id}`;

    return (
        <div className="service-line">
            <div className="service-line__icon">{icon}</div>
            <div className="service-line__content">
                <div className="service-line-title">{name}</div>
                {id && (
                    <div className="service-line-info">
                        <span>{ID}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ServiceDataLine;
