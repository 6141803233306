import Table from 'components/_common/Table';
import TextCell from 'components/_common/TableCells/TextCell';

import ControledDialog from 'components/_common/Dialog/ControledDialog';
import React, { useState } from 'react';

const NoDataComponent = () => {};

const ModalReplacementsDetails = ({ open, setOpen, details }) => {
    const columns = React.useMemo(
        () => [
            {
                field: 'proxy_replacement_id',
                headerName: 'Replacement ID',
                flex: 1,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'proxy_replacement_ip_address_ipv4',
                headerName: 'Old IPv4',
                flex: 1,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'proxy_replacement_ip_address_ipv6',
                headerName: 'Old IPv6',
                flex: 1,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'proxy_replacement_http_port',
                headerName: 'Old HTTP Port',
                flex: 1,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'proxy_replacement_socks5_port',
                headerName: 'Old SOCKS5 Port',
                flex: 1,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'proxy_replacement_new_ip_address_ipv4',
                headerName: 'New IPv4',
                flex: 1,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'proxy_replacement_new_ip_address_ipv6',
                headerName: 'New IPv6',
                flex: 1,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'proxy_replacement_new_http_port',
                headerName: 'New HTTP Port',
                flex: 1,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'proxy_replacement_new_socks5_port',
                headerName: 'New SOCKS5 Port',
                flex: 1,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
        ],
        [],
    );

    return (
        <ControledDialog title={`Replacement Details`} setOpen={setOpen} open={open} fullWidth={true}>
            <Table
                columns={columns}
                rows={details}
                dataLoading={false}
                fetchData={() => {}}
                total={details?.length}
                noDataComponent={NoDataComponent}
                paginationMode={'client'}
            />
        </ControledDialog>
    );
};

export default ModalReplacementsDetails;
