import { NavLink } from 'react-router-dom';
import { ReactComponent as DashboardIcon } from 'icons/layout-dashboard.svg';
import { ReactComponent as SummaryIcon } from 'icons/select-all.svg';
import { ReactComponent as ProxyListIcon } from 'icons/clipboard-list.svg';
import { ReactComponent as ProxySettingsIcon } from 'icons/users.svg';
import { ReactComponent as ProductsIcon } from 'icons/shop.svg';
import { ReactComponent as ApiKeysIcon } from 'icons/key.svg';
import { ReactComponent as ApiRequestsIcon } from 'icons/code-circle.svg';
import { ReactComponent as BillingIcon } from 'icons/billing.svg';
import { ReactComponent as HelpIcon } from 'icons/help-circle.svg';
import { ReactComponent as ResidentialIcon } from 'icons/service-logo-2-grey.svg';
import { ReactComponent as BookIcon } from 'icons/book.svg';
import Button from '@mui/material/Button';
import React from 'react';
import ROUTES from 'helpers/routes';
import { API_DOCUMENTATION_LINK } from 'helpers/constants';

export const getIcon = to => {
    const icons = {
        [ROUTES.dashboard]: <DashboardIcon />,
        [ROUTES.summary]: <SummaryIcon />,
        [ROUTES.proxyList]: <ProxyListIcon />,
        [ROUTES.proxyUsers]: <ProxySettingsIcon />,
        [ROUTES.products]: <ProductsIcon />,
        [ROUTES.residential]: <ResidentialIcon />,
        [ROUTES.apiKeys]: <ApiKeysIcon />,
        [ROUTES.apiRequests]: <ApiRequestsIcon />,
        [ROUTES.billingSubscriptions]: <BillingIcon />,
        [ROUTES.billingInvoices]: <BillingIcon />,
        [ROUTES.billingPaymentSettings]: <BillingIcon />,
        [ROUTES.billingTransactions]: <BillingIcon />,
        [ROUTES.billingAddress]: <BillingIcon />,
        [API_DOCUMENTATION_LINK]: <BookIcon />,
        undefined: <HelpIcon />,
    };
    return icons[to] || <HelpIcon />;
};

const SidebarNavLink = ({ to, icon = undefined, text, className = '', onClick = undefined }) => {
    return (
        <Button
            to={to}
            fullWidth
            size="small"
            onClick={onClick}
            component={to ? NavLink : undefined}
            startIcon={getIcon(to || icon)}
            className={`sidebar-nav-link ${className}`}
        >
            {text}
        </Button>
    );
};

export default SidebarNavLink;
