import $api from 'api/http';
import urls from 'api/urls-config.json';

export default class AuthService {
    static async login(params) {
        return $api.post(urls.auth.login, params);
    }
    static async registration(params) {
        return $api.post(urls.auth.signup, params);
    }
    static async logout() {
        return $api.post(urls.auth.logout);
    }
    static async logoutAll() {
        return $api.post(urls.auth.logout_all);
    }
    static async passwordReset(params) {
        return $api.post(urls.auth.password_reset, params);
    }
}
