import UserInfo from './Items/UserInfo';

const SidebarFooter = () => {
    return (
        <div className="sidebar-footer">
            <UserInfo />
        </div>
    );
};

export default SidebarFooter;
