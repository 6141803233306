import InfoBanner from 'components/InfoBanner';
import '../styles.scss';
import GenerateProxyForm from '../items/GenerateProxyForm';
import TerminalProxies from '../items/TerminalProxies';
import { useEffect, useState } from 'react';
import { fetchResidentialSummary, selectMetrics } from 'store/slices/services';
import { useDispatch, useSelector } from 'react-redux';
import MessageCard from 'components/MessageCard';
import ROUTES from 'helpers/routes';
import { selectActiveResidentialServiceId } from 'store/slices/customer';
import ServicesService from 'api/services/ServicesService';
import ModalReconfigureService from 'components/Modals/ModalReconfigureService';
import ModalPauseUnpauseService from 'components/Modals/ModalPauseUnpauseService';

const ConfiguratorTab = () => {
    const dispatch = useDispatch();
    const metrics = useSelector(selectMetrics);
    const active_residential_service_id = useSelector(selectActiveResidentialServiceId);
    const [isTopUpLoading, setIsTopUpLoading] = useState(false);
    const [topUpData, setTopUpData] = useState(null);
    const [isUnpauseModalOpen, setIsUnpauseModalOpen] = useState(false);
    const [isTopUpModalOpen, setIsTopUpModalOpen] = useState(false);

    const continueTopUp = async () => {
        if (active_residential_service_id) {
            try {
                setIsTopUpLoading(true);
                await ServicesService.reconfigureCalculate({ service_id: active_residential_service_id })
                    .then(response => {
                        setTopUpData({ ...response.data, service_id: active_residential_service_id });
                    })
                    .finally(() => {
                        setIsTopUpLoading(false);
                    });
            } catch (error) {
                return false;
            }
        }
    };

    const handleTopUp = async () => {
        if (active_residential_service_id) {
            try {
                setIsTopUpLoading(true);
                const response = await ServicesService.retrieve(active_residential_service_id);
                setIsTopUpLoading(false);
                const isPaused = response?.data?.data?.[0]?.service_subscription_is_paused;
                if (isPaused) {
                    setIsUnpauseModalOpen(true);
                } else {
                    setIsTopUpModalOpen(true);
                    await continueTopUp();
                }
            } catch (error) {
                return false;
            }
        }
    };

    useEffect(() => {
        //@ts-ignore
        dispatch(fetchResidentialSummary());
        //
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {+metrics?.find(metric => metric.title === 'usage')?.left === 0 && (
                <div className="mb-24">
                    <MessageCard
                        buttonText="Buy Now"
                        customButtonStyles={{ display: 'flex', width: 200, marginLeft: 'auto' }}
                        type="bad"
                        message="You don’t have any residential bandwidth."
                        to={`${ROUTES.products}/residential`}
                    />
                </div>
            )}
            {metrics?.find(metric => metric.title === 'usage')?.isNeedTopUp && (
                <div className="mb-24">
                    <MessageCard
                        buttonText="Top Up"
                        customStyles={{ display: 'flex', gap: 5, height: 100 }}
                        customButtonStyles={{ display: 'block', width: 200, marginLeft: 'auto' }}
                        type="bad"
                        message="You are close to running out of bandwidth, top up now to continue using Residential proxies."
                        isLoading={isTopUpLoading}
                        onClick={handleTopUp}
                    />
                </div>
            )}
            <div className="configurator-tab">
                <GenerateProxyForm />
                <TerminalProxies />
            </div>
            <InfoBanner />
            <ModalReconfigureService
                setOpen={setIsTopUpModalOpen}
                open={isTopUpModalOpen}
                existingSecondData={{ ...topUpData, service_id: active_residential_service_id }}
                isExistingLoading={isTopUpLoading}
                isResidential={true}
                title="Top Up Now"
                successMessage="Top Up is complete"
            />
            <ModalPauseUnpauseService
                open={isUnpauseModalOpen}
                setOpen={setIsUnpauseModalOpen}
                customContent={<p>Service is paused. Please unpause the service to reconfigure.</p>}
                service_id={active_residential_service_id}
                onClick={continueTopUp}
            />
        </div>
    );
};

export default ConfiguratorTab;
