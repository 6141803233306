import * as React from 'react';
import { Input as BaseInput } from '@mui/base/Input';
import { styled } from '@mui/system';
import './styles.scss';
import { FormControl, FormHelperText, FormLabel } from '@mui/material';

const InputElement = styled('input')(
    ({ theme }) => `
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
    padding: 8px 0px;
    border-radius: 4px;
    text-align: center;
    color: var(--ping-Grey-grey-800);
    background: #fff;
    border: 1px solid var(--ping-Grey-grey-200);

    &:hover:not(:disabled):not(:focus) {
        border-color: var(--ping-Grey-grey-800);
    }

    &:focus {
        border-color: var(--ping-Brand-brand-400);
        box-shadow: 0 0 0 1px var(--ping-Brand-brand-400);
    }
    &:disabled{
        color: var(--ping-Grey-grey-600);
    }

    // firefox
    &:focus-visible {
        outline: 0;
    }
    `,
);

const OTPInput = ({ length = 8, value, onChange, label = '', error = false, disabled = false, sizeBig = false }) => {
    const inputRefs = React.useRef(new Array(length).fill(null));

    const focusInput = targetIndex => {
        const targetInput = inputRefs.current[targetIndex];
        targetInput.focus();
    };

    const selectInput = targetIndex => {
        const targetInput = inputRefs.current[targetIndex];
        targetInput.select();
    };

    const handleKeyDown = (event, currentIndex) => {
        switch (event.key) {
            case 'ArrowUp':
            case 'ArrowDown':
            case ' ':
                event.preventDefault();
                break;
            case 'ArrowLeft':
                event.preventDefault();
                if (currentIndex > 0) {
                    focusInput(currentIndex - 1);
                    selectInput(currentIndex - 1);
                }
                break;
            case 'ArrowRight':
                event.preventDefault();
                if (currentIndex < length - 1) {
                    focusInput(currentIndex + 1);
                    selectInput(currentIndex + 1);
                }
                break;
            case 'Delete':
                event.preventDefault();
                onChange(prevOtp => {
                    const otp = prevOtp.slice(0, currentIndex) + prevOtp.slice(currentIndex + 1);
                    return otp;
                });

                break;
            case 'Backspace':
                event.preventDefault();
                if (currentIndex > 0) {
                    focusInput(currentIndex - 1);
                    selectInput(currentIndex - 1);
                }

                onChange(prevOtp => {
                    const otp = prevOtp.slice(0, currentIndex) + prevOtp.slice(currentIndex + 1);
                    return otp;
                });
                break;

            default:
                break;
        }
    };

    const handleChange = (event, currentIndex) => {
        const currentValue = event.target.value;
        let indexToEnter = 0;

        while (indexToEnter <= currentIndex) {
            if (inputRefs.current[indexToEnter].value && indexToEnter < currentIndex) {
                indexToEnter += 1;
            } else {
                break;
            }
        }
        onChange(prev => {
            const otpArray = prev.split('');
            const lastValue = currentValue[currentValue.length - 1];
            otpArray[indexToEnter] = lastValue;
            return otpArray.join('');
        });
        if (currentValue !== '') {
            if (currentIndex < length - 1) {
                focusInput(currentIndex + 1);
            }
        }
    };

    const handleClick = (event, currentIndex) => {
        selectInput(currentIndex);
    };

    const handlePaste = (event, currentIndex) => {
        event.preventDefault();
        const clipboardData = event.clipboardData;

        // Check if there is text data in the clipboard
        if (clipboardData.types.includes('text/plain')) {
            let pastedText = clipboardData.getData('text/plain');
            pastedText = pastedText.substring(0, length).trim();
            let indexToEnter = 0;

            while (indexToEnter <= currentIndex) {
                if (inputRefs.current[indexToEnter].value && indexToEnter < currentIndex) {
                    indexToEnter += 1;
                } else {
                    break;
                }
            }

            const otpArray = value.split('');

            for (let i = indexToEnter; i < length; i += 1) {
                const lastValue = pastedText[i - indexToEnter] ?? ' ';
                otpArray[i] = lastValue;
            }

            onChange(otpArray.join(''));
        }
    };

    const className = `custom-otp ${error ? 'custom-otp--incorrect' : ''} ${disabled ? 'custom-otp--disabled' : ''} ${
        sizeBig ? 'custom-otp--big' : ''
    }`;

    return (
        <FormControl fullWidth className={className}>
            {label && <FormLabel focused={false}>{label}</FormLabel>}
            <div className="custom-otp__inputs">
                {new Array(length).fill(null).map((_, index) => (
                    <BaseInput
                        error={error}
                        key={index}
                        slots={{
                            input: InputElement,
                        }}
                        aria-label={`Digit ${index + 1} of OTP`}
                        slotProps={{
                            input: {
                                ref: ele => {
                                    inputRefs.current[index] = ele;
                                },
                                onKeyDown: event => handleKeyDown(event, index),
                                onChange: event => handleChange(event, index),
                                onClick: event => handleClick(event, index),
                                onPaste: event => handlePaste(event, index),
                                value: value[index] ?? '-',
                                disabled: disabled,
                                className: value[index] ? 'custom-otp-input-filled' : '',
                            },
                        }}
                    />
                ))}
            </div>
            {error && <FormHelperText>Incorrect code, try again</FormHelperText>}
        </FormControl>
    );
};

export default OTPInput;
