import './styles.scss';

const PageHeader = ({ title, right = undefined }) => {
    return (
        <div className="page-header">
            <h4 className="page-header__title">{title}</h4>
            <div className="page-header__right">{right}</div>
        </div>
    );
};

export default PageHeader;
