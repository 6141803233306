import './styles.scss';
import { ReactComponent as Icon } from 'icons/colored/circle-check-filled.svg';

const List = ({ items, columns = 1, className = '' }) => (
    <ul className={`custom-list ${className}`} data-columns={columns}>
        {items.map((item, index) => (
            <li key={index}>
                <Icon />
                <span>{item}</span>
            </li>
        ))}
    </ul>
);

export default List;
