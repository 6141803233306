import $api from 'api/http';
import urls from 'api/urls-config.json';

export default class TransactionsService {
    static async transactionsSearch(params) {
        return $api.get(urls.transactions.search, {params});
    }
    static async balanceTransactionSearch(params) {
        return $api.get(urls.balance_transactions.search, {params});
    }
}
