import PaymentService from 'api/services/PaymentService';
import ConfirmButton from 'components/Buttons/ConfirmButton';
import ExpiryDatePicker from 'components/ExpiryDatePicker';
import showMessage from 'components/NoticeProvider/showMessage';
import ControledDialog from 'components/_common/Dialog/ControledDialog';
import RadioGroupCards from 'components/_common/RadioGroupCards';
import TextInput from 'components/_common/TextInput';
import dayjs from 'dayjs';
import { delay } from 'helpers';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchBillingPayments } from 'store/slices/billingPaymentSettings';

const ModalEditPaymentCard = ({ setOpen, open, data }) => {
    const dispatch = useDispatch();
    const { id, name, is_default, expiry_month, expiry_year, last_four } = data;
    const [loading, setLoading] = useState(false);
    const [isDefault, setIsDefault] = useState(false);
    const [cardName, setName] = useState('');
    const [hasError, setError] = useState('');

    const [expiryDate, setExpiryDate] = useState('');
    
    const optionsDefault = [
        {
            value: true,
            label: 'Yes',
        },
        {
            value: false,
            label: 'No',
        },
    ];

    const handleSetIsDefault = value => {
        setIsDefault(value === 'true');
    };

    useEffect(() => {
        if (id) {
            const date = dayjs(new Date(expiry_year, expiry_month - 1));
            // @ts-ignore
            setExpiryDate(date);
            setIsDefault(is_default);
            setName(name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const initialValues = { is_default, name, expiry_month, expiry_year };

    const handleEdit = async () => {
        const params = getNewData();
        try {
            setLoading(true);
            await PaymentService.edit(params)
                .then(response => {
                    showMessage(response.data.message, response.status);
                    setLoading(false);
                })
                .then(async () => {
                    await delay(500);
                    // @ts-ignore
                    dispatch(fetchBillingPayments({ payment_method_status: 'attached' }));
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            return false;
        }
    };

    const isDefaultChanged = initialValues.is_default !== isDefault;

    const nameChanged = initialValues.name !== cardName;
    // @ts-ignore
    const monthChanged = initialValues.expiry_month !== expiryDate.$M + 1;
    // @ts-ignore
    const yearChanged = initialValues.expiry_year !== expiryDate.$y;

    const disabled =
        (!isDefaultChanged && !nameChanged && !monthChanged && !yearChanged) || !!hasError || !cardName.length;

    const getNewData = () => {
        const payload = { id: id };

        if (isDefaultChanged) payload.payment_method_is_default = isDefault;
        if (nameChanged) payload.payment_method_billing_name = cardName;
        // @ts-ignore
        if (monthChanged) payload.payment_method_expiry_month = expiryDate.$M + 1;
        // @ts-ignore
        if (yearChanged) payload.payment_method_expiry_year = expiryDate.$y;

        return payload;
    };

    return (
        <ControledDialog
            open={open}
            setOpen={setOpen}
            title={`Edit payment card **** ${last_four}`}
            Submit={<ConfirmButton loading={loading} disabled={disabled} onClick={handleEdit} />}
        >
            <TextInput error={!cardName.length} value={cardName} onChange={setName} label="Name" />
            <ExpiryDatePicker value={expiryDate} onChange={setExpiryDate} label="Expiry Date" onError={setError} />
            <RadioGroupCards
                    horizontal
                    small
                    label="Default"
                    items={optionsDefault}
                    value={isDefault}
                    onChange={handleSetIsDefault}
                />
        </ControledDialog>
    );
};

export default ModalEditPaymentCard;
