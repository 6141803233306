import * as React from 'react';
import AccordionComponent from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ReactComponent as Icon } from 'icons/chevron-down.svg';
import './styles.scss';

const AccordionItem = ({ title, children }) => (
    <div className="custom-accordion">
        <AccordionComponent>
            <AccordionSummary expandIcon={<Icon />}>{title}</AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </AccordionComponent>
    </div>
);

export default AccordionItem;
