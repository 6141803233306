import EmptyPageState from 'components/EmptyPageState';
import Button from 'components/_common/Button';
import ROUTES from 'helpers/routes';
import { ReactComponent as Icon } from 'icons/colored/empty_icon_summary.svg';

const NoData = () => (
    <EmptyPageState Icon={<Icon />} title="You don`t have any active services">
        <Button to={ROUTES.products}>Explore proxy products</Button>
    </EmptyPageState>
);

export default NoData;
