import { useEffect, useState } from 'react';
import Tabs from 'components/_common/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import AllMessages from './AllMessages';
import UnreadMessages from './UnreadMessages';
import { notificationSearch, selectCounter, selectNotificationsLoading } from 'store/slices/notification';
import MarkAsReadButton from './Items/MarkAsReadButton';
import Spinner from 'components/_common/Spinner';
import NotificationService from 'api/services/NotificationService';
import showMessage from 'components/NoticeProvider/showMessage';

const tabsData = [
    { value: '1', label: 'Unread', content: <UnreadMessages /> },
    { value: '2', label: 'All', content: <AllMessages /> },
];

const NotificationsContent = () => {
    const dispatch = useDispatch();
    const count = useSelector(selectCounter);
    const loading = useSelector(selectNotificationsLoading);

    const [value, setActiveTab] = useState(tabsData[0].value);

    const handleChange = (event, newValue) => setActiveTab(newValue);

    const handleClick = async () => {
        setActiveTab(tabsData[0].value);
        try {
            await NotificationService.markRead().then(response => {
                // @ts-ignore
                dispatch(notificationSearch());
                showMessage(response.data.message, response.status);
            });
        } catch (e) {
            return false;
        }
    };

    useEffect(() => {
        // @ts-ignore
        dispatch(notificationSearch());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="notifications-content">
            <div className="notifications-content-header">
                <div className="h6">Notifications</div>
                {!!count && <MarkAsReadButton onClick={handleClick} />}
            </div>
            <div className="notifications-content-body">
                {loading ? (
                    <div className="messages-list">
                        <Spinner />
                    </div>
                ) : (
                    <Tabs tabs={tabsData} onChange={handleChange} value={value} />
                )}
            </div>
        </div>
    );
};

export default NotificationsContent;
