import PaymentService from 'api/services/PaymentService';
import Button from 'components/_common/Button';
import { useState } from 'react';

const AddNewMethodButton = ({ variant = 'contained', className = '', text = 'Add new method' }) => {
    const [loading, setLoading] = useState(false);

    const handleClick = async () => {
        try {
            setLoading(true);
            await PaymentService.setup()
                .then(response => {
                    const redirect_url = response.data.data.redirect_url;
                    window.open(redirect_url, '_blank');
                    setLoading(false);
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            return false;
        }
    };
    return (
        <Button iconName="plus" loading={loading} onClick={handleClick} variant={variant} className={className}>
            {text}
        </Button>
    );
};

export default AddNewMethodButton;
