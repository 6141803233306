import { Link, NavLink } from 'react-router-dom';
import { ReactComponent as Icon } from 'icons/external-link.svg';

const DropdownLinks = ({ items, children, data = null, className }) => (
    <div className={`dropdown-links ${className}`}>
        {children}
        <div className="dropdown-links-items">
            {items.map(({ to, onClick, name, blank }) =>
                onClick ? (
                    <div key={name} onClick={() => onClick(data)} className="dropdown-links-item">
                        {name}
                    </div>
                ) : blank ? (
                    <Link key={to + name} to={to} target="_blank" className="dropdown-links-item">
                        {name}
                        <Icon />
                    </Link>
                ) : (
                    <NavLink key={to + name} to={to} className="dropdown-links-item">
                        {name}
                    </NavLink>
                ),
            )}
        </div>
    </div>
);

export default DropdownLinks;
