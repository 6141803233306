import Radio from '../Radio';

const RadioCard = ({ value, label, checked, additional, disabled = false, small }) => {
    const Label = additional ? (
        <span className={`special-radio-label`}>
            <span className="special-radio-label__label">{label}</span>
            <span className="special-radio-label__additional">{additional}</span>
        </span>
    ) : (
        label
    );

    return (
        <div className={`radio-card ${checked ? 'checked' : ''} ${small ? 'radio-card-small' : ''}`}>
            <Radio value={value} label={Label} disabled={disabled} />
        </div>
    );
};

export default RadioCard;
