import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';
import 'styles/main.scss';
import theme from 'styles/theme';
import { ThemeProvider } from '@mui/material/styles';
import NoticeProvider from 'components/NoticeProvider';

// dynamic <script> tags
import Tolt from './script/tolt';
//

// Enable UTC for dayjs
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
//

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <NoticeProvider>
                <Tolt />
                <App />
            </NoticeProvider>
        </ThemeProvider>
    </Provider>,
);
