import $api from 'api/http';
import urls from 'api/urls-config.json';

export default class UtilitesService {
    static async languages() {
        return $api.get(urls.utilites.languages);
    }
    static async countries(params) {
        return $api.get(urls.country.search, { params });
    }
    static async cities(params) {
        return $api.get(urls.city.search, { params });
    }
}
