import Button from 'components/_common/Button';
import ROUTES from 'helpers/routes';
import { ReactComponent as Icon } from 'icons/chevron-left.svg';
import './styles.scss';
import OrderSummarySidebar from 'components/Sidebars/OrderSummarySidebar';
import { useEffect, useState } from 'react';
import ProductBox from './ProductBox';
import PlansInfoCard from 'components/PlansInfoCard';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
    selectCountriesOptionsDatacenter,
    selectCountriesOptionsISP,
    selectProducts,
    selectProductsIsLoading,
    setCalculationInfo,
} from 'store/slices/products';
import { filterPaymentParamsByPlatform, service_types } from 'helpers/services';
import { gb_max_real, ip_max, residential_marks } from './constants';
import { convertToCent, getPeriodName, getPriceIDByCoutryCode, getPriceTierAmount, getPeriodOptions, sortPrices } from './helpers';
import useMediaQuery from 'hooks/useMediaQuery';
import WithSidebarLayout from 'Layout/WithSidebarLayout';
import CheckoutService from 'api/services/CheckoutService';
import { withoutEmptyValues } from 'helpers';
import PaymentService from 'api/services/PaymentService';
import BillingInfoCard from '../../components/BillingInfoCard';

const ProductPage = ({ currentProduct }) => {
    const dispatch = useDispatch();
    const isDesktop = useMediaQuery('desktop');
    const [searchParams] = useSearchParams();
    const isLoading = useSelector(selectProductsIsLoading);
    const allProducts = useSelector(selectProducts);
    const isp_options = useSelector(selectCountriesOptionsISP);
    const datacenter_options = useSelector(selectCountriesOptionsDatacenter);

    const [validatedData, setValidatedData] = useState({});

    const [loadingCode, setLoadingCode] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);

    const [discountError, setDiscountError] = useState(false);
    const [state, setState] = useState(1);
    const { product_type, product_prices, title } = currentProduct;
    const period_options = getPeriodOptions(product_prices);
    const sorted_product_prices = sortPrices(product_prices)

    const isGb = product_type === service_types.residential;

    const valueQuery = searchParams.get('value');
    const isValueQueryValid = valueQuery && !Number.isNaN(+valueQuery);

    const countries_options =
        product_type === service_types.isp
            ? isp_options
            : product_type === service_types.datacenter
            ? datacenter_options
            : [];

    const US = countries_options.find(country => country.country_id === 'us');

    const [sliderValue, setSliderValue] = useState(
        isGb
            ? isValueQueryValid
                ? residential_marks.find(mark => mark.realValue === +valueQuery) || residential_marks[0]
                : residential_marks[0]
            : isValueQueryValid
            ? +valueQuery
            : 1,
    );
    const [period, setPeriod] = useState(period_options[0].value);
    const [country, setCountry] = useState(US?.product_instock ? US : countries_options[0]);

    const isNumber = typeof sliderValue === 'number';
    const value = isNumber ? sliderValue : sliderValue.realValue;

    const [pricePerUnit, setPricePerUnit] = useState(0);
    const [finalPrice, setFinalPrice] = useState(0);
    const [quantityText, setQuantityText] = useState(isNumber ? value : sliderValue.label);
    const [currentPriceTiers, setCurrentPriceTiers] = useState(sorted_product_prices?.[period]?.price_tiers);
    const [periodName, setPeriodName] = useState(getPeriodName(sorted_product_prices?.[period]));

    const [discountCode, setDiscountCode] = useState('');
    const [discount, setDiscount] = useState(1);

    const [paymentPlatform, setPaymentPlatform] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [paymentCoin, setPaymentCoin] = useState('');

    useEffect(() => {
        dispatch(setCalculationInfo({}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setCurrentPriceTiers(sorted_product_prices?.[period]?.price_tiers);
        setPeriodName(getPeriodName(sorted_product_prices?.[period]));
    }, [isLoading, period, sorted_product_prices]);

    useEffect(() => {
        if (isNumber) {
            if (value > ip_max) {
                setSliderValue(ip_max);
            }
        } else {
            if (value > gb_max_real) {
                setSliderValue(gb_max_real);
            }
        }
    }, [value]);

    useEffect(() => {
        if (currentPriceTiers) {
            setPricePerUnit(convertToCent(getPriceTierAmount(value, currentPriceTiers)));
            setFinalPrice(Number((pricePerUnit * value).toFixed(2)));
            setQuantityText(isNumber ? `${value} IP` : sliderValue.label);
        }
    }, [value, pricePerUnit, isNumber, sliderValue, currentPriceTiers]);

    const selected_price_id = getPriceIDByCoutryCode(
        allProducts,
        sorted_product_prices[period],
        country?.country_id || null,
        product_type,
    );

    const params = withoutEmptyValues({
        line_items: [{ item_price_id: selected_price_id, item_quantity: value }],
        promotional_code: discountCode,
        payment_platform: paymentPlatform,
        payment_method_id: paymentMethod,
        coinpayments_currency: paymentCoin,
    });

    const handleSubmitCode = async () => {
        try {
            setLoadingCode(true);
            await CheckoutService.calculate(params)
                .then(response => {
                    const newData = response.data.data;
                    const { before_discount_total, discount } = newData;
                    setValidatedData(newData);
                    setDiscountError(false);
                    setDiscount(1 - discount / before_discount_total);
                })
                .catch(() => {
                    setDiscountError(true);
                })
                .finally(() => {
                    setLoadingCode(false);
                });
        } catch (e) {
            return false;
        }
    };

    const handleSubmit = async () => {
        try {
            if (state === 1) {
                setLoadingSubmit(true);
                await CheckoutService.calculate(params)
                    .then(response => {
                        const newData = response.data.data;
                        const { before_discount_total, discount } = newData;
                        setValidatedData(newData);
                        setDiscountError(false);
                        setDiscount(1 - discount / before_discount_total);
                        dispatch(setCalculationInfo(newData));
                        setState(2);
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    })
                    .finally(() => {
                        setLoadingSubmit(false);
                    });
            }
            if (state === 2) {
                setLoadingSubmit(true);
                const newParams = filterPaymentParamsByPlatform(params.payment_platform, params);
                await CheckoutService.create(newParams)
                    .then(response => {
                        const redirect_url = response.data.data.redirect_url;
                        window.open(redirect_url, '_self');
                    })
                    .finally(() => {
                        setLoadingSubmit(false);
                    });
            }
        } catch (e) {
            return false;
        }
    };

    const handleBackToFirstState = () => {
        setState(1);
        setValidatedData(null);
        setPaymentCoin('');
        dispatch(setCalculationInfo({}));
    };

    useEffect(() => {
        PaymentService.search();
    }, []);

    const disabled = !country?.product_instock && product_type !== service_types.residential;

    return (
        <div>
            {state === 1 && (
                <Button variant="outlined" className="bg-white mb-24" to={ROUTES.products} Icon={<Icon />}>
                    Back to all
                </Button>
            )}
            {state === 2 && (
                <Button variant="outlined" className="bg-white mb-24" Icon={<Icon />} onClick={handleBackToFirstState}>
                    Back
                </Button>
            )}
            <WithSidebarLayout
                bottom={!isDesktop && <PlansInfoCard />}
                aside={
                    <OrderSummarySidebar
                        state={state}
                        loadingCode={loadingCode}
                        loadingSubmit={loadingSubmit}
                        disabled={disabled}
                        location={country}
                        name={title}
                        finalPrice={finalPrice}
                        onSubmit={handleSubmit}
                        periodName={periodName}
                        quantityText={quantityText}
                        pricePerUnit={pricePerUnit}
                        productType={product_type}
                        discountCode={discountCode}
                        onChangeDiscount={setDiscountCode}
                        discountError={discountError}
                        onDiscountSubmit={handleSubmitCode}
                        discount={discount}
                    />
                }
            >
                {state === 1 && (
                    <>
                        <ProductBox
                            loadingSubmit={loadingSubmit}
                            period={period}
                            country={country}
                            changePeriod={setPeriod}
                            sliderValue={sliderValue}
                            changeCountry={setCountry}
                            productData={currentProduct}
                            sliderChange={setSliderValue}
                            periodOptions={period_options}
                            countriesOptions={countries_options}
                        />
                        {isDesktop && <PlansInfoCard />}
                    </>
                )}
                {state === 2 && (
                    <BillingInfoCard
                        disabled={loadingSubmit}
                        data={validatedData}
                        paymentPlatform={paymentPlatform}
                        setPaymentPlatform={setPaymentPlatform}
                        paymentMethod={paymentMethod}
                        setPaymentMethod={setPaymentMethod}
                        paymentCoin={paymentCoin}
                        setPaymentCoin={setPaymentCoin}
                    />
                )}
            </WithSidebarLayout>
        </div>
    );
};

export default ProductPage;
