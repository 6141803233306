import { ReactComponent as ProxyListIcon } from 'icons/clipboard-list.svg';
import { ReactComponent as SettingsIcon } from 'icons/settings.svg';
import { service_types } from 'helpers/services';
import ROUTES from 'helpers/routes';

export const getParams = (params, navigate) => {
    const { type, id } = params.row;
    const isResidential = type === service_types.residential;

    const icon = isResidential ? <SettingsIcon /> : <ProxyListIcon />;
    const label = isResidential ? 'Generate Proxies' : 'View Proxies';

    const handleClick = () =>
        isResidential ? navigate(ROUTES.residential) : navigate(ROUTES.proxyList + '?service_id=' + id);

    return {
        showInMenu: true,
        icon,
        label,
        onClick: handleClick,
    };
};
