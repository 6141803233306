import { useSelector } from 'react-redux';
import Messages from './Items/Messages';
import { selectUnread } from 'store/slices/notification';

const UnreadMessages = () => {
    const data = useSelector(selectUnread);

    return <Messages messages={data} noDataText="No unread notifications" />;
};

export default UnreadMessages;
