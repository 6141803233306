import AccountSettingsCard from '../Items/AccountSettingsCard';
import { useState } from 'react';
import TextInput from 'components/_common/TextInput';
import WebSessions from '../Items/WebSessions';
import CustomerService from 'api/services/CustomerService';
import { useFormik } from 'formik';
import { changePasswordValidationSchema } from 'helpers/validations';
import Modal2FA from 'components/Modals/Modal2FA';
import showMessage from 'components/NoticeProvider/showMessage';
import Toggle2FA from '../Items/Toggle2FA';
import ActionButtons from '../Items/ActionButtons';
import GoogleConnect from '../Items/GoogleConnect';

const SecurityTab = () => {
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState('');
    const [open, setOpen] = useState(false);
    const [fields, setFields] = useState(null);

    const formik = useFormik({
        initialValues: { customer_password: '', repeat_password: '' },
        validationSchema: changePasswordValidationSchema,
        onSubmit: async params => {
            try {
                setLoading(true);
                await CustomerService.edit(params)
                    .catch(({ response }) => {
                        const { two_factor_authentication_public_key } = response.data.data;
                        setLoading(false);
                        if (response.status === 499) {
                            setFields({ ...params, two_factor_authentication_public_key });
                            setOpen(true);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } catch (e) {
                return false;
            }
        },
    });

    const handleSubmit = () => formik.handleSubmit();
    const handleDiscardChanges = () => formik.resetForm();

    const disabled = !formik.values.customer_password.length && !formik.values.repeat_password.length;

    const handleFinalSubmit = async () => {
        const params = { ...fields, two_factor_authentication_private_key: otp };
        try {
            setLoading(true);
            await CustomerService.edit(params)
                .then(response => {
                    setLoading(false);
                    showMessage(response.data.message, response.status);
                    setOpen(false);
                })
                .then(() => {
                    setOtp('');
                    formik.resetForm();
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            return false;
        }
    };

    return (
        <div className="content-container">
            <AccountSettingsCard title="Change password">
                <div className="inputs-row">
                    <TextInput
                        withFormik
                        name="customer_password"
                        label="New password"
                        type="password"
                        autoComplete="new-password"
                        value={formik.values.customer_password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.customer_password && Boolean(formik.errors.customer_password)}
                        helperText={formik.touched.customer_password && formik.errors.customer_password}
                    />
                    <TextInput
                        withFormik
                        name="repeat_password"
                        label="Repeat new password"
                        type="password"
                        autoComplete="new-password"
                        value={formik.values.repeat_password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.repeat_password && Boolean(formik.errors.repeat_password)}
                        helperText={formik.touched.repeat_password && formik.errors.repeat_password}
                    />
                </div>
            </AccountSettingsCard>

            <Toggle2FA />

            <WebSessions />

            {process.env.REACT_APP_ENABLE_CONNECT_GOOGLE_ACCOUNT === 'TRUE' && <GoogleConnect />}

            <ActionButtons
                loading={loading}
                handleSubmit={handleSubmit}
                disabled={disabled}
                handleDiscardChanges={handleDiscardChanges}
            />

            <Modal2FA
                otp={otp}
                setOtp={setOtp}
                open={open}
                setOpen={setOpen}
                onSubmit={handleFinalSubmit}
                loading={loading}
                title="Confirm password changing"
                text="Are you sure you want to change password?"
            />
        </div>
    );
};

export default SecurityTab;
