import EmptyPageState from 'components/EmptyPageState';
import ModalCreateProxyUser from 'components/Modals/ModalCreateProxyUser';
import { ReactComponent as Icon } from 'icons/colored/empty_icon_users.svg';

const NoData = ({ setModalOpen, modalOpen }) => (
    <EmptyPageState
        Icon={<Icon />}
        title="You don't have proxy user yet"
        description="Enhance your experience by adding a proxy user"
    >
        <ModalCreateProxyUser setOpen={setModalOpen} open={modalOpen} />
    </EmptyPageState>
);

export default NoData;
