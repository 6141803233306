import EmptyPageState from 'components/EmptyPageState';
import Button from 'components/_common/Button';
import ROUTES from 'helpers/routes';
import { service_types } from 'helpers/services';
import { ReactComponent as Icon } from 'icons/colored/empty_icon_summary.svg';

const NoData = () => (
    <EmptyPageState Icon={<Icon />} title="You don't have any residential bandwidth">
        <Button to={`${ROUTES.products}/${service_types.residential}`}>Add Residential Bandwidth</Button>
    </EmptyPageState>
);

export default NoData;
