const AccountSettingsCard = ({ title, desc = '', children = undefined }) => (
    <div className="account-settings-card">
        <div>
            <h5 className="account-settings-card__title">{title}</h5>
            {desc && <div className="account-settings-card__desc">{desc}</div>}
        </div>

        {children}
    </div>
);

export default AccountSettingsCard;
