import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import ReplacementService from 'api/services/ReplacementService';

export const fetchReplacementList = createAsyncThunk('fetchReplacementList', async params => {
    const response = await ReplacementService.search(params);
    return response.data;
});

const ReplacementSlice = createSlice({
    name: 'ReplacementSlice',
    initialState: {
        replacementList: {
            loading: false,
            data: [],
            error: null,
            total: 0,
        },
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchReplacementList.pending, state => {
                state.replacementList.loading = true;
            })
            .addCase(fetchReplacementList.fulfilled, (state, action) => {
                state.replacementList.loading = false;
                state.replacementList.data = action.payload.data;
                state.replacementList.total = action.payload.total_count;
            })
            .addCase(fetchReplacementList.rejected, (state, action) => {
                state.replacementList.loading = false;
                state.replacementList.error = action.error;
            });
    },
});

export const selectReplacementList = createSelector(
    [state => state.replacementState.replacementList],
    replacementList => replacementList.data,
);

export const selectReplacementListTableData = createSelector([selectReplacementList], data =>
    data.map(replacement => ({
        id: replacement.service_adjustment_id,
        service_adjustment_status: replacement.service_adjustment_status,
        service_id: replacement.service_id,
        service_adjustment_id: replacement.service_adjustment_id,
        service_adjustment_creation_datetime: replacement.service_adjustment_creation_datetime,
        proxy_replacements: replacement.proxy_replacements?.map(i => ({ ...i, id: i.proxy_replacement_id })),
    })),
);

export const selectReplacementListLoading = createSelector(
    [state => state.replacementState.replacementList],
    replacementList => replacementList.loading,
);

export const selectReplacementListTotal = createSelector(
    [state => state.replacementState.replacementList],
    replacementList => replacementList.total,
);

export default ReplacementSlice.reducer;
