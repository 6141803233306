import { getPaymensIcon } from 'helpers/payments';
import '../styles.scss';

const PaymentCell = ({ type, num }) => {
    if (!num) return false;

    return (
        <div className="payment-cell">
            <div className="payment-cell__icon">{getPaymensIcon(type)}</div>
            <div className="payment-cell__value">
                <span className="card-dots" />
                <span>{num}</span>
            </div>
        </div>
    );
};

export default PaymentCell;
