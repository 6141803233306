import AccountSettingsCard from 'pages/AccountSettings/Items/AccountSettingsCard';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCustomer, selectCustomerLoading } from 'store/slices/customer';
import TextInput from 'components/_common/TextInput';
import _ from 'lodash';
import ActionButtons from 'pages/AccountSettings/Items/ActionButtons';
import CustomerService from 'api/services/CustomerService';
import showMessage from 'components/NoticeProvider/showMessage';
import BillingCountryDropdown, { getValueByCode } from 'components/_common/CountryDropdown/BillingCountryDropdown';
import BillingLayout from '../Layout';

const inputs = [
    { key: 'customer_billing_line_one', label: 'Billing line one', placeholder: 'Billing line one' },
    { key: 'customer_billing_line_two', label: 'Billing line two', placeholder: 'Billing line two' },
    { key: 'customer_billing_name', label: 'Billing name', placeholder: 'Billing name' },
    {
        key: 'customer_billing_subdivision_name',
        label: 'Billing subdivision name',
        placeholder: 'Billing subdivision name',
    },
    { key: 'customer_billing_zip_code', label: 'Billing zip code', placeholder: 'Billing zip code' },
];

const Address = () => {
    const {
        country_id_billing,
        customer_billing_line_one,
        customer_billing_line_two,
        customer_billing_name,
        customer_billing_subdivision_name,
        customer_billing_zip_code,
    } = useSelector(selectCustomer);

    const [loading, setLoading] = useState(false);
    const [country, setCountry] = useState(getValueByCode(country_id_billing));
    const customerLoading = useSelector(selectCustomerLoading);

    const initial = {
        customer_billing_line_one: customer_billing_line_one || '',
        customer_billing_line_two: customer_billing_line_two || '',
        customer_billing_name: customer_billing_name || '',
        customer_billing_subdivision_name: customer_billing_subdivision_name || '',
        customer_billing_zip_code: customer_billing_zip_code || '',
    };

    useEffect(() => {
        if (country_id_billing) {
            setCountry(getValueByCode(country_id_billing));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerLoading]);

    const [inputsValues, setInputsValues] = useState(initial);

    const handleDiscardChanges = () => {
        setInputsValues(initial);
        setCountry(getValueByCode(country_id_billing));
    };

    // eslint-disable-next-line
    const disabled = _.isEqual(initial, inputsValues) && country?.code == country_id_billing;

    const handleSubmit = async () => {
        const params = { ...inputsValues, country_id_billing: country?.code };
        try {
            setLoading(true);
            await CustomerService.edit(params)
                .then(response => {
                    setLoading(false);
                    showMessage(response.data.message, response.status);
                })
                .then(() => {
                    setTimeout(() => window.location.reload(), 700);
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            return false;
        }
    };

    return (
        <BillingLayout>
            <AccountSettingsCard title="Billing details">
                <div className="inputs-row">
                    <BillingCountryDropdown value={country} onChange={setCountry} />

                    {inputs.map(({ key, label, placeholder }) => {
                        const handleInputChange = value => setInputsValues({ ...inputsValues, [key]: value });

                        return (
                            <TextInput
                                key={key}
                                name={key}
                                label={label}
                                placeholder={placeholder}
                                value={inputsValues[key]}
                                onChange={handleInputChange}
                            />
                        );
                    })}
                </div>
                <ActionButtons
                    loading={loading}
                    handleSubmit={handleSubmit}
                    disabled={disabled}
                    handleDiscardChanges={handleDiscardChanges}
                />
            </AccountSettingsCard>
        </BillingLayout>
    );
};

export default Address;
