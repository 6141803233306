import './styles.scss';
import ProductCardItem from './ProductCardItem';
import { useSelector } from 'react-redux';
import { selectMainProductsArray } from 'store/slices/products';

const ProductCards = ({ data = undefined, residentialServiceData, retrieveResidentialService, small = false }) => {
    const products_array = useSelector(selectMainProductsArray);
    const items = data || products_array;

    return (
        <div className="product-cards">
            {items?.map(item => (
                <ProductCardItem
                    key={item.product_id}
                    data={{ ...item, residentialServiceData }}
                    retrieveResidentialService={retrieveResidentialService}
                    small={small}
                />
            ))}
        </div>
    );
};

export default ProductCards;
