import '../styles.scss';
import { convertToDollar } from 'helpers/payments';

const AmountCell = ({ value }) => {
    const formatedValue = convertToDollar(value);
    const isPositive = value > 0;

    return (
        <div className="amount-cell" data-positive={isPositive}>
            {formatedValue}
        </div>
    );
};

export default AmountCell;
