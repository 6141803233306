import './styles.scss';
import RadioGroupComponent from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '../Radio';

const RadioGroup = ({ label, items, value, onChange, row = false }) => {
    const handleChange = event => onChange(event.target.value);

    return (
        <FormControl className="custom-radio-group">
            <FormLabel focused={false}>{label}</FormLabel>
            <RadioGroupComponent row={row} value={value} onChange={handleChange}>
                {items.map(({ value, label }) => (
                    <Radio key={value} value={value} label={label} />
                ))}
            </RadioGroupComponent>
        </FormControl>
    );
};

export default RadioGroup;
