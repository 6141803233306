import '../styles.scss';

import Flag from 'components/_common/CountryDropdown/Items/Flag';

import { getServiceIcon, getUnitByType } from 'helpers/services';

const ServiceCell = ({ withFlag = false, data }) => {
    const { type, name, value, id, country_id } = data || {};
    const icon = getServiceIcon(type);
    const valueWithUnit = `${value} ${getUnitByType(type)}`;
    const ID = `ID: ${id}`;

    if (!id) return false;

    return (
        <div className="service-cell">
            <div className="service-cell__icon">{icon}</div>
            <div className="service-cell__content">
                <div className="service-cell-title">{name}</div>
                <div className="service-cell-info">
                    <span>{valueWithUnit}</span>
                    <span className="dot" />
                    <span>{ID}</span>
                    {withFlag && country_id && <Flag country_id={country_id} size={15} />}
                </div>
            </div>
        </div>
    );
};

export default ServiceCell;
