import showMessage from 'components/NoticeProvider/showMessage';
import Button from 'components/_common/Button';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import { ReactComponent as Icon } from 'icons/copy.svg';

const CopyButton = ({
    copyValue,
    onClick = undefined,
    customStyles = {},
    disabled = false,
    withoutText = false,
    customText = null,
    fullWidth = false,
}) => {
    const [, copy] = useCopyToClipboard();

    const handleCopy = () => {
        onClick && onClick();
        copy(copyValue)
            .then(() => {
                showMessage('Copied!', 'success');
            })
            .catch(error => {
                console.error('Failed to copy!', error);
            });
    };

    const text = withoutText ? '' : customText || 'Copy';

    return (
        <Button
            variant="outlined"
            customStyles={customStyles}
            Icon={<Icon />}
            className={`bg-white ${withoutText ? 'button-without-text' : ''}`}
            onClick={handleCopy}
            disabled={disabled}
            fullWidth={fullWidth}
        >
            {text}
        </Button>
    );
};
export default CopyButton;
