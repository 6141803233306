import ServicesService from 'api/services/ServicesService';
import ConfirmButton from 'components/Buttons/ConfirmButton';
import showMessage from 'components/NoticeProvider/showMessage';
import ControledDialog from 'components/_common/Dialog/ControledDialog';
import React, { useState } from 'react';

const ModalCancelUpcomingReconfiguration = ({ open, setOpen, subscription_schedule_id, onClick }) => {
    const [loading, setLoading] = useState(false);

    const submit = async () => {
        try {
            setLoading(true);
            await ServicesService.cancelReconfiguration({ subscription_schedule_id })
                .then(response => {
                    showMessage(response.data.message, response.status);
                })
                .then(() => {
                    setOpen(false);
                    onClick();
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            return false;
        }
    };

    return (
        <ControledDialog
            title={`Cancel Upcoming Reconfiguration`}
            Submit={<ConfirmButton onClick={submit} loading={loading} />}
            setOpen={setOpen}
            open={open}
        >
            <div>
                <h5>Are you sure you want to cancel upcoming reconfiguration for this subscription?</h5>
            </div>
        </ControledDialog>
    );
};

export default ModalCancelUpcomingReconfiguration;
