import AddNewMethodButton from 'components/Buttons/AddNewMethodButton';
import EmptyPageState from 'components/EmptyPageState';

const NoData = () => (
    <EmptyPageState title="You don’t have any saved payment methods">
        <AddNewMethodButton className="self-end bg-white" text='Add Card'/>
    </EmptyPageState>
);

export default NoData;
