import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import './styles.scss';

const Spinner = ({ size = 40 }) => (
    <div className="custom-spinner">
        <CircularProgress size={size} />
    </div>
);

export default Spinner;
