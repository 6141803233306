import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import LogService from 'api/services/LogService';

export const fetchAnalytict = createAsyncThunk('fetchAnalytict', async searchParams => {
    const responses = await Promise.all(
        searchParams.map(async params => {
            const response = await LogService.analytics(params);
            return {
                proxy_user_id: params.proxy_user_id ?? null,
                intervals: response.data.data.intervals,
                totals: {
                    totalBytes: response.data.data.total_bytes,
                    totalSuccessful: response.data.data.total_sucessful,
                    totalError: response.data.data.total_error,
                    totalRequests: response.data.data.total_requests,
                },
            };
        }),
    );
    return responses;
});

const LogSlice = createSlice({
    name: 'LogSlice',
    initialState: {
        analytics: {
            loading: false,
            data: null,
            error: null,
            lastUpdated: null,
        },
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAnalytict.pending, state => {
                state.analytics.loading = true;
            })
            .addCase(fetchAnalytict.fulfilled, (state, action) => {
                state.analytics.loading = false;
                state.analytics.data = action.payload;
                state.analytics.lastUpdated = Date.now();
            })
            .addCase(fetchAnalytict.rejected, (state, action) => {
                state.analytics.loading = false;
                state.analytics.error = action.error;
            });
    },
});

export const selectAnalytics = createSelector([state => state.logState.analytics.data], data => {
    return data || [];
});

export const selectAnalyticsLoading = createSelector(
    [state => state.logState.analytics],
    analytics => analytics.loading,
);

export default LogSlice.reducer;
