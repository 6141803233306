import * as React from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import './styles.scss';

const Tabs = ({ tabs, onChange, value, className = '', variant = 'scrollable' }) => {
    return (
        <div className={`custom-tabs ${className}`}>
            <TabContext value={value}>
                <TabList onChange={onChange} variant={variant} scrollButtons={false} allowScrollButtonsMobile>
                    {tabs.map(({ value, label }) => (
                        <Tab label={label} value={value} key={value} />
                    ))}
                </TabList>
                {tabs.map(({ value, content }) => (
                    <TabPanel key={value} value={value}>
                        {content}
                    </TabPanel>
                ))}
            </TabContext>
        </div>
    );
};

export default Tabs;
