import React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { useElementSize } from 'hooks/useElementSize';
import './styles.scss';
import LoadingOverlay from '../LoadingOverlay';

const Chart = ({ xLabels, data, yAxisConfig, seriesConfig = {}, loading = false }) => {
    const { width = 0, height = 0, ref } = useElementSize();

    // Process the data to include proper opacity and texture
    const processedData = data.map((series, index) => ({
        curve: 'linear',
        area: true,
        showMark: false,
        ...series,
        ...seriesConfig,
    }));

    const calculateYAxisMax = value => {
        if (value === 0) return 10; // Handle the case when the max value is 0
        const exponent = Math.floor(Math.log10(value));
        const base = value / Math.pow(10, exponent);

        let factor;
        if (base <= 1.5) factor = 2;
        else if (base <= 2.5) factor = 3;
        else if (base <= 4) factor = 5;
        else if (base <= 7.5) factor = 10;
        else factor = 15;

        return factor * Math.pow(10, exponent);
    };

    const maxValue = Math.max(...data.flatMap(series => series.data));
    const yAxisMaxLocal = yAxisConfig?.yAxisMax || calculateYAxisMax(maxValue * 1.05); // Add 5% buffer
    const yAxisValueFormatterLocal = yAxisConfig?.yAxisValueFormatter || (value => value.toLocaleString());

    return (
        <div className="custom-chart" ref={ref}>
            {loading ? (
                <LoadingOverlay />
            ) : (
                <LineChart
                    grid={{ vertical: false, horizontal: true }}
                    margin={{
                        left: 40,
                        right: 10,
                        top: 50,
                        bottom: 25,
                    }}
                    slotProps={{
                        legend: {
                            position: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            itemMarkWidth: 8,
                            itemMarkHeight: 8,
                            labelStyle: {
                                fontSize: '12px',
                                fontWeight: 500,
                                fontFamily: 'inherit',
                                fill: 'var(--ping-Grey-grey-700)',
                                letterSpacing: '0.2px',
                            },
                            markGap: 4,
                            itemGap: 16,
                        },
                    }}
                    width={width}
                    height={height}
                    series={processedData}
                    xAxis={[{ scaleType: 'point', data: xLabels }]}
                    yAxis={[
                        {
                            min: 0,
                            max: yAxisMaxLocal,
                            tickNumber: 5,
                            tickLabelStyle: {
                                fontSize: 12,
                            },
                            valueFormatter: yAxisValueFormatterLocal,
                        },
                    ]}
                    sx={{
                        padding: '5px',
                        '.MuiChartsAxis-tickContainer .MuiChartsAxis-tickLabel': {
                            fontSize: '12px',
                            fontWeight: 500,
                            fontFamily: 'inherit',
                            fill: 'var(--ping-Grey-grey-600)',
                            letterSpacing: '0.2px',
                            marginRight: '20px',
                        },
                        '.MuiChartsGrid-horizontalLine': {
                            stroke: 'var(--ping-Grey-grey-300)',
                            strokeWidth: 1,
                            strokeDasharray: '4 4',
                        },
                        '.MuiChartsAxis-root .MuiChartsAxis-line': {
                            stroke: 'var(--ping-Grey-grey-300)',
                        },
                        '.MuiChartsAxis-tickContainer .MuiChartsAxis-tick': {
                            stroke: 'var(--ping-Grey-grey-300)',
                        },
                        '& .MuiAreaElement-root': {
                            opacity: '0.2 !important',
                        },

                        '& .MuiLineElement-root': {
                            strokeWidth: '2.75 !important',
                            opacity: '1 !important',
                        },
                    }}
                />
            )}
        </div>
    );
};

export default Chart;
