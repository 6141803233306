import AccordionItem from 'components/_common/AccordionItem';
import PriceTable from './PriceTable';

const ProductPrices = ({ data, type }) => (
    <div className="product-box-prices">
        <div className="text-label">Select number of IPs</div>
        <p className="text pb-16">Choose the perfect quantity of IPs for your needs effortlessly</p>
        <AccordionItem title="Bundle discounts">
            <div className="prices-tables">
                {data.map((data, index) => (
                    <PriceTable key={index} data={data} type={type} />
                ))}
            </div>
        </AccordionItem>
    </div>
);

export default ProductPrices;
