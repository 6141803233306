import BadgeComponent from '@mui/material/Badge';
import './styles.scss';

const Badge = ({ children = undefined, value }) => (
    <BadgeComponent badgeContent={value} className="custom-bage" color="primary">
        {children}
    </BadgeComponent>
);

export default Badge;
