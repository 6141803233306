const MessageItem = ({ text, date, isNew = false }) => {
    return (
        <div className="message-item">
            <div className="message-item__text">{text}</div>
            <div className="message-item__date">{date}</div>
            {isNew && <div className="message-item__status" />}
        </div>
    );
};

export default MessageItem;
