import $api from 'api/http';
import urls from 'api/urls-config.json';

export default class ReplacementService {
    static async search(params) {
        return $api.get(urls.replacement.search, {
            params: { service_adjustment_type: 'proxy_replacement', ...params },
        });
    }
    static async retrieve(id) {
        return $api.get(urls.replacement.retrieve + id);
    }
}
