import ResidentialTable from '../ResidentialTable';
import DashboardBandwidth from 'pages/Dashboard/DashboardBandwidth';

const StatisticTab = () => {
    return (
        <>
            <DashboardBandwidth />
            <ResidentialTable />
        </>
    );
};

export default StatisticTab;
