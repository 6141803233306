import './styles.scss';
import RadioGroupComponent from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioCard from './RadioCard';

const RadioGroupCards = ({
    items,
    value,
    onChange,
    label = '',
    row = false,
    disabled = false,
    horizontal = false,
    small = false,
}) => {
    const handleChange = event => onChange(event.target.value);

    return (
        <FormControl className={`radio-group-cards ${horizontal ? 'horizontal' : ''}`}>
            <FormLabel focused={false}>{label}</FormLabel>
            <RadioGroupComponent row={row} value={value} onChange={handleChange}>
                {items?.map(item => (
                    <RadioCard
                        key={item.value}
                        value={item.value}
                        label={item.label}
                        disabled={disabled || item.disabled}
                        checked={item.value === value}
                        additional={item.additional || item.Label}
                        small={small}
                    />
                ))}
            </RadioGroupComponent>
        </FormControl>
    );
};

export default RadioGroupCards;
