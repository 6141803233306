import Label from 'components/_common/Label';
import '../styles.scss';

const statuses = {
    active: 'green',
    inactive: 'grey',
    paid: 'green',
    200: 'green',
    201: 'green',
    400: 'red',
    402: 'yellow',
    422: 'yellow',
    404: 'red',
    canceled: 'red',
    overdue: 'red',
    deleted: 'red',
    open: 'yellow',
};

const getStatusColor = status => statuses[status];

const ScheduleStatusCell = ({ status }) => {
    const color = getStatusColor(status) || 'grey';

    return <Label color={color} text={status} />;
};

export default ScheduleStatusCell;
