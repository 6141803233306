import { useState } from 'react';
import '../styles.scss';

import Label from 'components/_common/Label';
import showMessage from 'components/NoticeProvider/showMessage';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';

const TextCell = ({ text, capitalize = false, labelText = '', hoverText = '' }) => {
    const [, copy] = useCopyToClipboard();
    const [displayText, setDisplayText] = useState(text);
    const [hoverActive, setHoverActive] = useState(true);

    const handleCopy = () => {
        copy(text)
            .then(() => {
                showMessage('Copied!', 'success');
            })
            .catch(error => {
                console.error('Failed to copy!', error);
            });
        if (hoverText) {
            setDisplayText(hoverText);
            setHoverActive(false);
        }
    };
    return (
        <div
            onMouseOver={hoverText && hoverActive ? () => setDisplayText(hoverText) : null}
            onMouseOut={hoverText && hoverActive ? () => setDisplayText(text) : null}
            onClick={handleCopy}
            className={`text-cell pointer ${capitalize ? 'capitalize' : ''}`}
            title={text}
        >
            <div>{displayText}</div>
            {labelText && <Label text={labelText} />}
        </div>
    );
};

export default TextCell;
