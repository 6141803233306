import ROUTES from 'helpers/routes';
import { ReactComponent as LogoIcon } from 'images/ping_logo.svg';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectIsUser } from 'store/slices/customer';

const Logo = () => {
    const isUser = useSelector(selectIsUser);

    return (
        <Link to={isUser ? ROUTES.dashboard : ROUTES.login} className="header-logo">
            <LogoIcon />
        </Link>
    );
};

export default Logo;
