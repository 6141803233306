import Table from 'components/_common/Table';
import TextCell from 'components/_common/TableCells/TextCell';
import { getPriceTiers, getTarrifName } from '../helpers';
import { service_types } from 'helpers/services';
import { gb_max_real, ip_max } from '../constants';
import { GB, IP } from 'helpers/constants';

const PriceTable = ({ data, type }) => {
    const { price_cycle_interval, price_cycle_interval_count, price_tiers } = data;
    const name = getTarrifName(price_cycle_interval, price_cycle_interval_count);
    const isGb = type === service_types.residential;
    const unit = isGb ? GB : IP;

    const intervals = getPriceTiers(price_tiers, isGb ? gb_max_real : ip_max);

    const rows = [
        intervals.reduce(
            (acc, value, index) => ({
                ...acc,
                [`field_${index}`]: `$${value.amount / 100}`,
            }),
            { id: 0, field_name: `Price per ${unit}` },
        ),
    ];

    const columns = [
        {
            field: `field_name`,
            headerName: unit,
            flex: 1,
            minWidth: 120,
            renderCell: ({ value }) => <TextCell text={value} />,
        },
        ...intervals.map((value, index) => ({
            field: `field_${index}`,
            headerName: `${value.from} - ${value.to}`,
            flex: 1,
            minWidth: 100,
            renderCell: ({ value }) => <TextCell text={value} />,
        })),
    ];

    return (
        <div className="price-table" data-name={name}>
            <div className="price-table__title">{name}</div>
            <Table columns={columns} rows={rows} withoutPagination />
        </div>
    );
};

export default PriceTable;
