import Autocomplete from 'components/_common/Autocomplete';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCities, selectCitiesOptions, fetchCitiesDropdown } from 'store/slices/customer';

const CityDropdown = ({ value, onChange, countryCode = '', disabled }) => {
    const dispatch = useDispatch();
    const options = useSelector(selectCitiesOptions);

    useEffect(() => {
        // @ts-ignore
        dispatch(fetchCities());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (countryCode) {
            // @ts-ignore
            dispatch(fetchCities({ like_subdivision_id: `${countryCode}-%` }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryCode]);

    return (
        <Autocomplete
            fullWidth
            withFormLabel
            label="City"
            value={value}
            options={options}
            onChange={onChange}
            disabled={disabled}
            fetchData={query =>
                fetchCitiesDropdown({ like_subdivision_id: `${countryCode}-%`, like_city_name: `%${query}%` })
            }
        />
    );
};

export default CityDropdown;
