import { createSelector, createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
    name: 'authSlice',
    initialState: {
        isAuth: false,
    },
    reducers: {
        setAuth: (state, action) => {
            state.isAuth = action.payload;
        },
    },
});

export const selectLoading = createSelector([state => state.authState], data => data.loading);
export const selectIsAuth = createSelector([state => state.authState], data => data.isAuth);

export const { setAuth } = authSlice.actions;

export default authSlice.reducer;
