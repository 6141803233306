import Label from 'components/_common/Label';
import '../styles.scss';

const StatusCell = ({ id }) => {
    if (!id) {
        return null;
    }

    return <Label color={'yellow'} text={'Schedule Upcoming Reconfiguration'} />;
};

export default StatusCell;
