import Slider from 'components/_common/Slider';
import { datacenter_marks, gb_max, gb_min, ip_max, ip_min, residential_marks } from '../constants';
import { service_types } from 'helpers/services';

const valueText = value => `${value}`;
const getClassName = unit => `slider-with-${unit}-value`;

const ProductSlider = ({ sliderValue, product_type, onChange, disabled = false }) => {
    const isGb = product_type === service_types.residential;

    const valueLabelFormat = value =>
        isGb ? residential_marks[residential_marks.findIndex(mark => mark.value === value)]?.label : `${value} IP`;

    const handleChange = (event, value, activeThumb) => {
        onChange(isGb ? residential_marks[residential_marks.findIndex(mark => mark.value === value)] : value);
    };

    const min = isGb ? gb_min : ip_min;
    const max = isGb ? gb_max : ip_max;

    const value = isGb ? sliderValue.value : sliderValue;
    const marks = isGb ? residential_marks : datacenter_marks;

    const className = isGb ? getClassName('gb') : getClassName('ip');

    return (
        <Slider
            min={min}
            max={max}
            value={value}
            marks={marks}
            disabled={disabled}
            className={className}
            valueText={valueText}
            onChange={handleChange}
            valueLabelFormat={valueLabelFormat}
        />
    );
};

export default ProductSlider;
