import List from 'components/_common/List';
import './styles.scss';
import Button from 'components/_common/Button';
import PaymentsMethods from './Items/PaymentsMethods';
import { service_types } from 'helpers/services';
import DiscountCodeForm from 'components/DiscountCodeForm';
import SidebarRow from './Items/SidebarRow';
import SidebarDivider from './Items/SidebarDivider';
import { useSelector } from 'react-redux';
import { selectCalculationInfo } from 'store/slices/products';
import DiscountLabel from './DiscountLabel';

const items = ['A* Customer Support', 'Clean IP Addresses'];

const scrollTop = () =>
    window.scrollTo({
        top: document?.querySelector('main')?.getBoundingClientRect().top,
        behavior: 'smooth',
    });

const OrderSummarySidebar = ({
    onSubmit,
    state,
    quantityText,
    finalPrice,
    pricePerUnit,
    productType,
    location,
    loadingCode,
    loadingSubmit,
    name,
    periodName,
    discountCode,
    onChangeDiscount,
    discount,
    discountError,
    onDiscountSubmit,
    disabled,
}) => {
    const {
        total_after_applied_credit,
        customer_credit_balance_applied_amount,
        customer_credit_balance_applied,
        credit_balance,
        has_data,
        before_discount_total,
        discounted,
    } = useSelector(selectCalculationInfo);

    const isGb = productType === service_types.residential;

    const handleSubmit = () => {
        scrollTop();
        onSubmit();
    };

    const priceLabel = `Price per ${isGb ? 'GB' : 'IP'}`;

    const total = `$${(discount * finalPrice).toFixed(2)}`;

    return (
        <div className="order-summary-sidebar-container">
            <div className="order-summary box">
                <h5>Order summary</h5>
                <div className="subtitle1 pb-8">{name}</div>
                <List items={items} className="pb-16" />
                <SidebarRow name="Quantity">{quantityText}</SidebarRow>
                {!isGb && <SidebarRow name="Location">{location?.label}</SidebarRow>}
                <SidebarRow name={priceLabel}>${pricePerUnit}</SidebarRow>
                <SidebarRow name="Subscription period">{periodName}</SidebarRow>
                {state === 1 && (
                    <DiscountCodeForm
                        loading={loadingCode}
                        value={discountCode}
                        onChange={onChangeDiscount}
                        error={discountError}
                        onSubmit={onDiscountSubmit}
                        disabled={discount < 1 || loadingSubmit}
                    />
                )}
                <SidebarDivider />
                {discounted && <SidebarRow name="Before discount total">{before_discount_total}</SidebarRow>}
                {discount < 1 && (
                    <SidebarRow name={<DiscountLabel discount={discount} discountCode={discountCode} />}>
                        -${(finalPrice - finalPrice * discount).toFixed(2)}
                    </SidebarRow>
                )}
                {has_data && <SidebarRow name="Credit balance">{credit_balance}</SidebarRow>}
                {customer_credit_balance_applied && (
                    <SidebarRow name="Credit balance applied amount">
                        {customer_credit_balance_applied_amount}
                    </SidebarRow>
                )}
                <SidebarRow name="Total">{has_data ? total : <h4>{total}</h4>}</SidebarRow>
                {total_after_applied_credit && has_data && (
                    <SidebarRow name="Total after applied credit">
                        <h4>{total_after_applied_credit}</h4>
                    </SidebarRow>
                )}
            </div>
            {state < 3 && (
                <div className="order-submit-container">
                    <Button
                        variant="contained"
                        fullWidth
                        size="large"
                        onClick={handleSubmit}
                        loading={loadingSubmit}
                        disabled={loadingCode || disabled}
                    >
                        Continue to checkout
                    </Button>
                    <PaymentsMethods />
                </div>
            )}
        </div>
    );
};

export default OrderSummarySidebar;
