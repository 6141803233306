import Select from 'components/_common/Select/SimpleSelect';
import { uppercaseFirst } from 'helpers';
import { proxyFilters } from 'helpers/constants';
import { useSelector } from 'react-redux';
import { selectProxyFilterOptions } from 'store/slices/proxy';
import { getServiceIcon } from 'helpers/services';

const FilterType = ({ value, onChange }) => {
    const options = useSelector(selectProxyFilterOptions);

    const formattedOptions = options[proxyFilters.PROXY_TYPE]?.map(option => {
        return { value: option, label: uppercaseFirst(option), icon: () => getServiceIcon(option) };
    });

    const handleChange = value => onChange(value);

    return (
        <Select label="Type" options={formattedOptions || []} value={value} onChange={handleChange} withIcon={true} />
    );
};

export default FilterType;
