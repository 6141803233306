import { useEffect, useState } from 'react';
import BillingLayout from '../Layout';
import OrdersTable from './Table';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectBillingOrdersLoading,
    selectBillingOrdersTable,
    selectBillingOrdersTotal,
    fetchBillingOrders,
} from 'store/slices/billingOrders';
import { fetchPaymentMethods } from 'store/slices/billingOrders';

const Subscriptions = () => {
    const dispatch = useDispatch();
    const data = useSelector(selectBillingOrdersTable);
    const loading = useSelector(selectBillingOrdersLoading);
    const total = useSelector(selectBillingOrdersTotal);
    const [paymentMethods, setPaymentMethods] = useState({});

    useEffect(() => {
        (async () => {
            const payments = await fetchPaymentMethods();
            const storage = {};
            payments?.data.forEach(i => {
                storage[i.payment_method_id] = i;
            });

            setPaymentMethods(storage);
        })();
    }, []);

    return (
        <BillingLayout>
            <OrdersTable
                data={data.map(i => ({
                    ...i,
                    payment_method_network: paymentMethods[i.payment_method_id]?.payment_method_network,
                    payment_method_last_four: paymentMethods[i.payment_method_id]?.payment_method_last_four,
                }))}
                dataLoading={loading}
                // @ts-ignore
                fetchData={filters => dispatch(fetchBillingOrders(filters))}
                total={total}
                sortModel={[
                    {
                        field: 'service_creation_datetime',
                        sort: 'desc',
                    },
                ]}
                noDataMessage={'Explore proxy products'}
                paymentMethods={paymentMethods}
            />
        </BillingLayout>
    );
};

export default Subscriptions;
