import './styles.scss';

const FormTemplate = ({ title, desc = '', children }) => (
    <div className="auth-form">
        <div className="auth-form__title">{title}</div>
        {desc && <div className="auth-form__desc">{desc}</div>}
        {children}
    </div>
);

export default FormTemplate;
