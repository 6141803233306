import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import ProxyService from 'api/services/ProxyService';

export const fetchProxyUsers = createAsyncThunk('fetchProxyUsers', async params => {
    const response = await ProxyService.users(params);
    return response.data;
});

export const fetchProxyUsersDropdown = async params => {
    const response = await ProxyService.users(params);
    return response.data.data
        .sort((a, b) => (a.proxy_user_is_default === b.proxy_user_is_default ? 0 : a.proxy_user_is_default ? -1 : 1))
        .map(user => ({
            value: user.proxy_user_id,
            label: user.proxy_user_id,
            showLabel: user.proxy_user_is_default,
            labelColor: 'green',
            labelText: 'Default',
        }));
};

const ProxyUsersSlice = createSlice({
    name: 'ProxyUsersSlice',
    initialState: {
        proxyUsers: {
            loading: false,
            data: [],
            error: null,
            total: 0,
        },
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchProxyUsers.pending, state => {
                state.proxyUsers.loading = true;
            })
            .addCase(fetchProxyUsers.fulfilled, (state, action) => {
                state.proxyUsers.loading = false;
                state.proxyUsers.data = action.payload.data;
                state.proxyUsers.total = action.payload.total_count;
            })
            .addCase(fetchProxyUsers.rejected, (state, action) => {
                state.proxyUsers.loading = false;
                state.proxyUsers.error = action.error;
            });
    },
});

export const selectProxyUsers = createSelector(
    [state => state.proxyUsersState.proxyUsers],
    proxyUsers => proxyUsers.data,
);

export const selectProxyUsersTableData = createSelector([selectProxyUsers], data =>
    data.map(user => ({
        id: user.proxy_user_id,
        name: user.proxy_user_id,
        password: user.proxy_user_password,
        whitelisted_ip: user.ip_address_authentications,
        proxy_user_is_default: user.proxy_user_is_default,
        residential_bandwidth_usage: {
            value: user.proxy_user_residential_bytes_used,
            limit: user.proxy_user_residential_bytes_limit,
        },
        linked_products: user.restricted_service_ids,
    })),
);

export const selectProxyUsersLoading = createSelector(
    [state => state.proxyUsersState.proxyUsers],
    proxyUsers => proxyUsers.loading,
);

export const selectProxyUsersTotal = createSelector(
    [state => state.proxyUsersState.proxyUsers],
    proxyUsers => proxyUsers.total,
);

export const selectProxyUsersOptions = createSelector([selectProxyUsers], data =>
    data.map(user => ({ value: user.proxy_user_id, label: user.proxy_user_id })),
);

export default ProxyUsersSlice.reducer;
