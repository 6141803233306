import $api from 'api/http';
import urls from 'api/urls-config.json';

export default class NotificationService {
    static async search(params) {
        return $api.get(urls.notification.search, { params });
    }
    static async markRead() {
        return $api.post(urls.notification.read);
    }
    static async retrieve() {
        return $api.get(urls.notification.retrieve);
    }
}
