import * as React from 'react';
import { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ReactComponent as Chevron } from 'icons/chevron-down.svg';
import { FormLabel, InputAdornment } from '@mui/material';
import UntrolledCheckbox from '../Checkbox/UntrolledCheckbox';
import Badge from '../Badge';
import Label from 'components/_common/Label';

const SimpleSelect = ({
    label,
    fetchData = null,
    value,
    onChange,
    width = 140,
    fullWidth = false,
    withFormLabel = false,
    disabled = false,
    multiple = false,
    options: initialOptions,
}) => {
    const [internalValue, setInternalValue] = useState(value);
    const [options, setOptions] = useState(initialOptions);
    const [loading, setLoading] = useState(false);

    const selectedOption = options.find(option => option.value === internalValue?.value);

    useEffect(() => {
        if (!value) {
            return setInternalValue(null);
        }
        if (value !== internalValue && value !== internalValue?.value) {
            setInternalValue(value);
        }
    }, [value]);

    useEffect(() => {
        setOptions(initialOptions);
    }, [initialOptions]);

    const handleChange = (event, newValue) => {
        setInternalValue(newValue);
        if (multiple) {
            onChange(newValue?.map(i => i?.value || i) || null);
        } else {
            onChange(newValue?.value || null);
        }
    };

    const fetchOptions = async query => {
        setLoading(true);
        try {
            const response = await fetchData(query);
            setOptions(response);
        } catch (error) {
            console.error('Error fetching options:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (event, value) => {
        if (fetchData) {
            fetchOptions(value);
        }
    };

    return (
        <FormControl sx={{ width: fullWidth ? '100%' : width }} className="custom-select">
            {withFormLabel && <FormLabel focused={false}>{label}</FormLabel>}
            <Autocomplete
                multiple={multiple}
                options={options}
                size="small"
                onChange={handleChange}
                onInputChange={handleInputChange}
                value={internalValue}
                disabled={disabled}
                loading={loading}
                disableCloseOnSelect={multiple}
                isOptionEqualToValue={(option, value) => option.value === value?.value || option.value === value}
                limitTags={0}
                disableClearable={selectedOption?.showLabel}
                renderTags={() => null}
                renderOption={(props, option, state) => {
                    const { key, ...optionProps } = props;
                    if (multiple) {
                        return (
                            <li key={key} {...optionProps} style={{ padding: 0 }}>
                                <div style={{ minWidth: '42px', width: '42px' }}>
                                    <UntrolledCheckbox checked={state.selected} />
                                </div>
                                {option.label}
                                {option.showLabel && <Label color={option.labelColor} text={option.labelText} />}
                            </li>
                        );
                    }

                    return (
                        <li key={key} {...optionProps}>
                            {option.label}
                            {option.showLabel && (
                                <div style={{ marginLeft: '10px', display: 'flex' }}>
                                    <Label color={option.labelColor} text={option.labelText} />
                                </div>
                            )}
                        </li>
                    );
                }}
                renderInput={params => (
                    <TextField
                        {...params}
                        placeholder={label}
                        sx={{
                            '& .MuiInputBase-root': {
                                height: 'auto',
                                paddingTop: '4px !important',
                                paddingBottom: '5px !important',
                            },
                            '& .MuiInputBase-input': {
                                height: 'auto',
                                padding: 0,
                            },
                            '& .MuiButtonBase-root': {
                                visibility: 'visible',
                            },
                            '& .MuiInputBase-input::placeholder': {
                                color: 'rgb(31, 42, 55)',
                                opacity: 1,
                                fontWeight: 500,
                                fontFamily: 'Space Grotesk,sans-serif',
                                fontSize: '14px',
                            },
                        }}
                        InputProps={
                            multiple
                                ? {
                                      ...params.InputProps,
                                      endAdornment: (
                                          <>
                                              <InputAdornment position="end">
                                                  <div style={{ marginRight: 5, marginTop: -2 }}>
                                                      <Badge value={internalValue.length} />
                                                  </div>
                                              </InputAdornment>
                                              {params.InputProps.endAdornment}
                                          </>
                                      ),
                                  }
                                : {
                                      ...params.InputProps,
                                      endAdornment: (
                                          <>
                                              <InputAdornment position="end">
                                                  {selectedOption?.showLabel && (
                                                      <Label
                                                          color={selectedOption.labelColor}
                                                          text={selectedOption.labelText}
                                                      />
                                                  )}
                                              </InputAdornment>
                                              {params.InputProps.endAdornment}
                                          </>
                                      ),
                                  }
                        }
                    />
                )}
                popupIcon={<Chevron />}
            />
        </FormControl>
    );
};

export default SimpleSelect;
