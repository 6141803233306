import '../styles.scss';
import { useState } from 'react';
import { ReactComponent as Open } from 'icons/eye.svg';
import { ReactComponent as Close } from 'icons/eye-closed.svg';

const PasswordCell = ({ value }) => {
    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);

    const content = show ? value : '* * * * *';
    const icon = show ? <Close /> : <Open />;
    const title = show ? value : '';

    return (
        <div className="password-cell">
            <div className="password-cell__value" title={title}>
                {content}
            </div>
            <button className="password-cell__button" onClick={handleClick}>
                {icon}
            </button>
        </div>
    );
};

export default PasswordCell;
