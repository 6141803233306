import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as Icon } from 'icons/tooltip.svg';
import './styles.scss';
import { useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const TooltipComponent = ({ text, body = undefined, placement = 'top' }) => {
    const [open, setOpen] = useState(false);
    const handleTooltipClose = () => setOpen(false);
    const handleTooltipOpen = () => setOpen(true);

    return (
        <div className="tooltip-component">
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                    arrow
                    open={open}
                    title={text}
                    // @ts-ignore
                    placement={placement}
                    onClose={handleTooltipClose}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                >
                    <span
                        onClick={handleTooltipOpen}
                        onMouseEnter={handleTooltipOpen}
                        onMouseLeave={handleTooltipClose}
                    >
                        {body || <Icon />}
                    </span>
                </Tooltip>
            </ClickAwayListener>
        </div>
    );
};

export default TooltipComponent;
