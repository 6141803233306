import WithSidebarLayout from 'Layout/WithSidebarLayout';
import MessageCard from 'components/MessageCard';
import SuccessfulSidebar from './SuccessfulSidebar';
import ROUTES from 'helpers/routes';

const props = {
    type: 'good',
    message: 'Thank you for your purchase!',
    title: 'Your plan is ready',
    desc: 'Ready to take control? Manage your plan now and explore the full potential of your enhanced connectivity.',
    buttonText: 'Go to your plan',
    to: ROUTES.summary,
};

const PaymentSuccessful = () => {
    return (
        <WithSidebarLayout aside={<SuccessfulSidebar />}>
            <MessageCard {...props} />
        </WithSidebarLayout>
    );
};

export default PaymentSuccessful;
