import DateTimePickerRange from 'components/_common/DateTimePickerRange';
import MultipleSelect from 'components/_common/Select/MultipleSelect';
import Autocomplete from 'components/_common/Autocomplete';
import SimpleSelect from 'components/_common/Select/SimpleSelect';
import { useSelector } from 'react-redux';
import { selectUsersID } from 'store/slices/services';
import { networksSelect } from 'helpers/services';
import { metricSelect } from 'helpers';

const Filters = ({
    users,
    setUsers,
    network,
    setNetwork,
    metricFilter,
    setMetricFilter,
    range,
    setRange,
    minStartDateTime,
    maxEndDateTime,
    onError,
}) => {
    const usersID = useSelector(selectUsersID);
    const users_options = usersID?.map(id => ({ value: id, label: id }));
    const handleChangeUsers = value => setUsers(value);

    return (
        <>
            <DateTimePickerRange
                range={range}
                setRange={setRange}
                minStartDateTime={minStartDateTime}
                maxEndDateTime={maxEndDateTime}
                onError={onError}
            />
            <SimpleSelect
                label="Network"
                options={networksSelect}
                value={network}
                onChange={setNetwork}
                width={130}
                withIcon={true}
            />
            <Autocomplete
                label="Users"
                options={users_options}
                value={users}
                onChange={handleChangeUsers}
                width={200}
                multiple={true}
            />
            <SimpleSelect
                label="Metric"
                options={metricSelect}
                value={metricFilter}
                onChange={setMetricFilter}
                width={130}
            />
        </>
    );
};
export default Filters;
