import NoData from './NoData';
import SummaryTable from './SummaryTable';
import { useState } from 'react';
import PageHeader from 'components/PageHeader';
import { useDispatch, useSelector } from 'react-redux';
import { fetchServicesSummary, selectServicesLoading, selectServicesTableData, selectServicesTotal } from 'store/slices/services';
import SummaryTableBar from './SummaryTableBar';
import SummaryFilters from './SummaryFilters';

const SummaryPage = () => {
    const dispatch = useDispatch();
    const data = useSelector(selectServicesTableData);
    const loading = useSelector(selectServicesLoading);
    const total = useSelector(selectServicesTotal);
    const [service, setService] = useState('');
    const [status, setStatus] = useState('');

    const props = { service, setService, status, setStatus };

    return (
        <div>
            <PageHeader title="My Services Summary" />
                <SummaryTableBar filters={<SummaryFilters {...props} />} />
                <SummaryTable
                data={data}
                dataLoading={loading}
                // @ts-ignore
                fetchData={filters => dispatch(fetchServicesSummary(filters))}
                //
                staticFilters={{
                    sort_by: 'service_creation_datetime_desc',
                }}
                filters={{service_type: service, service_status: status}}
                total={total}
                noDataComponent={<NoData/>}
            />
        </div>
    );
};

export default SummaryPage;
