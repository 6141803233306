import $api from 'api/http';
import urls from 'api/urls-config.json';
import { omit } from 'lodash';

export default class PaymentService {
    static async retrieve(id) {
        return $api.get(urls.payments.payment_method.retrieve + id);
    }
    static async search(params) {
        return $api.get(urls.payments.payment_method.search, { params });
    }
    static async setup() {
        return $api.post(urls.payments.payment_method.setup, {
            payment_platform: 'stripe',
        });
    }
    static async delete(id) {
        return $api.delete(urls.payments.payment_method.delete + id);
    }
    static async edit(params) {
        return $api.patch(urls.payments.payment_method.edit + params.id, { ...omit(params, ['id']) });
    }
}
