import Label from 'components/_common/Label';
import '../styles.scss';

const TextCell = ({ text, capitalize = false, labelText = '', labelColor = '', onClick = null }) => (
    <div className={`text-cell ${capitalize ? 'capitalize' : ''} ${onClick ? 'pointer' : ''}`} title={text} onClick={onClick}>
        <div>{text}</div>
        {labelText && <Label text={labelText} color={labelColor}/>}
    </div>
);

export default TextCell;
