import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import NotificationService from 'api/services/NotificationService';

export const notificationSearch = createAsyncThunk('notificationSearch', async () => {
    const response = await NotificationService.search();
    return response.data;
});

const NotificationSlice = createSlice({
    name: 'NotificationSlice',
    initialState: {
        notification: {
            data: [],
            loading: false,
            error: null,
        },
        all: {
            notification: [],
            loading: false,
            error: null,
        },
        unread: {
            notification: [],
            loading: false,
            error: null,
        },
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(notificationSearch.pending, state => {
                state.notification.loading = true;
            })
            .addCase(notificationSearch.fulfilled, (state, action) => {
                state.notification.loading = false;
                state.notification.data = action.payload.data;
            })
            .addCase(notificationSearch.rejected, (state, action) => {
                state.notification.loading = false;
                state.notification.error = action.error;
            });
    },
});

export const selectNotifications = createSelector([state => state.notificationState.notification], notification =>
    notification.data.toReversed(),
);

export const selectNotificationsLoading = createSelector(
    [state => state.notificationState.notification],
    notification => notification.loading,
);

export const selectUnread = createSelector([selectNotifications], data =>
    data.filter(item => Boolean(!item.notification_read_datetime)),
);

export const selectCounter = createSelector([selectUnread], data => data.length);

export default NotificationSlice.reducer;
