import './styles.scss';
import * as React from 'react';
import RadioComponent from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

const Radio = ({ value = undefined, label = undefined, disabled = false, checked = undefined }) => (
    <FormControlLabel
        value={value}
        label={label}
        checked={checked}
        control={<RadioComponent size="small" />}
        className="custom-radio"
        disabled={disabled}
    />
);

export default Radio;
