import $api from 'api/http';
import urls from 'api/urls-config.json';

export default class ApiKeysService {
    static async search(params) {
        return $api.get(urls.api_key.search, { params });
    }
    static async create(params) {
        return $api.post(urls.api_key.create, params);
    }
    static async delete(id) {
        return $api.delete(urls.api_key.delete + '/' + id);
    }
}
