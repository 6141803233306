import $api from 'api/http';
import urls from 'api/urls-config.json';

export default class InvoicesService {
    static async search(filters) {
        return $api.get(urls.invoice.search, { params: filters });
    }
    static async retrieve(id) {
        return $api.get(urls.invoice.retrieve + id);
    }
    static async payOptions(id) {
        return $api.get(urls.invoice.pay_options + id);
    }
    static async generatePaymentLink(id, params) {
        return $api.post(urls.invoice.generate_payment_link + id, params);
    }
}
