import './styles.scss';
import { ReactComponent as Good } from 'icons/colored/circle-check-filled.svg';
import { ReactComponent as Bad } from 'icons/colored/alert-triangle-filled.svg';

const MessageText = ({ type, text, small = false, noIcon = false }) => {
    const icon = type === 'good' ? <Good /> : <Bad />;

    return (
        <div className={`message-text message-text--${type} ${small ? 'message-text--small' : ''}`}>
            {noIcon || icon}
            <div className="message-text__text">{text}</div>
        </div>
    );
};

export default MessageText;
