import { getServiceIcon } from 'helpers/services';

const ProductHeader = ({ product_type, product_name, product_description }) => (
    <div className="product-box-header">
        {getServiceIcon(product_type)}
        <div>
            <h4>{product_name}</h4>
            <p className="text">{product_description}</p>
        </div>
    </div>
);

export default ProductHeader;
