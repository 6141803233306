import { getAuthToken, removeAuthToken } from 'api/helpers';
import axios from 'axios';
import showMessage from 'components/NoticeProvider/showMessage';
import ROUTES from 'helpers/routes';

const $api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    paramsSerializer: {
        // @ts-ignore
        encode: param => encodeURI(param).replaceAll(':', '%3A'),
    },
});

$api.interceptors.request.use(config => {
    const authToken = getAuthToken();
    if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
});

$api.interceptors.response.use(
    config => {
        return config;
    },
    async error => {
        showMessage(error.response.data.message, 'error');
        if (error.response.status === 401) {
            removeAuthToken();
            if (window.location.pathname !== ROUTES.login) {
                // @ts-ignore
                window.location = ROUTES.login;
            }
        }
        if (
            error.response.status === 404 &&
            error.response.data.message !== 'Promotional Code not found.' &&
            !error.response.data.message.includes('Payment method')
        ) {
            // @ts-ignore
            window.location = ROUTES[404];
        }
        return Promise.reject(error);
    },
);

export default $api;
