import urls from 'api/urls-config.json';

export const authorization_type = {
    login: 'login',
    connect: 'connect',
    signup: 'signup',
};

const external_service = 'google';
const automatic_redirect = true;

export default class GoogleAuthService {
    static generate_url(data) {
        const params = new URLSearchParams({ automatic_redirect, external_service, ...data }).toString();
        return `${process.env.REACT_APP_API_URL}${urls.auth.oauth}?${params}`;
    }
}