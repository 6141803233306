import './styles.scss';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import AuthBanner from './AuthBanner';
import AuthHeader from './AuthHeader';
import ROUTES from 'helpers/routes';
import { getAuthToken } from 'api/helpers';

const AuthLayout = () => {
    const hasToken = getAuthToken();
    const { search } = useLocation();

    return hasToken ? (
        <Navigate to={search ? `${ROUTES.products}${search}` : ROUTES.dashboard} />
    ) : (
        <section className="auth-layout">
            <main>
                <AuthHeader />
                <div className="auth-form-container">
                    <Outlet />
                </div>
            </main>
            <AuthBanner />
        </section>
    );
};

export default AuthLayout;
