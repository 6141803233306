import Button from 'components/_common/Button';
import { ReactComponent as Export } from 'icons/corner-up-right.svg';

const ExportButton = ({ onClick = undefined, disabled = false }) => (
    <Button variant="outlined" Icon={<Export />} className="bg-white" onClick={onClick} disabled={disabled}>
        Export
    </Button>
);

export default ExportButton;
