import Label from 'components/_common/Label';
import '../styles.scss';
import TooltipComponent from 'components/_common/Tooltip';

const ListCell = ({ data, max_size = 3 }) => {
    const items = data.slice(0, max_size);
    const last = data.slice(max_size);

    const others = last.join('\n');

    const body_text = `+${last.length}`;
    const show_label = !!last.length;

    return (
        <div className="list-cell">
            <div className="list-cell__main">
                {items.map(item => (
                    <div key={item} title={item}>
                        {item}
                    </div>
                ))}
            </div>
            {show_label && (
                <div className="list-cell__label">
                    <TooltipComponent placement="bottom" text={others} body={<Label color="grey" text={body_text} />} />
                </div>
            )}
        </div>
    );
};

export default ListCell;
