export const ITEM_HEIGHT = 36;
export const ITEM_PADDING_TOP = 8;
export const ITEMS = 5;

export const getMenuProps = menuWidth => {
    return {
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * ITEMS + ITEM_PADDING_TOP,
                width: menuWidth,
            },
        },
    };
};
