import './styles.scss';
import Button from 'components/_common/Button';
import ControledDialog from 'components/_common/Dialog/ControledDialog';
import { ReactComponent as IconCheck } from 'icons/colored/circle-check-filled.svg';

const TurnOffModal = ({ setOpen, open, onClick }) => {
    const handleOpen = () => setOpen(true);

    const Save = () => (
        <Button color="error" onClick={onClick}>
            Yes, disable
        </Button>
    );

    return (
        <div className="disable-two-step-authentication">
            <div className="disable-two-step-authentication__message">
                <IconCheck />
                <p className="subtitle2">Two-step authentication is enabled</p>
            </div>
            <Button variant="outlined" color="error" className="bg-white" onClick={handleOpen}>
                Disable
            </Button>
            <ControledDialog title="Disable two-step authentication" Submit={<Save />} setOpen={setOpen} open={open}>
                <p>
                    Are you sure you want to disable a two-step authentication on this account? Keep in mind that this
                    action reduces your account's security.
                </p>
            </ControledDialog>
        </div>
    );
};

export default TurnOffModal;
