import { useSelector } from 'react-redux';
import Messages from './Items/Messages';
import { selectNotifications } from 'store/slices/notification';

const AllMessages = () => {
    const data = useSelector(selectNotifications);

    return <Messages messages={data} noDataText="You don't have any notifications" />;
};

export default AllMessages;
