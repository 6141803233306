import { useState } from 'react';
import PageHeader from 'components/PageHeader';
import ModalCreateApiKey from 'components/Modals/ModalCreateApiKey/ModalCreateApiKey';
import ApiKeysTable from './Table';
import { useDispatch, useSelector } from 'react-redux';
import { fetchApiKeys, selectApiKeysLoading, selectApiKeysTableData, selectApiKeysTotal } from 'store/slices/apiKeys';
import ModalShowCreatedApiKey from 'components/Modals/ModalShowCreatedApiKey';
import showMessage from 'components/NoticeProvider/showMessage';
import ApiKeysService from 'api/services/ApiKeysService';
import InfoBanner from 'components/InfoBanner';
import NoData from './NoData';

const ApiKeys = () => {
    const dispatch = useDispatch();
    const data = useSelector(selectApiKeysTableData);
    const loading = useSelector(selectApiKeysLoading);
    const total = useSelector(selectApiKeysTotal);

    const [loadingModal, setLoadingModal] = useState(false);
    const [keys, setKeys] = useState({});
    const [open, setOpen] = useState(false);
    const [apiKeyLabel, setApiKeyLabel] = useState('');

    const handleCreate = async () => {
        try {
            setLoadingModal(true);
            await ApiKeysService.create({api_key_label: apiKeyLabel})
                .then(response => {
                    showMessage(response.data.message, response.status);
                    setLoadingModal(false);
                    setKeys(response.data.data);
                })
                .then(() => {
                    setOpen(true);
                    // @ts-ignore
                    dispatch(fetchApiKeys());
                })
                .finally(() => {
                    setLoadingModal(false);
                });
        } catch (e) {
            return false;
        }
    };

    const showModal = !!data.length;

    return (
        <div>
            <PageHeader
                title="API Keys"
                right={showModal && <ModalCreateApiKey loading={loadingModal} onCreate={handleCreate} apiKeyLabel={apiKeyLabel} setApiKeyLabel={setApiKeyLabel} />}
            />
            <ApiKeysTable
                data={data}
                dataLoading={loading} // @ts-ignore
                fetchData={filters => dispatch(fetchApiKeys(filters))}
                //
                total={total}
                noDataComponent={<NoData loadingModal={loadingModal}  handleCreate={handleCreate}/>}
            />
            <InfoBanner />

            <ModalShowCreatedApiKey setOpen={setOpen} open={open} keys={keys} />
        </div>
    );
};

export default ApiKeys;