import List from 'components/_common/List';

const items = [
    'Unlimited Concurrent Sessions',
    'Country Level Targeting',
    'HTTP and SOCKS5 support',
    'Proxy Servers in 195+ Countries',
    'Email and Chat Support',
    'API access (Launching Soon)',
];

const PlansInfoCard = () => (
    <div className="box">
        <h6 className="pb-16">All plans include</h6>
        <List items={items} columns={2} />
    </div>
);

export default PlansInfoCard;
