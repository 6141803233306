import './styles.scss';

const WithSidebarLayout = ({ aside, children, bottom = undefined }) => (
    <div className="with-sidebar-layout">
        <div className="with-sidebar-layout__main">{children}</div>
        <div className="with-sidebar-layout__aside">{aside}</div>
        {!!bottom && <div className="with-sidebar-layout__bottom">{bottom}</div>}
    </div>
);

export default WithSidebarLayout;
