import React from 'react';
import { useNavigate } from 'react-router-dom';
import Table from 'components/_common/Table';
import AmountCell from 'components/_common/TableCells/AmountCell';
import TextCell from 'components/_common/TableCells/TextCell';
import PaymentCell from 'components/_common/TableCells/PaymentCell';
import ROUTES from 'helpers/routes';

const TransactionsTable = ({ data, dataLoading, fetchData, total, noDataMessage }) => {
    const navigate = useNavigate();

    const columns = React.useMemo(
        () => [
            {
                field: 'invoice_id',
                headerName: 'Invoice ID',
                flex: 0.5,
                minWidth: 250,
                renderCell: ({ value }) => <TextCell text={value} onClick={() => navigate(`${ROUTES.billingInvoices}/${value}`)}/>,
            },
            {
                field: 'transaction_creation_datetime',
                headerName: 'Date',
                flex: 0.5,
                minWidth: 180,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'amount',
                headerName: 'Amount',
                minWidth: 160,
                headerAlign: 'right',
                renderCell: ({ value }) => <AmountCell value={value} />,
            },
            {
                field: 'transaction_method',
                headerName: 'Transaction method',
                minWidth: 200,
                renderCell: ({ row }) => (
                    <PaymentCell type={row.payment_method_network} num={row.payment_method_last_four} />
                ),
            },
            {
                field: 'status',
                headerName: 'Status',
                minWidth: 200,
                renderCell: ({ value }) => <TextCell text={value} capitalize />,
            },
        ],
        [],
    );

    return (
        <Table
            columns={columns}
            rows={data}
            dataLoading={dataLoading}
            fetchData={fetchData}
            total={total}
            noDataMessage={noDataMessage}
            sortModel={[
                {
                    field: 'transaction_creation_datetime',
                    sort: 'desc',
                },
            ]}
        />
    );
};

export default TransactionsTable;
