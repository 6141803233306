import Button from 'components/_common/Button';
import './styles.scss';
import { ReactComponent as Icon } from 'icons/colored/info-banner.svg';
import { API_DOCUMENTATION_LINK } from 'helpers/constants';

const InfoBanner = ({
    header = 'Getting started with generating proxies using the API',
    content = 'With just a few simple steps, you can start generating proxies for your specific needs.',
    marginTop = 24,
    marginBottom = 0,
}) => {
    const handleClick = () => window.open(API_DOCUMENTATION_LINK, '_blank');

    return (
        <div className="info-banner" style={{ marginTop, marginBottom }}>
            <Icon />
            <div className="info-banner__content">
                <div>
                    <h5>{header}</h5>
                    <p className="body2">{content}</p>
                </div>

                <Button variant="outlined" size="small" className="bg-white" onClick={handleClick}>
                    Read documentation
                </Button>
            </div>
        </div>
    );
};
export default InfoBanner;
