import { convertToDollar } from 'helpers/payments';
import { useSelector } from 'react-redux';
import { selectCustomer } from 'store/slices/customer';
import ModalTopUp from 'components/Modals/ModalTopUp';

const TransactionsBar = () => {
    const { credit_balance } = useSelector(selectCustomer);
    const convertedBalance = convertToDollar(credit_balance);

    return (
        <div className="transaction-balance-card">
            <div className="transaction-balance-card__left">
                <div className="overline-text">Credit Total</div>
                <div className="display-large">{convertedBalance}</div>
            </div>
            <div className="transaction-balance-card__right">
                <ModalTopUp />
            </div>
        </div>
    );
};

export default TransactionsBar;
