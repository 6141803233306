import Button from 'components/_common/Button';

const ActionButtons = ({ handleSubmit, disabled, handleDiscardChanges, loading }) => (
    <div className="account-settings-buttons">
        <Button className="self-start" onClick={handleSubmit} disabled={disabled} loading={loading}>
            Save changes
        </Button>
        {!disabled && (
            <Button variant="text" onClick={handleDiscardChanges}>
                Discard changes
            </Button>
        )}
    </div>
);

export default ActionButtons;
