import TwoStepAuthentication from '../TwoStepAuthentication';
import AccountSettingsCard from './AccountSettingsCard';

const Toggle2FA = () => {
    return (
        <AccountSettingsCard
            title="Two-step authentication"
            desc="Two-step authentication (2FA) strengthens access security by requiring two methods (also referred to as
        factors) to verify your identity."
        >
            <TwoStepAuthentication />
        </AccountSettingsCard>
    );
};

export default Toggle2FA;
