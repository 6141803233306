import * as React from 'react';
import './styles.scss';
import DialogBody from './DialogBody';

const ControledDialog = ({
    Trigger = undefined,
    Submit = undefined,
    setOpen,
    open,
    title = '',
    children,
    withClose = true,
    withCancel = true,
    cancelText = undefined,
    minHeight = null,
    fullWidth = false,
}) => {
    return (
        <DialogBody
            Trigger={Trigger}
            Submit={Submit}
            setOpen={setOpen}
            open={open}
            title={title}
            withClose={withClose}
            withCancel={withCancel}
            cancelText={cancelText}
            minHeight={minHeight}
            fullWidth={fullWidth}
        >
            {children}
        </DialogBody>
    );
};

export default ControledDialog;
