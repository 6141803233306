import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../styles.scss';

import BillingLayout from '../Layout';
import TransactionsTable from './TransactionTable';
import BalanceTransactionsTable from './BalanceTransactionsTable';

import {
    selectTransactionsLoading,
    selectTransactionsTable,
    fetchTransactions,
    fetchBalanceTransactions,
    selectBalanceTransactionsTable,
    selectBalanceTransactionsLoading,
    selectTransactionsTotal,
    selectBalanceTransactionsTotal,
} from 'store/slices/billingTransactions';
import TransactionsBar from './TransactionBar';
import SimpleSelect from 'components/_common/Select/SimpleSelect';
import { fetchPaymentMethods } from 'store/slices/billingOrders';

const options = [
    { value: '1', label: 'Transactions' },
    { value: '2', label: 'Credit Transactions' },
];

const Transactions = () => {
    const [type, setType] = useState('1');
    const dispatch = useDispatch();

    const tr_data = useSelector(selectTransactionsTable);
    const tr_loading = useSelector(selectTransactionsLoading);
    const tr_total = useSelector(selectTransactionsTotal);

    const btr_data = useSelector(selectBalanceTransactionsTable);
    const btr_loading = useSelector(selectBalanceTransactionsLoading);
    const btr_total = useSelector(selectBalanceTransactionsTotal);

    const showDropdown = !!tr_data.length || !!btr_data.length;

    const [paymentMethods, setPaymentMethods] = useState({});

    useEffect(() => {
        (async () => {
            const payments = await fetchPaymentMethods();
            const storage = {};
            payments?.data.forEach(i => {
                storage[i.payment_method_id] = i;
            });

            setPaymentMethods(storage);
        })();
    }, []);

    return (
        <BillingLayout>
            <TransactionsBar />
            {showDropdown && (
                <SimpleSelect options={options} label="Type" value={type} onChange={setType} width={200} />
            )}
            {type === '1' && (
                <TransactionsTable
                    data={tr_data.map(i => ({
                        ...i,
                        payment_method_network: paymentMethods[i.payment_method_id]?.payment_method_network,
                        payment_method_last_four: paymentMethods[i.payment_method_id]?.payment_method_last_four,
                    }))}
                    dataLoading={tr_loading}
                    // @ts-ignore
                    fetchData={filters => dispatch(fetchTransactions(filters))}
                    //
                    total={tr_total}
                    noDataMessage={"You don't have transactions"}
                />
            )}
            {type === '2' && (
                <BalanceTransactionsTable
                    data={btr_data}
                    dataLoading={btr_loading}
                    // @ts-ignore
                    fetchData={filters => dispatch(fetchBalanceTransactions(filters))}
                    //
                    total={btr_total}
                    noDataMessage={"You don't have transactions"}
                />
            )}
        </BillingLayout>
    );
};

export default Transactions;
