import './styles.scss';

import Button from 'components/_common/Button';
import EmptyPageState from 'components/EmptyPageState';
import { ReactComponent as Icon } from 'icons/colored/empty_icon_summary.svg';

const ErrorPage = () => (
    <div className='error-page-wrapper'>
        <EmptyPageState Icon={<Icon />} title="Something went wrong">
            <Button onClick={() => window.location.reload()}>Refresh page</Button>
        </EmptyPageState>
    </div>
);

export default ErrorPage;
