import InfoSidebar from './InfoSidebar';
import './styles.scss';
import PageHeader from 'components/PageHeader';

const DashboardLayout = ({ children, top, services }) => (
    <div className="dashboard-layout">
        <PageHeader title="Dashboard" />
        <div className="dashboard-top">{top}</div>
        <div className="dashboard-services">{services}</div>
        <div className="dashboard-container">
            <div className="dashboard-content">{children}</div>
        </div>
        <InfoSidebar />
    </div>
);

export default DashboardLayout;
