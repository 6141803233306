import $api from 'api/http';
import urls from 'api/urls-config.json';
import { omit } from 'lodash';

export default class ProxyService {
    static async search(params) {
        return $api.get(urls.proxy.search, { params });
    }
    static async filterOptions() {
        return $api.get(urls.proxy.filter_options);
    }
    static async users(params) {
        return $api.get(urls.proxy.users, { params });
    }
    static async createUser(params) {
        return $api.post(urls.proxy.create_user, params);
    }
    static async editUser(params) {
        return $api.patch(urls.proxy.edit_user + params.id, { ...omit(params, ['id']) });
    }
    static async deleteUser(id) {
        return $api.delete(urls.proxy.delete_user + id);
    }
    static async list_by_id(params) {
        return $api.get(urls.proxy.list_by_id, { params });
    }
    static async list_by_search(params) {
        return $api.get(urls.proxy.list_by_search, { params });
    }
}
