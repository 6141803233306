import { styled } from '@mui/material';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { MaterialDesignContent } from 'notistack';
import { ReactComponent as CloseIcon } from 'icons/x-close.svg';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-success': {
        backgroundColor: 'var(--ping-Green-green-700)',
    },
    '&.notistack-MuiContent-error': {
        backgroundColor: 'var(--ping-Red-red-700)',
    },
    '&.notistack-MuiContent-warning': {
        backgroundColor: 'var(--ping-Orange-orange-700)',
    },
    '&.notistack-MuiContent-info': {
        backgroundColor: 'var(--ping-Blue-blue-700)',
    },
    '&.notistack-MuiContent-default': {
        backgroundColor: 'var(--ping-Brand-brand-700)',
    },
    '&.notistack-MuiContent': {
        alignItems: 'center',
        display: 'flex',
    },
    '&.notistack-MuiContent div#notistack-snackbar': {
        maxWidth: 'calc(100% - 40px)',
    },
    '&.notistack-MuiContent div#notistack-snackbar + div': {
        display: 'flex',
    },
    '&.notistack-MuiContent .notistack-close-button': {
        display: 'flex',
    },
}));

function SnackbarCloseButton({ snackbarKey }) {
    const { closeSnackbar } = useSnackbar();
    const handleClose = () => closeSnackbar(snackbarKey);

    return (
        <button className="notistack-close-button" onClick={handleClose}>
            <CloseIcon />
        </button>
    );
}

const NoticeProvider = ({ children }) => (
    <SnackbarProvider
        action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
        maxSnack={5}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        Components={{
            success: StyledMaterialDesignContent,
            error: StyledMaterialDesignContent,
            warning: StyledMaterialDesignContent,
            info: StyledMaterialDesignContent,
            default: StyledMaterialDesignContent,
        }}
    >
        {children}
    </SnackbarProvider>
);

export default NoticeProvider;
