import Button from 'components/_common/Button';
import ROUTES from 'helpers/routes';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
    fetchSingleBillingInvoicePayOptions,
    selectSingleBillingInvoice,
    selectSingleBillingInvoiceLoading,
    selectSingleBillingInvoicePayOptions,
} from 'store/slices/billingInvoices';
import { ReactComponent as Icon } from 'icons/chevron-left.svg';
import { convertToDollar } from 'helpers/payments';
import { useEffect, useState } from 'react';
import Spinner from 'components/_common/Spinner';
import WithSidebarLayout from 'Layout/WithSidebarLayout';
import InvoiceSidebar from 'components/Sidebars/InvoiceSidebar';
import ServiceCell from 'components/_common/TableCells/ServiceCell';
import SidebarDivider from 'components/Sidebars/Items/SidebarDivider';
import SidebarRow from 'components/Sidebars/Items/SidebarRow';
import StatusCell from 'components/_common/TableCells/StatusCell';
import InvoiceInfoBox from '../../components/InvoiceInfoBox';
import List from '../../components/InvoiceInfoBox/List';
import { fetchSingleBillingInvoice } from 'store/slices/billingInvoices';
import ServicesService from 'api/services/ServicesService';
import { formatPeriod, withoutEmptyValues } from 'helpers';
import BillingInfoCard from 'components/BillingInfoCard';
import InvoicesService from 'api/services/InvoicesService';
import { filterPaymentParamsByPlatform } from 'helpers/services';
import CancelButton from 'components/Buttons/CancelButton';
import { invoiceStatuses } from 'helpers/constants';

const SingleInvoicePage = () => {
    const { slug } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const data = useSelector(selectSingleBillingInvoice)?.[0];
    const invoiceLoading = useSelector(selectSingleBillingInvoiceLoading);
    const invoicePayOptions = useSelector(selectSingleBillingInvoicePayOptions);

    const [step, setStep] = useState(1);
    const [serviceLoading, setServiceLoading] = useState(false);
    const [paymentLinkLoading, setPaymentLinkLoading] = useState(false);
    const [paymentPlatform, setPaymentPlatform] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [paymentCoin, setPaymentCoin] = useState('');
    const [serviceData, setServiceData] = useState({});

    const {
        invoice_line_items,
        invoice_status,
        invoice_amount_paid,
        invoice_total,
        invoice_creation_datetime,
        service_id,
        invoice_id,
        invoice_pdf,
    } = data || {};

    // @ts-ignore
    const { payment_method_id, service_cycle, service_type, service_name, service_quantity } = serviceData;

    const handlePayInvoice = async () => {
        if (invoice_id) {
            try {
                setPaymentLinkLoading(true);
                const params = withoutEmptyValues({
                    coinpayments_currency: paymentCoin,
                    payment_method_id: paymentMethod,
                    payment_platform: paymentPlatform,
                });
                const filteredParams = filterPaymentParamsByPlatform(params.payment_platform, params);
                const response = await InvoicesService.generatePaymentLink(invoice_id, filteredParams);
                const redirect_url = response?.data?.data?.redirect_url;
                if (redirect_url) {
                    window.open(redirect_url, '_blank');
                }
            } catch (error) {
                return false;
            } finally {
                setPaymentLinkLoading(false);
            }
        }
    };

    const handleNextStep = () => {
        switch (step) {
            case 1:
                return setStep(2);
            case 2:
                return handlePayInvoice();
        }
    };

    useEffect(() => {
        // @ts-ignore
        dispatch(fetchSingleBillingInvoice(slug));
        //
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (invoice_id && invoice_status !== invoiceStatuses.PAID) {
            // @ts-ignore
            dispatch(fetchSingleBillingInvoicePayOptions(invoice_id));
            //
        }
    }, [invoice_id]);

    useEffect(() => {
        const getSingleServiceInfo = async () => {
            try {
                setServiceLoading(true);
                await ServicesService.retrieve(service_id).then(response => {
                    setServiceLoading(false);
                    // @ts-ignore
                    setServiceData(...response.data.data);
                });
            } catch (e) {
                return false;
            }
        };
        if (!serviceLoading && service_id) {
            getSingleServiceInfo();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [service_id]);

    const converted_invoice_total = convertToDollar(invoice_total);
    const converted_invoice_amount_paid = convertToDollar(invoice_amount_paid);
    const converted_service_cycle = formatPeriod(service_cycle);
    const service_info = {
        type: service_type,
        name: service_name,
        value: service_quantity,
        id: service_id,
    };
    const items = invoice_line_items?.map(item => item.description);

    return (
        <div>
            <div className="navigation-buttons-wrapper">
                <Button
                    onClick={step === 1 ? () => navigate(-1) : () => setStep(prevState => prevState - 1)}
                    variant="outlined"
                    className="bg-white mb-24"
                    Icon={<Icon />}
                >
                    Back
                </Button>
                {step === 1 || <CancelButton onClick={() => navigate(-1)} />}
            </div>
            {invoiceLoading || serviceLoading || paymentLinkLoading ? (
                <Spinner />
            ) : (
                <WithSidebarLayout
                    aside={
                        <InvoiceSidebar
                            invoice_total={converted_invoice_total}
                            invoice_amount_paid={converted_invoice_amount_paid}
                            payment_method_id={payment_method_id}
                            datetime={invoice_creation_datetime}
                            invoice_status={invoice_status}
                            handleNextStep={handleNextStep}
                            invoice_pdf={invoice_pdf}
                        />
                    }
                >
                    {step === 1 && (
                        <>
                            <InvoiceInfoBox title="Invoice details">
                                <ServiceCell data={service_info} />
                                <SidebarDivider />
                                <SidebarRow name="Invoice creation datetime:">{invoice_creation_datetime}</SidebarRow>
                                <SidebarRow name="Invoice id:">{invoice_id}</SidebarRow>
                                <SidebarRow name="Service id:">{service_id}</SidebarRow>
                                <SidebarRow name="Period:">{converted_service_cycle}</SidebarRow>
                                <SidebarRow name="Status:">
                                    <StatusCell status={invoice_status} />
                                </SidebarRow>
                            </InvoiceInfoBox>
                            {!!items?.length && (
                                <InvoiceInfoBox title="Invoice line items">
                                    <List items={items} />
                                </InvoiceInfoBox>
                            )}
                        </>
                    )}
                    {step === 2 && (
                        <BillingInfoCard
                            data={invoicePayOptions}
                            paymentPlatform={paymentPlatform}
                            setPaymentPlatform={setPaymentPlatform}
                            paymentMethod={paymentMethod}
                            setPaymentMethod={setPaymentMethod}
                            paymentCoin={paymentCoin}
                            setPaymentCoin={setPaymentCoin}
                        />
                    )}
                </WithSidebarLayout>
            )}
        </div>
    );
};

export default SingleInvoicePage;
