import { ReactComponent as Plus } from 'icons/plus.svg';
import { ReactComponent as Goggle } from 'icons/colored/google.svg';
import { ReactComponent as Download } from 'icons/download.svg';
import { ReactComponent as Edit } from 'icons/edit.svg';

export const getIcon = name => {
    const icons = {
        plus: <Plus />,
        google: <Goggle />,
        download: <Download />,
        edit: <Edit />,
    };
    return icons[name];
};
