import { FormControl, FormLabel } from '@mui/material';
import './styles.scss';
import SliderComponent from '@mui/material/Slider';

const ReconfigureSlider = ({ value, onChange, min, max, marks, label }) => (
    <div className="custom-slider reconfigure-slider">
        <FormControl fullWidth>
            <FormLabel focused={false}>{label}</FormLabel>
            <div className="reconfigure-slider__inner">
                <SliderComponent
                    min={min}
                    max={max}
                    value={value}
                    onChange={onChange}
                    marks={marks}
                    valueLabelDisplay="auto"
                />
            </div>
        </FormControl>
    </div>
);

export default ReconfigureSlider;
