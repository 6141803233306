import Table from 'components/_common/Table';
import TextCell from 'components/_common/TableCells/TextCell';
import Label from 'components/_common/Label';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchResidentialLedger,
    selectResidentialLedgerLoading,
    selectResidentialLedgerTotal,
    selectResidentialLedgerTableData,
} from 'store/slices/residentialLedger';

const reasons = {
    usage: 'Usage',
    top_up: 'Top up',
    administrator_adjustment: 'Administrator adjustment',
};

const ResidentialTable = () => {
    const dispatch = useDispatch();
    const data = useSelector(selectResidentialLedgerTableData);
    const loading = useSelector(selectResidentialLedgerLoading);
    const total = useSelector(selectResidentialLedgerTotal);

    const columns = React.useMemo(
        () => [
            {
                field: 'residential_ledger_creation_datetime',
                headerName: 'Date',
                minWidth: 230,
                flex: 1,
                renderCell: ({ value, row }) => (
                    <TextCell text={row.residential_ledger_period_date || row.residential_ledger_creation_datetime} />
                ),
            },
            {
                field: 'residential_ledger_reason',
                headerName: 'Reason',
                minWidth: 230,
                flex: 1,
                renderCell: ({ value }) => <Label text={reasons[value] || value} />,
            },
            {
                field: 'bytes',
                headerName: 'Bandwidth',
                minWidth: 230,
                flex: 1,
                renderCell: ({ value, row }) => (
                    <Label
                        color={value == 0 ? 'grey' : row.residential_ledger_bytes >= 0 ? 'green' : 'red'}
                        text={`${value} GB`}
                    />
                ),
            },
            {
                field: 'requests',
                headerName: 'Requests',
                minWidth: 230,
                flex: 1,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
        ],
        [],
    );

    return (
        <Table
            columns={columns}
            rows={data}
            total={total}
            sortModel={[
                {
                    field: 'residential_ledger_creation_datetime',
                    sort: 'desc',
                },
            ]}
            // @ts-ignore
            fetchData={filters => dispatch(fetchResidentialLedger(filters))}
            dataLoading={loading}
        />
    );
};

export default ResidentialTable;
