import ProxyService from 'api/services/ProxyService';
import DeleteButton from 'components/Buttons/DeleteButton';
import showMessage from 'components/NoticeProvider/showMessage';
import ControledDialog from 'components/_common/Dialog/ControledDialog';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchProxyUsers } from 'store/slices/proxyUsers';

const ModalDeleteUserProxy = ({ setOpen, open, id }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const handleDelete = async () => {
        try {
            setLoading(true);
            await ProxyService.deleteUser(id)
                .then(response => {
                    showMessage(response.data.message, response.status);
                    setLoading(false);
                })
                .then(() => {
                    // @ts-ignore
                    dispatch(fetchProxyUsers());
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            return false;
        }
    };

    return (
        <ControledDialog
            title="Delete proxy user"
            Submit={<DeleteButton loading={loading} onClick={handleDelete} />}
            setOpen={setOpen}
            open={open}
        >
            <p className="body1">Are you sure you want to delete '{id}' user? This action cannot be undone.</p>
        </ControledDialog>
    );
};

export default ModalDeleteUserProxy;
