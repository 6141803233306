import React, { useState } from 'react';
import Table from 'components/_common/Table';
import StatusCell from 'components/_common/TableCells/StatusCell';
import TextCell from 'components/_common/TableCells/TextCell';
import ModalReplacementsDetails from 'components/Modals/ModalReplacementsDetails';
import ModalExportReplacement from 'components/Modals/ModalExportReplacement';
import Button from 'components/_common/Button';

const minWidth = 140;

const ProxyListTable = ({ data, dataLoading, fetchData, total, noDataComponent }) => {
    const [replacementsDetailsOpen, setReplacementsDetailsOpen] = useState(false);
    const [replacementExportOpen, setReplacementExportOpen] = useState(false);
    const [selectedReplacement, setSelectedReplacement] = useState(null);

    const columns = React.useMemo(
        () => [
            {
                field: 'service_id',
                headerName: 'Service ID',
                flex: 1,
                minWidth,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'id',
                headerName: 'Replacement ID',
                flex: 1,
                minWidth,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'service_adjustment_status',
                headerName: 'status',
                minWidth: 220,
                flex: 1,
                renderCell: ({ value }) => <StatusCell status={value} />,
            },
            {
                field: 'service_adjustment_creation_datetime',
                headerName: 'Created Date',
                minWidth: 200,
                flex: 1,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'copy',
                headerName: '',
                minWidth: 300,
                align: 'right',
                renderCell: ({ row }) => (
                    <div style={{ display: 'flex', gap: 8 }}>
                        <Button
                            onClick={() => {
                                setSelectedReplacement(row);
                                setReplacementExportOpen(true);
                            }}
                            variant="outlined"
                        >
                            Export
                        </Button>
                        <Button
                            onClick={() => {
                                setSelectedReplacement(row);
                                setReplacementsDetailsOpen(true);
                            }}
                            variant="outlined"
                        >
                            View Replacement
                        </Button>
                    </div>
                ),
            },
        ],
        [],
    );

    return (
        <>
            <Table
                columns={columns}
                rows={data}
                dataLoading={dataLoading}
                fetchData={fetchData}
                total={total}
                noDataComponent={noDataComponent}
            />
            <ModalReplacementsDetails
                setOpen={setReplacementsDetailsOpen}
                open={replacementsDetailsOpen}
                details={selectedReplacement?.proxy_replacements}
            />
            <ModalExportReplacement
                setOpen={setReplacementExportOpen}
                open={replacementExportOpen}
                details={selectedReplacement?.proxy_replacements}
            />
        </>
    );
};

export default ProxyListTable;
