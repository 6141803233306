import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import ApiKeysService from 'api/services/ApiKeysService';

export const fetchApiKeys = createAsyncThunk('fetchApiKeys', async (params) => {
    const response = await ApiKeysService.search(params);
    return response.data;
});

const ApiKeysSlice = createSlice({
    name: 'ApiKeysSlice',
    initialState: {
        apiKeys: {
            loading: false,
            data: [],
            error: null,
            total: 0,
        },
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchApiKeys.pending, state => {
                state.apiKeys.loading = true;
            })
            .addCase(fetchApiKeys.fulfilled, (state, action) => {
                state.apiKeys.loading = false;
                state.apiKeys.data = action.payload.data;
                state.apiKeys.total = action.payload.total_count;
            })
            .addCase(fetchApiKeys.rejected, (state, action) => {
                state.apiKeys.loading = false;
                state.apiKeys.error = action.error;
            });
    },
});

export const selectApiKeys = createSelector([state => state.apiKeysState.apiKeys], apiKeys => apiKeys.data);
export const selectApiKeysTableData = createSelector([selectApiKeys], data =>
    data.map(item => ({
        id: item.api_key_id,
        label: item.api_key_label,
        status: item.api_key_status,
        preview: item.api_key_private_key_preview,
        api_key: item.api_key_public_key,
        created__sort: item.api_key_creation_datetime,
    })),
);

export const selectApiKeysLoading = createSelector([state => state.apiKeysState.apiKeys], apiKeys => apiKeys.loading);

export const selectApiKeysTotal = createSelector(
    [state => state.apiKeysState.apiKeys],
    apiKeys => apiKeys.total,
);

export default ApiKeysSlice.reducer;
