import './styles.scss';
import SliderComponent from '@mui/material/Slider';

const Slider = ({
    value,
    onChange,
    marks,
    valueText,
    valueLabelFormat,
    min,
    max,
    className = '',
    disabled = false,
}) => (
    <div className={`custom-slider ${className}`}>
        <SliderComponent
            min={min}
            max={max}
            step={null}
            marks={marks}
            value={value}
            disabled={disabled}
            onChange={onChange}
            valueLabelDisplay="on"
            getAriaValueText={valueText}
            valueLabelFormat={valueLabelFormat}
        />
    </div>
);

export default Slider;
