import * as React from 'react';
import { styled } from '@mui/material/styles';
import { ReactComponent as Icon } from 'icons/colored/empty_icon.svg';

const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
}));

const CustomNoRowsOverlay = ({
    title = 'No search results found',
    message = 'Try a different search query',
    showMessage,
}) => (
    <StyledGridOverlay>
        <Icon className="pb-4" />
        <div className="h5">{title}</div>
        {showMessage && <p className="body1 text-grey-500">{message}</p>}
    </StyledGridOverlay>
);

export default CustomNoRowsOverlay;
