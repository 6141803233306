import { convertToDollar } from 'helpers/payments';
import '../styles.scss';
import { billingDates, GB, IP } from 'helpers/constants';

const FinalState = ({ secondData, isResidential }) => {
    const { service_quantity, service_price_id, due, proration_behavior, total } = secondData;

    return (
        <div className="reconfigure-popup-final">
            <p>Details</p>
            <p>
                <span>New quantity:</span>
                <span>
                    {service_quantity} {isResidential ? GB : IP}
                </span>
            </p>
            <p>
                <span>New Price ID:</span>
                <span>{service_price_id}</span>
            </p>
            <p>
                <span>New Billing date:</span>
                <span>{due}</span>
            </p>
            <p>
                <span>Prorated:</span>
                <span>{proration_behavior}</span>
            </p>
            <hr />
            <p>
                <span>Total amount: </span>
                <span className="h4">{convertToDollar(total)}</span>
            </p>
            <hr />
            {due === billingDates.NOW && (
                <p className="box">
                    <span>
                        {isResidential
                            ? 'Your subscription billing date will be altered and payment will be taken from your account immediately. Bandwidth will be added to your account immediately.'
                            : 'Your subscription billing date will be altered and payment will be taken from your account immediately.'}
                    </span>
                    <span />
                </p>
            )}
        </div>
    );
};

export default FinalState;
