import { useState, useEffect } from 'react';

const getQuery = query => {
    const queries = {
        mobile: '(max-width: 767px)',
        tablet: '(max-width: 1023px)',
        desktop: '(min-width: 1200px)',
    };
    return queries[query] || `(${query})`;
};

const useMediaQuery = query => {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
        const media = window.matchMedia(getQuery(query));
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        const listener = () => setMatches(media.matches);
        window.addEventListener('resize', listener);
        return () => window.removeEventListener('resize', listener);
    }, [matches, query]);

    return matches;
};

export default useMediaQuery;
