import { useDispatch, useSelector } from 'react-redux';
import UserActions from './UserActions';
import { customerRetrieve, selectCustomer } from 'store/slices/customer';
import { convertToDollar } from 'helpers/payments';
import ModalTopUp from 'components/Modals/ModalTopUp';
import { useEffect } from 'react';

const UserInfo = () => {
    const dispatch = useDispatch();
    const { customer_first_name, customer_last_name, customer_email_address, credit_balance } =
        useSelector(selectCustomer);

    const convertedBalance = convertToDollar(credit_balance);

    useEffect(() => {
        const intervalId = setInterval(() => {
            // @ts-ignore
            dispatch(customerRetrieve());
        }, 15000)

        return () => clearInterval(intervalId)
    }, [dispatch])

    return (
        <div>
            <div className="user-balance">
                <div>{credit_balance === undefined ? '' : convertedBalance}</div>
                <ModalTopUp labelButton={true} labelColor="purple" />
            </div>
            <div className="user-info">
                <div>
                    <div className="subtitle2">
                        <div>
                            {customer_first_name} {customer_last_name}
                        </div>
                    </div>
                    <div className="body2 text-grey-500">{customer_email_address}</div>
                </div>
                <UserActions />
            </div>
        </div>
    );
};

export default UserInfo;
