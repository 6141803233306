import CopyButton from 'components/Buttons/CopyButton';
import MessageText from 'components/MessageText';
import Button from 'components/_common/Button';
import ControledDialog from 'components/_common/Dialog/ControledDialog';
import TextInput from 'components/_common/TextInput';
import { useEffect, useState } from 'react';

const ModalShowCreatedApiKey = ({ setOpen, open, keys }) => {
    const { api_key_private_key, api_key_public_key } = keys;

    const [copied, setCopied] = useState(false);

    const handleCopyClick = () => setCopied(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        setCopied(false);
    }, [open]);

    return (
        <ControledDialog
            title="API Key Successfully Generated"
            setOpen={setOpen}
            open={open}
            withClose={false}
            withCancel={false}
            Submit={
                <Button color="error" onClick={handleClose} disabled={!copied}>
                    Close
                </Button>
            }
        >
            <div className="box">
                <MessageText
                    small
                    noIcon
                    type="bad"
                    text="Please copy your API Private Key and securely store it. You will not be able to see it again after
                closing this pop up."
                />
            </div>
            <h6>Private Key:</h6>
            <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                <TextInput disabled value={api_key_private_key} onChange={null}></TextInput>
                <CopyButton
                    withoutText
                    onClick={handleCopyClick}
                    copyValue={api_key_public_key}
                    customStyles={{ height: 40 }}
                />
            </div>
            <h6>Public Key:</h6>
            <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                <TextInput disabled value={api_key_public_key} onChange={null}></TextInput>
                <CopyButton withoutText copyValue={api_key_private_key} customStyles={{ height: 40 }} />
            </div>
        </ControledDialog>
    );
};

export default ModalShowCreatedApiKey;
