import Button from 'components/_common/Button';

const ClearFiltersButton = ({ onClick, isShow }) =>
    isShow ? (
        <Button variant="text" onClick={onClick}>
            clear filters
        </Button>
    ) : undefined;

export default ClearFiltersButton;
