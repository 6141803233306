import dayjs from 'dayjs';
import _ from 'lodash';
import { GB, REQUEST_DATE_FORMAT } from './constants';

export const addZeroes = num => num.toLocaleString('en', { useGrouping: false, minimumFractionDigits: 2 });

export function deepEqual(x, y) {
    return x && y && typeof x === 'object' && typeof y === 'object'
        ? Object.keys(x).length === Object.keys(y).length &&
              Object.keys(x).reduce(function (isEqual, key) {
                  return isEqual && deepEqual(x[key], y[key]);
              }, true)
        : x === y;
}

export function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function convertBandwidth(value, toUnit = GB, fromUnit = 'KB', fixed = 2) {
    const units = {
        b: 1,
        byte: 1,
        kb: Math.pow(2, 10),
        kilobyte: Math.pow(2, 10),
        mb: Math.pow(2, 20),
        megabyte: Math.pow(2, 20),
        gb: Math.pow(2, 30),
        gigabyte: Math.pow(2, 30),
        tb: Math.pow(2, 40),
        terabyte: Math.pow(2, 40),
    };

    fromUnit = fromUnit.toLowerCase();
    toUnit = toUnit.toLowerCase();

    if (!units.hasOwnProperty(fromUnit)) {
        throw new Error(
            "Invalid 'from' unit. Supported units are b, B, KB, MB, GB, TB, kilobyte, megabyte, gigabyte, terabyte.",
        );
    }
    if (!units.hasOwnProperty(toUnit)) {
        throw new Error(
            "Invalid 'to' unit. Supported units are b, B, KB, MB, GB, TB, kilobyte, megabyte, gigabyte, terabyte.",
        );
    }

    const bytesValue = value * units[fromUnit];

    const convertedValue = bytesValue / units[toUnit];

    return convertedValue.toFixed(fixed);
}

export const isObjectEmpty = objectName => {
    return _.isEmpty(objectName);
};

export const convertDate = date => dayjs.utc(date).format(REQUEST_DATE_FORMAT);

export const calculateInterval = (startDateStr, endDateStr) => {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    // @ts-ignore
    const timeDifference = Math.abs(endDate - startDate);
    const timeDifferenceInDays = timeDifference / (1000 * 3600 * 24);

    const isIn3Days = Math.abs(dayjs(startDateStr).diff(dayjs(), 'hours')) <= 72;

    if (timeDifference < 60 * 60 * 1000 && isIn3Days) {
        // Less than 1 hour
        return '1 minute';
    } else if (timeDifference < 1 * 24 * 60 * 60 * 1000) {
        // 1 hour to 1 days
        return '10 minute';
    } else if (timeDifference < 3 * 24 * 60 * 60 * 1000) {
        // 1 day to 3 days
        return '1 hour';
    } else if (timeDifferenceInDays < 365) {
        // 3 days to 365 days
        return '1 day';
    } else {
        return 'Interval not defined for the given range';
    }
};

export const uppercaseFirst = str => str.charAt(0).toUpperCase() + str.slice(1);

export const getUnique = data => data.filter((value, index, array) => array.indexOf(value) === index);

export const formatPeriod = period => {
    const data = {
        '1:year': '12 Months',
        '1:month': '1 Month',
        '3:month': '3 months',
    };
    return data[period];
};

export const getChangedKeys = (o1, o2) => {
    const keys = _.union(_.keys(o1), _.keys(o2));
    return _.filter(keys, key => o1[key] !== o2[key]);
};

export const getChangedParams = (initial, result) =>
    getChangedKeys(initial, result).reduce((acc, key) => ({ ...acc, [key]: result[key] }), {});

export function withoutEmptyValues(obj) {
    return _.pickBy(obj, value => {
        return value !== '' && !_.isNull(value) && !_.isUndefined(value);
    });
}

export const calculateDiscountPercentage = (beforeDiscountTotal, discount) => {
    if (beforeDiscountTotal === 0) return 0;
    return (discount / beforeDiscountTotal) * 100;
};

export const sortNewFirst = data => data.toSorted((a, b) => (dayjs(a).isAfter(dayjs(b)) ? 1 : -1));

export const metricTypes = {
    bytes: 'bytes',
    megabytes: 'megabytes',
    gigabytes: 'gigabytes',
    requests: 'requests',
    errorRate: 'errorRate',
};

export const metricSelect = [
    { value: metricTypes.bytes, label: 'Bytes' },
    { value: metricTypes.megabytes, label: 'Megabytes' },
    { value: metricTypes.gigabytes, label: 'Gigabytes' },
    { value: metricTypes.requests, label: 'Requests' },
    { value: metricTypes.errorRate, label: 'Error Rate' },
];