import { GridActionsCellItem } from '@mui/x-data-grid';
import ApiKeysService from 'api/services/ApiKeysService';
import DeleteButton from 'components/Buttons/DeleteButton';
import showMessage from 'components/NoticeProvider/showMessage';
import Dialog from 'components/_common/Dialog';
import { ReactComponent as Trash } from 'icons/trash.svg';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchApiKeys } from 'store/slices/apiKeys';

const ModalDeleteApiKey = ({ id, preview }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const handleDelete = async () => {
        try {
            setLoading(true);
            await ApiKeysService.delete(id)
                .then(response => {
                    showMessage(response.data.message, response.status);
                    setLoading(false);
                })
                .then(() => {
                    // @ts-ignore
                    dispatch(fetchApiKeys());
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            return false;
        }
    };

    return (
        <Dialog
            title="Delete API key"
            Trigger={<GridActionsCellItem icon={<Trash />} color="error" label="Delete" />}
            Submit={<DeleteButton onClick={handleDelete} loading={loading} />}
        >
            Are you sure you want to delete API key "{preview}..."? This action cannot be undone.
        </Dialog>
    );
};

export default ModalDeleteApiKey;
