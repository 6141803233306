import '../styles.scss';
import CopyButton from 'components/Buttons/CopyButton';

const CopyCell = ({ value }) => (
    <div className="copy-cell">
        <CopyButton copyValue={value} withoutText />
    </div>
);

export default CopyCell;
