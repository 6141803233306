import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchProducts, selectMainProductsArray, selectProductsIsLoading } from 'store/slices/products';
import ProductPage from './ProductPage';
import { useEffect } from 'react';
import ROUTES from 'helpers/routes';
import Spinner from 'components/_common/Spinner';

const Product = () => {
    const dispatch = useDispatch();
    const { slug } = useParams();
    const navigate = useNavigate();
    const loading = useSelector(selectProductsIsLoading);
    const products = useSelector(selectMainProductsArray);

    const currentProduct = products.find(product => product.product_type === slug);

    useEffect(() => {
        // @ts-ignore
        dispatch(fetchProducts());
    }, [dispatch]);

    useEffect(() => {
        if (!currentProduct && !loading) {
            navigate(ROUTES[404]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProduct, loading]);

    if (loading) return <Spinner />;

    return currentProduct && <ProductPage currentProduct={currentProduct} />;
};

export default Product;
