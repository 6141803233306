export const datacenter_marks = [
    { label: '1', value: 1 },
    { label: '', value: 2 },
    { label: '', value: 3 },
    { label: '', value: 4 },
    { label: '', value: 5 },
    { label: '', value: 6 },
    { label: '', value: 7 },
    { label: '', value: 8 },
    { label: '', value: 9 },
    { label: '', value: 10 },
    { label: '', value: 20 },
    { label: '', value: 30 },
    { label: '', value: 40 },
    { label: '50', value: 50 },
    { label: '', value: 75 },
    { label: '100', value: 100 },
    { label: '', value: 125 },
    { label: '150', value: 150 },
    { label: '', value: 175 },
    { label: '200', value: 200 },
    { label: '', value: 225 },
    { label: '', value: 250 },
    { label: '300', value: 300 },
    { label: '', value: 350 },
    { label: '400', value: 400 },
    { label: '', value: 450 },
    { label: '500', value: 500 },
    { label: '600', value: 600 },
    { label: '700', value: 700 },
    { label: '800', value: 800 },
    { label: '900', value: 900 },
    { label: '1000', value: 1000 },
];

export const residential_marks = [
    { label: '1GB', value: 1, realValue: 1 },
    { label: '2GB', value: 2, realValue: 2 },
    { label: '5GB', value: 3, realValue: 5 },
    { label: '10GB', value: 4, realValue: 10 },
    { label: '25GB', value: 5, realValue: 25 },
    { label: '50GB', value: 6, realValue: 50 },
    { label: '100GB', value: 7, realValue: 100 },
    { label: '250GB', value: 8, realValue: 250 },
    { label: '500GB', value: 9, realValue: 500 },
    { label: '1TB', value: 10, realValue: 1000 },
    { label: '1.5TB', value: 11, realValue: 1500 },
    { label: '2TB', value: 12, realValue: 2000 },
    { label: '3TB', value: 13, realValue: 3000 },
    { label: '4TB', value: 14, realValue: 4000 },
    { label: '5TB', value: 15, realValue: 5000 },
];

export const ip_min = datacenter_marks[0].value;
export const ip_max = datacenter_marks[datacenter_marks.length - 1].value;

export const gb_min = residential_marks[0].value;
export const gb_max = residential_marks.length;
export const gb_max_real = residential_marks[residential_marks.length - 1].realValue;