import '../styles.scss';
import Button from 'components/_common/Button';

const SubmitButton = ({ children, loading = false, onClick = undefined, disabled = false }) => (
    <Button
        fullWidth
        type="submit"
        size="large"
        onClick={onClick}
        className="auth-button"
        disabled={disabled}
        loading={loading}
    >
        {children}
    </Button>
);

export default SubmitButton;
