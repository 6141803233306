import './styles.scss';

const FilterBar = ({ left, right }) => {
    return (
        <div className="filterbar">
            <div className="filterbar__start">{left}</div>
            <div className="filterbar__end">{right}</div>
        </div>
    );
};

export default FilterBar;
