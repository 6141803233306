import FormTemplate from 'components/FormTemplate';
import AuthText from 'components/FormTemplate/Items/AuthText';
import SubmitButton from 'components/FormTemplate/Items/SubmitButton';
import OTPInput from 'components/_common/OTPInput';

const TwoFactorForm = ({ otp, setOtp, loading, onSubmit, children }) => {
    return (
        <FormTemplate
            title="Two-factor authentication"
            desc="Enter the confirmation 8-digit code send to your contact method of choice."
        >
            <OTPInput value={otp} onChange={setOtp} label="8-digit code" sizeBig disabled={loading} />
            <SubmitButton loading={loading} onClick={onSubmit} disabled={otp.length < 8}>
                Submit code
            </SubmitButton>
            <AuthText>{children}</AuthText>
        </FormTemplate>
    );
};

export default TwoFactorForm;
