import MultipleSelect from 'components/_common/Select/MultipleSelect';
import { useSelector } from 'react-redux';
import { selectProxiesForDropdown, selectProxiesForDropdownLoading } from 'store/slices/reconfigureService';

const RemoveProxiesDropdown = ({ value, service_quantity, serviceQuantity, onChange }) => {
    const proxies_options = useSelector(selectProxiesForDropdown);
    const loading = useSelector(selectProxiesForDropdownLoading);

    const dropdownLabel = `Proxies for remove (${value.length}/${service_quantity - serviceQuantity})`;

    return (
        <MultipleSelect
            loading={loading}
            fullWidth
            value={value}
            withDisplayOptions
            onChange={onChange}
            label={dropdownLabel}
            options={proxies_options}
            placeholder="Select proxies"
        />
    );
};

export default RemoveProxiesDropdown;
