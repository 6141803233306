import DateTimePickerRange from 'components/_common/DateTimePickerRange';

const Filters = ({ range, setRange, minStartDateTime, maxEndDateTime, onError }) => {
    return (
        <DateTimePickerRange
            range={range}
            setRange={setRange}
            minStartDateTime={minStartDateTime}
            maxEndDateTime={maxEndDateTime}
            onError={onError}
        />
    );
};
export default Filters;
