// @ts-nocheck
import MuiButton from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import './styles.scss';
import { getIcon } from './helpers';
// "contained" | "text" | "outlined"
// small | medium | large

const Button = ({
    children,
    variant = 'contained',
    size = 'medium',
    color = 'default',
    fullWidth = false,
    onClick = undefined,
    className = '',
    Icon = undefined,
    iconName = '',
    to = '',
    disabled = false,
    loading = false,
    type = undefined,
    customStyles = {}
}) => {
    return (
        <MuiButton
            to={to}
            type={type}
            size={size}
            variant={variant}
            onClick={onClick}
            disabled={disabled || loading}
            fullWidth={fullWidth}
            startIcon={Icon || getIcon(iconName)}
            component={to ? NavLink : undefined}
            className={`custom-button color-${color} ${className}`}
            sx={customStyles}
        >
            {children}
            {/* {loading && <span className="custom-button__loading">...</span>} */}
            {loading && <span className="custom-button__loading" />}
        </MuiButton>
    );
};

export default Button;
