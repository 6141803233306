import BillingLayout from '../Layout';
import '../styles.scss';
import PaymentSettingsTable from './Table';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectBillingPaymentsLoading,
    selectBillingPaymentsTable,
    selectBillingPaymentsTotal,
    fetchBillingPayments,
} from 'store/slices/billingPaymentSettings';
import NoData from './NoData';

const PaymentSettings = () => {
    const dispatch = useDispatch();
    const data = useSelector(selectBillingPaymentsTable);
    const loading = useSelector(selectBillingPaymentsLoading);
    const total = useSelector(selectBillingPaymentsTotal);

    const showButton = !loading && data.length > 0;

    return (
        <BillingLayout>
            <PaymentSettingsTable
                data={data}
                dataLoading={loading}
                // @ts-ignore
                fetchData={filters => dispatch(fetchBillingPayments(filters))}
                //
                staticFilters={{
                    payment_method_status: 'attached',
                }}
                total={total}
                noDataComponent={<NoData/>}
            />
        </BillingLayout>
    );
};

export default PaymentSettings;
