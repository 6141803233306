import PageHeader from 'components/PageHeader';
import ApiRequestsTable from './Table';
import { useDispatch, useSelector } from 'react-redux';
import { fetchApiRequests, selectApiRequestsLoading, selectApiRequestsTableData, selectApiRequestsTotal } from 'store/slices/apiRequests';
import Chart from './Chart';
import InfoBanner from 'components/InfoBanner';

const ApiRequests = () => {
    const dispatch = useDispatch();
    const data = useSelector(selectApiRequestsTableData);
    const loading = useSelector(selectApiRequestsLoading);
    const total = useSelector(selectApiRequestsTotal);

    return (
        <div>
            <PageHeader title="API Requests" />
            <Chart />
            <ApiRequestsTable
                data={data}
                dataLoading={loading} // @ts-ignore
                fetchData={filters => dispatch(fetchApiRequests(filters))}
                //
                total={total}
                noDataMessage={"You don't have any API requests yet"}
            />
            <InfoBanner />
        </div>
    );
};

export default ApiRequests;
