import './styles.scss';
import Logo from 'components/Logo';
import Notifications from 'components/Notifications';
import SidebarBody from './SidebarBody';
import SidebarFooter from './SidebarFooter';
import useMediaQuery from 'hooks/useMediaQuery';
import { useState } from 'react';
import SwipeableTemporaryDrawer from './Items/SwipeableTemporaryDrawer';

const content = () => (
    <>
        <SidebarBody />
        <SidebarFooter />
    </>
);

const Sidebar = () => {
    const [open, setOpen] = useState(false);
    const isTablet = useMediaQuery('tablet');

    return (
        <aside className="sidebar">
            <div className="sidebar-header">
                <Logo />
                <Notifications />
                {isTablet && (
                    <SwipeableTemporaryDrawer open={open} setOpen={setOpen}>
                        {content()}
                    </SwipeableTemporaryDrawer>
                )}
            </div>
            {!isTablet && content()}
        </aside>
    );
};

export default Sidebar;
