import Autocomplete from 'components/_common/Autocomplete';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCountries, selectCountriesOptions, fetchCountriesDropdown } from 'store/slices/customer';

const CountriesDropdown = ({ value, onChange }) => {
    const dispatch = useDispatch();
    const options = useSelector(selectCountriesOptions);

    useEffect(() => {
        // @ts-ignore
        dispatch(fetchCountries({per_page: 500}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Autocomplete
            fullWidth
            withFormLabel
            label="Country"
            value={value}
            options={options}
            onChange={onChange}
            fetchData={query => fetchCountriesDropdown({ like_country_name: `%${query}%` })}
        />
    );
};

export default CountriesDropdown;
