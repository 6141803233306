import Button from 'components/_common/Button';
import ROUTES from 'helpers/routes';

const GoggleButton = ({queryParams}) => {

    return (
        <Button fullWidth size="large" to={`${ROUTES.signUp}${queryParams}`} variant="outlined" iconName="plus" className="bg-white mt-24">
            Create an account
        </Button>
    );
};
export default GoggleButton;
