import './styles.scss';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FileSaver from 'file-saver';
import dayjs from 'dayjs';

import { selectSelectedIDs, setIDs } from 'store/slices/proxy';
import { fetchProxyUsers, selectProxyUsersOptions } from 'store/slices/proxyUsers';
import ProxyService from 'api/services/ProxyService';

import showMessage from 'components/NoticeProvider/showMessage';
import ExportButton from 'components/Buttons/ExportButton';
import Button from 'components/_common/Button';
import RadioGroup from 'components/_common/RadioGroup';
import ControledDialog from 'components/_common/Dialog/ControledDialog';
import SimpleSelect from 'components/_common/Select/SimpleSelect';
import MessageText from 'components/MessageText';
import ProxyUserDropdown from 'components/ProxyUserDropdown';

import { exportProxyAuthTypes, exportProxyAuthTypesOptions } from 'helpers/constants';
import { withoutEmptyValues } from 'helpers';

const list_protocol_options = [
    { value: 'http', label: 'HTTP' },
    { value: 'socks5', label: 'SOCKS5' },
];
const list_format_options = [
    { value: 'standard', label: 'Standard' },
    { value: 'socks5', label: 'SOCKS5' },
    { value: 'http', label: 'HTTP' },
];
const export_ext_options = [{ value: 'TXT', label: 'TXT' }];

const ModalExportProxyList = ({ filterProps }) => {
    const dispatch = useDispatch();
    const ids = useSelector(selectSelectedIDs);
    const users_options = useSelector(selectProxyUsersOptions);
    const [open, setOpen] = useState(false);
    const [list_protocol, setListProtocol] = useState(list_protocol_options[0].value);
    const [list_format, setListFormat] = useState(list_format_options[0].value);
    const [ext, setExt] = useState(export_ext_options[0].value);
    const [authType, setAuthType] = useState(exportProxyAuthTypes.PROXY_USER_AUTH);
    const [user, setUser] = useState('');
    const [loading, setLoading] = useState(false);

    const isUserAuthType = authType === exportProxyAuthTypes.PROXY_USER_AUTH;
    const isIPAuthType = authType === exportProxyAuthTypes.IP_AUTH;
    const isProxySpecificAuthType = authType === exportProxyAuthTypes.PROXY_SPECIFIC_AUTH;

    const { country, search, type, service } = filterProps;

    useEffect(() => {
        setUser('');
    }, [open]);

    useEffect(() => {
        // @ts-ignore
        dispatch(fetchProxyUsers());
        return () => {
            dispatch(setIDs([]));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClick = async () => {
        try {
            setLoading(true);
            const params = {
                list_protocol,
                list_format,
            };

            if (isUserAuthType && user) {
                params.list_authentication = 'username_and_password';
                params.proxy_user_id = user;
            }
            if (isIPAuthType) {
                params.list_authentication = 'ip_address';
            }
            if (isProxySpecificAuthType) {
                params.list_authentication = 'proxy_specific';
            }
            if (!ids.length) {
                params.country_id = country;
                params.like_ip_address_id_v4 = `${search}%`;
                params.proxy_type = type;
                params.service_id = service;
            } else {
                params.proxy_ids = ids.join(',');
            }

            const handleExport = async method => {
                await method(withoutEmptyValues(params))
                    .then(response => {
                        const data = response.data.data;
                        const fileName = `Export ${dayjs().format('DD-MM-YYYY (HH-mm)')}.txt`;
                        const blob = new Blob(
                            data.map(row => `${row}\n`),
                            { type: 'text/plain;charset=utf-8' },
                        );
                        FileSaver.saveAs(blob, fileName);
                        setOpen(false);
                        return response;
                    })
                    .then(response => {
                        showMessage(response.data.message, response.status);
                        dispatch(setIDs([]));
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            };

            if (ids.length) {
                await handleExport(ProxyService.list_by_id);
            } else {
                await handleExport(ProxyService.list_by_search);
            }
        } catch (e) {
            return false;
        }
    };

    return (
        <ControledDialog
            title="Export proxy list"
            Submit={
                <Button onClick={handleClick} loading={loading}>
                    Export
                </Button>
            }
            setOpen={setOpen}
            open={open}
            Trigger={<ExportButton />}
            minHeight={284}
        >
            <p className="body2">Setup and export all the proxies</p>
            <div className="proxy-list-radio-group">
                <RadioGroup
                    label="Proxy protocol"
                    items={list_protocol_options}
                    value={list_protocol}
                    onChange={setListProtocol}
                />
                <RadioGroup
                    label="List format"
                    items={list_format_options}
                    value={list_format}
                    onChange={setListFormat}
                />
                <RadioGroup label="Output format" items={export_ext_options} value={ext} onChange={setExt} />
            </div>
            <SimpleSelect
                fullWidth
                withFormLabel
                label="Authentication Option"
                options={exportProxyAuthTypesOptions}
                value={authType}
                onChange={setAuthType}
            />
            {isUserAuthType && <ProxyUserDropdown value={user} onChange={setUser} />}
            <div className="box mt-8">
                <MessageText
                    small
                    noIcon
                    type="good"
                    text={
                        <ul
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 10,
                                listStyle: 'disc',
                                color: 'var(--ping-Grey-grey-500)',
                            }}
                        >
                            <li style={{ listStyle: 'disc' }}>Use Proxy User Authentication To Track Analytics</li>
                            <li style={{ listStyle: 'disc' }}>
                                IP Authentication can be set up on the Proxy User page
                            </li>
                            <li style={{ listStyle: 'disc' }}>
                                Proxy Specific Authentication activity will be attributed to your default Proxy User
                            </li>
                        </ul>
                    }
                />
            </div>
        </ControledDialog>
    );
};

export default ModalExportProxyList;
