import CopyButton from 'components/Buttons/CopyButton';
import ModalExportTerminalInfo from 'components/Modals/ModalExportTerminalInfo';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectResidentialList } from 'store/slices/residentialLedger';

const TerminalProxies = () => {
    const data = useSelector(selectResidentialList);
    const [open, setOpen] = useState(false);
    const disabled = !data.length;

    return (
        <div className="terminal-proxies">
            <div className="terminal-proxies__screen">
                {data?.map((item, index) => (
                    <p key={index}>{item}</p>
                ))}
            </div>
            <div className="terminal-proxies__footer">
                <CopyButton copyValue={data?.join()?.replaceAll(',', '\n')} disabled={disabled} />
                <ModalExportTerminalInfo data={data} setOpen={setOpen} open={open} disabled={disabled} />
            </div>
        </div>
    );
};

export default TerminalProxies;
