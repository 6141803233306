import './styles.scss';

const EmptyPageState = ({ Icon = <></>, title = '', description = '', children = undefined }) => (
    <div className="empty-page-state">
        {!!Icon && Icon}
        <div className="empty-page-state__text">
            {!!title && <h5>{title}</h5>}
            {!!description && <p className="body1">{description}</p>}
        </div>
        {children}
    </div>
);

export default EmptyPageState;
