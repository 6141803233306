import Select from 'components/_common/Select/SimpleSelect';
import { proxyFilters } from 'helpers/constants';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCountries, selectCountriesOptions } from 'store/slices/customer';
import { selectProxyFilterOptions } from 'store/slices/proxy';

const CountryFilter = ({ value, onChange }) => {
    const dispatch = useDispatch();
    const proxyFilterOptions = useSelector(selectProxyFilterOptions);
    const countryOptions = useSelector(selectCountriesOptions);

    useEffect(() => {
        // @ts-ignore
        dispatch(fetchCountries({per_page: 500}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formattedOptions = countryOptions?.filter(option => proxyFilterOptions[proxyFilters.COUNTRY_ID]?.includes(option.value))

    const handleChange = value => onChange(value);

    return <Select label="Countries" options={formattedOptions || []} value={value} onChange={handleChange} />;
};

export default CountryFilter;
