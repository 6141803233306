import './styles.scss';

const Label = ({ color = 'grey', text, onClick = null }) => (
    <div
        className={`label-component label-component--${color} ${onClick ? 'pointer' : ''}`}
        onClick={onClick ? onClick : null}
    >
        {text}
    </div>
);

export default Label;
