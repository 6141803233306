import SimpleSelect from 'components/_common/Select/SimpleSelect';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLanguages, selectLanguagesOptions } from 'store/slices/customer';

const LanguagesDropdown = ({ value, onChange, name = '' }) => {
    const dispatch = useDispatch();
    const options = useSelector(selectLanguagesOptions);

    useEffect(() => {
        // @ts-ignore
        dispatch(fetchLanguages());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SimpleSelect withFormik={!!name} fullWidth withFormLabel label="Language" name={name} value={value} options={options} onChange={onChange} />
    );
};

export default LanguagesDropdown;
