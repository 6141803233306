import TextField from '@mui/material/TextField';
import './styles.scss';
import { FormControl, FormLabel } from '@mui/material';

const TextInput = ({
    label = '',
    value,
    onChange,
    placeholder = '',
    size = 'medium',
    type = 'text',
    maxLength = 255,
    error = false,
    id = undefined,
    name = undefined,
    helperText = undefined,
    className = undefined,
    onBlur = undefined,
    withFormik = false,
    autoComplete = undefined,
    disabled = false,
    min = null,
    max = null,
    endAdornment = null,
}) => {
    const handleChange = event => {
        const v = event.target.value;
        onChange(type === 'number' ? (v == '' ? v : Number(v)) : v);
    };

    return (
        <FormControl className={`custom-text-input ${className}`} fullWidth>
            {label && <FormLabel focused={false}>{label}</FormLabel>}
            <TextField
                // @ts-ignore
                size={size}
                autoComplete={autoComplete}
                id={id}
                name={name}
                type={type}
                placeholder={placeholder || label}
                value={value}
                onChange={withFormik ? onChange : handleChange}
                onBlur={onBlur}
                error={error}
                helperText={helperText}
                inputProps={{
                    maxLength,
                    min,
                    max,
                }}
                InputProps={{
                    endAdornment,
                }}
                disabled={disabled}
            />
        </FormControl>
    );
};
export default TextInput;
