import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import PaymentService from 'api/services/PaymentService';

export const fetchBillingPayments = createAsyncThunk(
    'fetchBillingPayments',
    async params => {
        const response = await PaymentService.search(params);
        return response.data;
    },
    {
        condition: (_, { getState }) => {
            // @ts-ignore
            const { loading } = getState().billingPaymentSettingsState.payments;
            return !loading;
        },
    },
);

const billingPaymentSettings = createSlice({
    name: 'billingPaymentSettings',
    initialState: {
        payments: {
            loading: false,
            data: [],
            error: null,
            total: 0,
        },
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchBillingPayments.pending, state => {
                state.payments.loading = true;
            })
            .addCase(fetchBillingPayments.fulfilled, (state, action) => {
                state.payments.loading = false;
                state.payments.total = action.payload.total_count;
                state.payments.data = action.payload.data;
            })
            .addCase(fetchBillingPayments.rejected, (state, action) => {
                state.payments.loading = false;
                state.payments.error = action.error;
            });
    },
});

export const selectBillingPayments = createSelector(
    [state => state.billingPaymentSettingsState.payments],
    payments => payments.data,
);

export const selectBillingPaymentsTable = createSelector([selectBillingPayments], data =>
    data.map(pm => ({
        id: pm.payment_method_id,
        name: pm.payment_method_billing_name,
        is_default: pm.payment_method_is_default,
        expiry_month: pm.payment_method_expiry_month,
        expiry_year: pm.payment_method_expiry_year,
        card_type: pm.payment_method_network,
        last_four: pm.payment_method_last_four,
    })),
);

export const selectBillingPaymentsLoading = createSelector(
    [state => state.billingPaymentSettingsState.payments],
    payments => payments.loading,
);

export const selectBillingPaymentsTotal = createSelector(
    [state => state.billingPaymentSettingsState.payments],
    payments => payments.total,
);

export default billingPaymentSettings.reducer;
