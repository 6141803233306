import { ReactComponent as Visa } from 'icons/colored/visa.svg';
import { ReactComponent as Mastercard } from 'icons/colored/mastercard.svg';
import { ReactComponent as Coinpayments } from 'images/coinpayments-wallet-seeklogo.svg';
import { ReactComponent as Stripe } from 'images/stripe-logo.svg';
import { ReactComponent as Bitpay } from 'images/bitpay-logo.svg';
import { ReactComponent as UnionPay } from 'icons/colored/unionpay.svg';
import { ReactComponent as Amex } from 'icons/colored/american-express.svg';
import { ReactComponent as Discover } from 'icons/colored/discover.svg';
import { ReactComponent as Diners } from 'icons/colored/DinersClub.svg';
import { ReactComponent as Jcb } from 'icons/colored/JCB.svg';

export const payment_types = {
    visa: 'visa',
    mastercard: 'mastercard',
    unionpay: 'unionpay',
    amex: 'amex',
    discover: 'discover',
    diners: 'diners',
    jcb: 'jcb',
};

export const getPaymensIcon = type => {
    const icons = {
        [payment_types.visa]: <Visa />,
        [payment_types.mastercard]: <Mastercard />,
        [payment_types.unionpay]: <UnionPay />,
        [payment_types.amex]: <Amex />,
        [payment_types.discover]: <Discover />,
        [payment_types.diners]: <Diners />,
        [payment_types.jcb]: <Jcb />,
    };
    return icons[type] || null;
};

export const convertToDollar = (value = 0) =>
    (value / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

export const getPaymensOptions = data =>
    data.map(pm => ({
        value: pm.payment_method_id,
        label: `**** ${pm.payment_method_last_four}`,
        additional: <span className="radio-card-icon">{getPaymensIcon(pm.payment_method_network)}</span>,
    }));

export const payment_platform_types = {
    stripe: 'stripe',
    bitpay: 'bitpay',
    coinpayments: 'coinpayments',
};

export const getPaymentPlatformIcon = type => {
    const icons = {
        [payment_platform_types.stripe]: <Stripe />,
        [payment_platform_types.bitpay]: <Bitpay />,
        [payment_platform_types.coinpayments]: <Coinpayments />,
    };
    return icons[type] || null;
};
