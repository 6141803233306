import React, { useState } from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import Table from 'components/_common/Table';
import ServiceCell from 'components/_common/TableCells/ServiceCell';
import StatusCell from 'components/_common/TableCells/StatusCell';
import TextCell from 'components/_common/TableCells/TextCell';
import ModalCancelSubscription from 'components/Modals/ModalCancelSubscription';
import ModalEditBilling from 'components/Modals/ModalEditBilling';
import { getParams } from 'components/GenerateViewProxies';
import Label from 'components/_common/Label';
import { ReactComponent as Icon2 } from 'icons/credit-card.svg';
import { ReactComponent as Icon3 } from 'icons/ban.svg';
import { ReactComponent as EditIcon } from 'icons/edit.svg';
import ModalReconfigureService from 'components/Modals/ModalReconfigureService';
import PaymentCell from 'components/_common/TableCells/PaymentCell';
import { serviceStatuses } from 'helpers/services';

const OrdersTable = ({ data, dataLoading, fetchData, total, sortModel, noDataMessage, paymentMethods }) => {
    const navigate = useNavigate();
    const [cancelOpen, setCancelOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [serviceData, setServiceData] = useState(null);
    const [reconfigureOpen, setReconfigureOpen] = useState(false);

    const columns = React.useMemo(
        () => [
            {
                field: 'status',
                headerName: 'Status',
                flex: 1,
                minWidth: 150,
                renderCell: ({ value }) => <StatusCell status={value} />,
            },
            {
                field: 'service',
                headerName: 'Service',
                minWidth: 350,
                flex: 1,
                renderCell: ({ value }) => <ServiceCell withFlag data={value} />,
            },
            {
                field: 'period',
                headerName: 'Period',
                flex: 1,
                minWidth: 100,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'payment_method_id',
                headerName: 'Payment Method',
                flex: 1,
                minWidth: 150,
                renderCell: ({ value, row }) =>
                    value ? (
                        <PaymentCell type={row.payment_method_network} num={row.payment_method_last_four} />
                    ) : (
                        <Label text={'Manual Payment'} />
                    ),
            },
            {
                field: 'next_invoice',
                headerName: 'Next Invoice',
                minWidth: 200,
                flex: 1,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'service_total',
                headerName: 'Total',
                flex: 1,
                minWidth: 100,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'actions',
                type: 'actions',
                minWidth: 80,
                getActions: params => {
                    const pullItems = [];
                    if (params.row.status == serviceStatuses.ACTIVE) {
                        pullItems.push(<GridActionsCellItem {...getParams(params, navigate)} />);
                        pullItems.push(
                            <GridActionsCellItem
                                icon={<EditIcon />}
                                showInMenu
                                label="Reconfigure Service"
                                onClick={() => handleReconfigureOpen(params)}
                            />,
                        );
                    }
                    if (
                        params.row.service_subscription_id &&
                        ![serviceStatuses.CANCELED, serviceStatuses.COMPLETE].includes(params.row.status)
                    ) {
                        pullItems.push(
                            <GridActionsCellItem
                                icon={<Icon2 />}
                                showInMenu
                                label="Edit billing"
                                onClick={() => handleEditOpen(params)}
                            />,
                        );
                        pullItems.push(
                            <GridActionsCellItem
                                icon={<Icon3 />}
                                showInMenu
                                className="item-danger"
                                label="Cancel subscription"
                                onClick={() => handleCancelOpen(params)}
                            />,
                        );
                    }
                    return pullItems;
                },
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const handleEditOpen = params => {
        setServiceData(params.row);
        setEditOpen(true);
    };
    const handleCancelOpen = params => {
        setServiceData(params.row);
        setCancelOpen(true);
    };
    const handleReconfigureOpen = params => {
        setServiceData(params.row);
        setReconfigureOpen(true);
    };

    return (
        <>
            <Table
                columns={columns}
                rows={data}
                rowHeight={56}
                dataLoading={dataLoading}
                fetchData={fetchData}
                total={total}
                sortModel={sortModel}
                noDataMessage={noDataMessage}
            />
            <ModalEditBilling setOpen={setEditOpen} open={editOpen} data={serviceData} type="orders" />
            <ModalCancelSubscription setOpen={setCancelOpen} open={cancelOpen} data={serviceData} />
            <ModalReconfigureService setOpen={setReconfigureOpen} open={reconfigureOpen} data={serviceData} />
        </>
    );
};

export default OrdersTable;
