import Button from 'components/_common/Button';

const DownloadButton = ({ link, fullWidth = false }) => {
    const handleClick = () => window.open(link, '_blank');

    return (
        <Button iconName="download" size="large" className="self-start" onClick={handleClick} fullWidth={fullWidth}>
            Download
        </Button>
    );
};

export default DownloadButton;
