import { useState, useEffect } from 'react';
import DropdownLinks from './DropdownLinks';
import SidebarNavLink from './SidebarNavLink';
import { useLocation } from 'react-router-dom';

const MenuDropdown = ({ path, items, to, text }) => {
    const location = useLocation();
    const [opened, setOpened] = useState(location.pathname.includes(path));

    useEffect(() => {
        setOpened(location.pathname.includes(path));
    }, [location.pathname]);

    return (
        <DropdownLinks items={items}>
            <SidebarNavLink to={to} text={text} onClick={() => setOpened(!opened)} className={opened ? 'opened' : ''} />
        </DropdownLinks>
    );
};

export default MenuDropdown;
