import { configureStore } from '@reduxjs/toolkit';
import productsReducer from './slices/products';
import logReducer from './slices/logSlice';
import authReducer from './slices/auth';
import customerReducer from './slices/customer';
import servicesReducer from './slices/services';
import notificationReducer from './slices/notification';
import paymentsReducer from './slices/payments';
import proxyReducer from './slices/proxy';
import replacementReducer from './slices/replacement';
import proxyUsersReducer from './slices/proxyUsers';
import apiKeysReducer from './slices/apiKeys';
import apiRequestsReducer from './slices/apiRequests';
import billingOrdersReducer from './slices/billingOrders';
import billingInvoicesReducer from './slices/billingInvoices';
import billingTransactionsReducer from './slices/billingTransactions';
import billingPaymentSettingsReducer from './slices/billingPaymentSettings';
import residentialLedgerReducer from './slices/residentialLedger';
import reconfigureServiceReducer from './slices/reconfigureService';
import ASNReducer from './slices/ASN';

const store = configureStore({
    reducer: {
        logState: logReducer,
        authState: authReducer,
        proxyState: proxyReducer,
        replacementState: replacementReducer,
        apiKeysState: apiKeysReducer,
        productsState: productsReducer,
        customerState: customerReducer,
        servicesState: servicesReducer,
        paymentsState: paymentsReducer,
        proxyUsersState: proxyUsersReducer,
        apiRequestsState: apiRequestsReducer,
        notificationState: notificationReducer,
        residentialLedgerState: residentialLedgerReducer,
        billingOrdersState: billingOrdersReducer,
        billingInvoicesState: billingInvoicesReducer,
        billingTransactionsState: billingTransactionsReducer,
        billingPaymentSettingsState: billingPaymentSettingsReducer,
        reconfigureServiceState: reconfigureServiceReducer,
        ASNState: ASNReducer
    },
});

export default store;
