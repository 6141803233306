import Button from 'components/_common/Button';
import ControledDialog from 'components/_common/Dialog/ControledDialog';
import OTPInput from 'components/_common/OTPInput';

const Modal2FA = ({ setOpen, open, onSubmit, otp, setOtp, loading, title, text }) => {
    const Submit = () => (
        <Button disabled={otp.length < 8} loading={loading} onClick={onSubmit}>
            Confirm
        </Button>
    );

    return (
        <ControledDialog title={title} Submit={<Submit />} setOpen={setOpen} open={open} withClose={false}>
            {text}
            <OTPInput value={otp} onChange={setOtp} label="8-digit code" />
        </ControledDialog>
    );
};

export default Modal2FA;
