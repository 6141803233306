import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import LogChart from 'components/LogChart';
import Chart from 'components/_common/Chart';
import Filters from './Filters';
import { fetchResidentialSummary, selectMetrics, selectUsersID } from 'store/slices/services';
import { fetchAnalytict, selectAnalytics, selectAnalyticsLoading } from 'store/slices/logSlice';
import { convertDate, calculateInterval, metricTypes } from 'helpers';

const DashboardBandwidth = () => {
    const dispatch = useDispatch();
    const metrics = useSelector(selectMetrics);
    const data = useSelector(selectAnalytics);
    const loading = useSelector(selectAnalyticsLoading);
    const usersId = useSelector(selectUsersID);

    const initialStart = useRef(dayjs().subtract(7, 'days').startOf('day'));
    const initialEnd = useRef(dayjs().endOf('day'));

    const [network, setNetwork] = useState('residential');
    const [metricFilter, setMetricFilter] = useState(metricTypes.megabytes);
    const [users, setUsers] = useState([]);
    const [userColors, setUserColors] = useState({});
    const [hasError, setError] = useState('');
    const [limits, setLimits] = useState({ minStartDateTime: null, maxEndDateTime: null });
    const [range, setRange] = useState({ start: null, end: null });

    const { minStartDateTime, maxEndDateTime } = limits;
    const { start, end } = range;

    const prevDepsRef = useRef({ start: null, end: null, users: [], hasError: null, network });

    const colorOptions = [
        'rgba(161, 146, 236, 1)',
        'rgba(40, 54, 128, 1)',
        'rgba(217, 147, 247, 1)',
        'rgba(138, 189, 249, 1)',
        'rgba(0, 182, 122, 1)',
        'rgba(255, 107, 107, 1)',
        'rgba(254, 202, 87, 1)',
        'rgba(84, 160, 255, 1)',
        'rgba(255, 159, 243, 1)',
        'rgba(95, 39, 205, 1)',
        'rgba(29, 209, 161, 1)',
        'rgba(255, 159, 243, 1)',
        'rgba(84, 160, 255, 1)',
        'rgba(255, 107, 107, 1)',
        'rgba(0, 182, 122, 1)',
    ];

    const getRandomColor = () => {
        const hue = Math.floor(Math.random() * 360);
        const saturation = Math.floor(Math.random() * 30) + 70;
        const lightness = Math.floor(Math.random() * 20) + 40;
        return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    };

    const getColorForUser = useCallback(
        userId => {
            if (userColors[userId]) {
                return userColors[userId];
            }

            let newColor;
            if (Object.keys(userColors).length < colorOptions.length - 1) {
                const availableColors = colorOptions
                    .slice(1)
                    .filter(color => !Object.values(userColors).includes(color));
                newColor = availableColors[0];
            } else {
                newColor = getRandomColor();
            }

            setUserColors(prev => ({ ...prev, [userId]: newColor }));
            return newColor;
        },
        [userColors],
    );

    const areUsersEqual = (prevUsers, currentUsers) => {
        if (prevUsers.length !== currentUsers.length) return false;
        return prevUsers.every((user, index) => user === currentUsers[index]);
    };

    const fetchData = () => {
        const min_log_request_datetime = convertDate(start);
        const max_log_request_datetime = convertDate(end);
        const interval = calculateInterval(min_log_request_datetime, max_log_request_datetime);

        const cond =
            dayjs(min_log_request_datetime).isValid() &&
            dayjs(max_log_request_datetime).isValid() &&
            dayjs(min_log_request_datetime).isBefore(max_log_request_datetime) &&
            dayjs(max_log_request_datetime).subtract(1, 'hour').isAfter(min_log_request_datetime) &&
            !hasError;

        const prevDeps = prevDepsRef.current;
        const startChanged = !dayjs(start).isSame(prevDeps.start);
        const endChanged = !dayjs(end).isSame(prevDeps.end);
        const usersChanged = !areUsersEqual(prevDeps.users, users);
        const errorChanged = hasError !== prevDeps.hasError;
        const networkChanged = network !== prevDeps.network;
        const log_network = network;

        const depsChanged = startChanged || endChanged || usersChanged || errorChanged || networkChanged;

        if (cond && depsChanged) {
            const baseParams = {
                min_log_request_datetime,
                max_log_request_datetime,
                interval,
            };

            if (log_network) {
                baseParams.log_network = log_network;
            }

            const searchParams =
                users.length === 0
                    ? [baseParams]
                    : users.map(userId => ({
                          ...baseParams,
                          proxy_user_id: userId,
                      }));

            dispatch(fetchAnalytict(searchParams));

            prevDepsRef.current = { start, end, users: [...users], hasError, network };
        }
    };

    useEffect(() => {
        fetchData();
    }, [start, end, users, hasError, network]);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setRange({ start: initialStart.current, end: initialEnd.current });
        setLimits({ minStartDateTime: initialStart.current, maxEndDateTime: initialEnd.current });
        dispatch(fetchResidentialSummary());
    }, [initialStart?.current, initialEnd?.current]);

    const chartData = useMemo(() => {
        if (!data || !Array.isArray(data)) {
            return [];
        }

        const processData = intervals => {
            return intervals.map(interval => {
                switch (metricFilter) {
                    case metricTypes.bytes:
                        return interval.bytes;
                    case metricTypes.megabytes:
                        return interval.bytes / Math.pow(1024, 2);
                    case metricTypes.gigabytes:
                        return interval.bytes / Math.pow(1024, 3);
                    case metricTypes.requests:
                        return interval.requests;
                    case metricTypes.errorRate:
                        return interval.requests ? (1 - interval.successful / interval.requests) * 100 : 0;
                    default:
                        return interval.bytes;
                }
            });
        };

        const createChartData = (d, label) => {
            const color = label === 'All Users' ? colorOptions[0] : getColorForUser(label);

            return {
                data: processData(d.intervals),
                label: label,
                area: true,
                color: color,
            };
        };

        const chartDataAllUsers = data.filter(d => d.proxy_user_id === null).map(d => createChartData(d, 'All Users'));

        const chartDataFiltered = users.flatMap(id => {
            const userData = data.find(d => d.proxy_user_id === id);
            return userData ? [createChartData(userData, id)] : [];
        });
        return users.length ? [...chartDataAllUsers, ...chartDataFiltered] : chartDataAllUsers;
    }, [data, users, getColorForUser, colorOptions, metricFilter]);

    const yAxisMax = useMemo(() => {
        switch (metricFilter) {
            case metricTypes.errorRate:
                return 100;
            default:
                return false;
        }
    }, [metricFilter]);

    const yAxisValueFormatter = useMemo(() => {
        switch (metricFilter) {
            case metricTypes.errorRate:
                return value => `${value.toLocaleString()} %`;
            default:
                return false;
        }
    }, [metricFilter]);

    const seriesValueFormatter = useMemo(() => {
        switch (metricFilter) {
            case metricTypes.bytes:
                return value => `${value.toLocaleString()} Bytes`;
            case metricTypes.errorRate:
                return value => `${value.toLocaleString()} % Error Rate`;
            case metricTypes.gigabytes:
                return value => `${value.toLocaleString()} GB`;
            case metricTypes.megabytes:
                return value => `${value.toLocaleString()} MB`;
            case metricTypes.requests:
                return value => `${value.toLocaleString()} Requests`;
            default:
                return value => value;
        }
    }, [metricFilter]);

    const handleSetUsers = useCallback(
        newUsers => {
            if (!areUsersEqual(users, newUsers)) {
                setUsers(newUsers);
            }
        },
        [users],
    );

    const handleSetRange = useCallback(values => {
        const [start, end] = values;
        setRange({ start, end });
    }, []);

    const handleSetError = useCallback(error => {
        setError(error);
    }, []);

    const labels = useMemo(() => {
        if (!data || !Array.isArray(data) || data.length === 0) {
            return [];
        }

        const firstDataItem = data[0];
        if (!firstDataItem || !Array.isArray(firstDataItem.intervals)) {
            return [];
        }
        // transform data from backend (UTC) to user local time
        const min_log_request_datetime = convertDate(start);
        const max_log_request_datetime = convertDate(end);
        const interval = calculateInterval(min_log_request_datetime, max_log_request_datetime);

        const formatStorage = {
            '1 minute': 'HH:mm',
            '10 minute': 'HH:mm',
            '1 hour': 'HH:mm',
            '1 day': 'MM-DD',
        };

        return firstDataItem.intervals.map(item =>
            dayjs
                .utc(item.interval)
                .local()
                .format(formatStorage[interval] || 'YYYY-MM-DD HH:mm'),
        );
    }, [data]);

    return (
        <>
            <LogChart
                title="Proxy Analytics"
                filters={
                    <Filters
                        users={users}
                        setUsers={handleSetUsers}
                        network={network}
                        setNetwork={setNetwork}
                        metricFilter={metricFilter}
                        setMetricFilter={setMetricFilter}
                        range={range}
                        setRange={handleSetRange}
                        maxEndDateTime={maxEndDateTime}
                        onError={handleSetError}
                    />
                }
                metricsData={metrics}
                network={network}
                chart={
                    <Chart
                        loading={loading}
                        xLabels={labels}
                        data={chartData}
                        yAxisConfig={{ yAxisMax, yAxisValueFormatter }}
                        seriesConfig={{ valueFormatter: seriesValueFormatter }}
                    />
                }
            />
        </>
    );
};

export default DashboardBandwidth;
