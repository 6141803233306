import Button from 'components/_common/Button';
import './styles.scss';
import MessageText from 'components/MessageText';

const MessageCard = ({
    title = '',
    desc = '',
    buttonText,
    customStyles = {},
    customButtonStyles = {},
    type,
    message,
    to = undefined,
    onClick = undefined,
    isLoading = false,
}) => (
    <div className="message-card box" style={customStyles}>
        <MessageText type={type} text={message} />
        <h4>{title}</h4>
        <p className="body2">{desc}</p>
        <Button customStyles={customButtonStyles} disabled={isLoading} loading={isLoading} size="large" onClick={onClick} to={to}>
            {buttonText}
        </Button>
    </div>
);

export default MessageCard;
