import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import ProxyService from 'api/services/ProxyService';
import ServicesService from 'api/services/ServicesService';
import Label from 'components/_common/Label';

export const fetchReconfigureOptions = createAsyncThunk('fetchReconfigureOptions', async id => {
    const response = await ServicesService.reconfigureOption(id);
    return response.data;
});

export const fetchProxiesForDropdown = createAsyncThunk('fetchProxiesForDropdown', async service_id => {
    const params = { service_id };
    const response = await ProxyService.search(params);
    return response.data;
});

const ReconfigureServiceSlice = createSlice({
    name: 'ReconfigureServiceSlice',
    initialState: {
        reconfigureOptions: {
            loading: false,
            data: [],
            error: null,
        },
        proxies: {
            loading: false,
            data: [],
        },
        params: {
            data: {},
        },
    },
    reducers: {
        setReconfigureParams: (state, action) => {
            state.params.data = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchReconfigureOptions.pending, state => {
                state.reconfigureOptions.loading = true;
                state.reconfigureOptions.error = null;
            })
            .addCase(fetchReconfigureOptions.fulfilled, (state, action) => {
                state.reconfigureOptions.loading = false;
                state.reconfigureOptions.data = action.payload.reconfigure_option;
            })
            .addCase(fetchReconfigureOptions.rejected, state => {
                state.reconfigureOptions.error = true;
            });
        builder
            .addCase(fetchProxiesForDropdown.pending, state => {
                state.proxies.loading = true;
            })
            .addCase(fetchProxiesForDropdown.fulfilled, (state, action) => {
                state.proxies.loading = false;
                state.proxies.data = action.payload.data;
            });
    },
});

export const selectReconfigureOptions = createSelector(
    [state => state.reconfigureServiceState.reconfigureOptions],
    reconfigureOptions => reconfigureOptions.data,
);

export const selectReconfigureOptionsLoading = createSelector(
    [state => state.reconfigureServiceState.reconfigureOptions],
    reconfigureOptions => reconfigureOptions.loading,
);

export const selectReconfigureOptionsError = createSelector(
    [state => state.reconfigureServiceState.reconfigureOptions],
    reconfigureOptions => reconfigureOptions.error,
);

export const selectImmediateData = createSelector([selectReconfigureOptions], data =>
    data.find(item => !item.end_of_period),
);

export const selectEndOfPeriodData = createSelector([selectReconfigureOptions], data =>
    data.find(item => item.end_of_period),
);

export const selectReconfigureParams = createSelector(
    [state => state.reconfigureServiceState.params],
    params => params.data,
);

export const selectProxiesForDropdown = createSelector([state => state.reconfigureServiceState.proxies], proxies =>
    proxies.data.map(item => ({
        value: item.proxy_id,
        label: item.ip_address_id_v4,
        Label: <Label text={`Proxy ID: ${item.proxy_id}`} color="grey" />,
    })),
);

export const selectProxiesForDropdownLoading = createSelector(
    [state => state.reconfigureServiceState.proxies],
    proxies => proxies.loading,
);

export const { setReconfigureParams } = ReconfigureServiceSlice.actions;

export default ReconfigureServiceSlice.reducer;
