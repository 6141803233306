import PaymentService from 'api/services/PaymentService';
import DeleteButton from 'components/Buttons/DeleteButton';
import showMessage from 'components/NoticeProvider/showMessage';
import ControledDialog from 'components/_common/Dialog/ControledDialog';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchBillingPayments } from 'store/slices/billingPaymentSettings';

const ModalDeletePaymentCard = ({ setOpen, open, data }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const handleDelete = async () => {
        try {
            setLoading(true);
            await PaymentService.delete(data?.id)
                .then(response => {
                    showMessage(response.data.message, response.status);
                    setLoading(false);
                })
                .then(() => {
                    // @ts-ignore
                    dispatch(fetchBillingPayments({ payment_method_status: 'attached' }));
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            return false;
        }
    };

    return (
        <ControledDialog
            title="Delete payment card"
            Submit={<DeleteButton loading={loading} onClick={handleDelete} />}
            setOpen={setOpen}
            open={open}
        >
            Are you sure you want to delete **** {data?.last_four} card? This action cannot be undone.
        </ControledDialog>
    );
};

export default ModalDeletePaymentCard;
