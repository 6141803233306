import * as React from 'react';
import './styles.scss';
import DialogBody from './DialogBody';

const Dialog = ({ children, title, Trigger, Submit }) => {
    const [open, setOpen] = React.useState(false);

    return (
        <DialogBody Trigger={Trigger} Submit={Submit} setOpen={setOpen} open={open} title={title}>
            {children}
        </DialogBody>
    );
};

export default Dialog;
