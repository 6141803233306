import EmptyPageState from 'components/EmptyPageState';
import { ReactComponent as Icon } from 'icons/colored/empty_icon_keys.svg';
import ModalCreateApiKey from 'components/Modals/ModalCreateApiKey/ModalCreateApiKey';

const NoData = ({ loadingModal, handleCreate }) => (
    <EmptyPageState
        Icon={<Icon />}
        title="You don't have any API key yet"
        description="Unlock possibilities by generating your first API key"
    >
        <ModalCreateApiKey loading={loadingModal} onCreate={handleCreate} />
    </EmptyPageState>
);

export default NoData;
