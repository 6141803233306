import { ReactComponent as Icon } from 'icons/bell.svg';
import * as React from 'react';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import './styles.scss';
import NotificationsContent from './NotificationsContent';
import { useSelector } from 'react-redux';
import Badge from 'components/_common/Badge';
import { selectCounter } from 'store/slices/notification';

const Notifications = () => {
    const count = useSelector(selectCounter);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className="notifications">
            <Button
                aria-describedby={id}
                variant="text"
                onClick={handleClick}
                style={{ width: '36px', height: '36px', justifyContent: 'center' }}
            >
                <Badge value={count}>
                    <Icon />
                </Badge>
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <NotificationsContent />
            </Popover>
        </div>
    );
};
export default Notifications;
