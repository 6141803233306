import './styles.scss';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FormControl, FormLabel } from '@mui/material';
import { ReactComponent as Chevron } from 'icons/chevron-down.svg';
import { useState } from 'react';
import { countries } from './constants';
import Flag from './Items/Flag';

export const getValueByCode = code => countries.find(item => item.code === code);

const BillingCountryDropdown = ({ value, onChange, disabled = false }) => {
    const handleChange = (event, newValue) => onChange(newValue);
    const [inputValue, setInputValue] = useState('');

    const code = value?.code;

    return (
        <div className="custom-coutry-dropdown billing-country-dropwdown">
            <FormControl fullWidth>
                <FormLabel focused={false}>Billing country</FormLabel>
                <Autocomplete
                    disabled={disabled}
                    value={value}
                    onChange={handleChange}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    options={countries}
                    autoHighlight
                    popupIcon={<Chevron />}
                    isOptionEqualToValue={(option, value) => option.code === value.code}
                    getOptionLabel={option => option.label}
                    renderOption={(props, option) => (
                        <Box
                            component="li"
                            sx={{
                                '& > img': { mr: 2, flexShrink: 0, width: 24 },
                                fontWeight: 500,
                            }}
                            {...props}
                        >
                            <Flag country_id={option.code} />
                            <span style={{ marginRight: 'auto' }}>{option.label}</span>
                        </Box>
                    )}
                    renderInput={params => (
                        <div className={`custom-coutry-dropdown-container ${code ? 'has-value' : ''}`}>
                            {code && <Flag country_id={code} />}
                            <TextField
                                {...params}
                                placeholder="Choose country"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                            />
                        </div>
                    )}
                />
            </FormControl>
        </div>
    );
};

export default BillingCountryDropdown;
