import RadioGroupCards from 'components/_common/RadioGroupCards';
import { getPaymentPlatformIcon } from 'helpers/payments';
import { capitalize } from 'lodash';
import { useEffect } from 'react';
import { VISIBLE_PAYMENT_PLATFORMS } from 'helpers/constants';

const AllowedPaymentPlatforms = ({
    allowed_payment_platforms,
    credit_only_checkout,
    value,
    onChange,
    disabled = false,
}) => {
    const options = credit_only_checkout
        ? [
              {
                  value: 'credit',
                  label: 'Store Credit',
              },
              {
                  value: 'stripe',
                  label: 'Stripe',
                  additional: <span className="radio-card-icon">{getPaymentPlatformIcon('stripe')}</span>,
              },
          ]
        : allowed_payment_platforms
              .filter(i => VISIBLE_PAYMENT_PLATFORMS.includes(i))
              .map(name => ({
                  value: name,
                  label: capitalize(name),
                  additional: <span className="radio-card-icon">{getPaymentPlatformIcon(name)}</span>,
              }));

    useEffect(() => {
        if (allowed_payment_platforms) {
            onChange(credit_only_checkout ? 'credit' : allowed_payment_platforms[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <RadioGroupCards
            disabled={disabled}
            label="Select payment method"
            items={options}
            value={value}
            onChange={onChange}
        />
    );
};

export default AllowedPaymentPlatforms;
