import Sidebar from 'Layout/Sidebar';
import ROUTES from 'helpers/routes';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { customerRetrieve, selectIsUser } from 'store/slices/customer';
import { notificationSearch } from 'store/slices/notification';
import { fetchProducts } from 'store/slices/products';
import { getAuthToken } from 'api/helpers';

const MainLayout = () => {
    const isUser = useSelector(selectIsUser);
    const dispatch = useDispatch();
    const hasToken = getAuthToken();
    const { search } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();


    useEffect(() => {
        if (hasToken) {
            // @ts-ignore
            dispatch(customerRetrieve());
            // @ts-ignore
            dispatch(notificationSearch());
            // @ts-ignore
            dispatch(fetchProducts());
            if(searchParams.has('toltId')) {
                searchParams.delete('toltId');
                setSearchParams(searchParams)
            }
            if(searchParams.has('access_token')) {
                searchParams.delete('access_token');
                setSearchParams(searchParams)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    return hasToken ? (
        <section className="main-layout">
            <Sidebar />
            <main>{isUser && <Outlet />}</main>
        </section>
    ) : (
        <Navigate to={search ? `${ROUTES.login}${search}` : ROUTES.login} />
    );
};

export default MainLayout;
