const ROUTES = {
    base: '/',
    login: '/login',
    signUp: '/sign-up',
    recovery: '/password-recovery',
    dashboard: '/dashboard',
    summary: '/summary',
    residential: '/residential',
    proxyList: '/static-proxy/list',
    replacements: '/static-proxy/replacements',
    proxyUsers: '/proxy-users',
    products: '/products',
    apiKeys: '/api-keys',
    apiRequests: '/api-requests',
    billingSubscriptions: '/billing/subscriptions',
    billingInvoices: '/billing/invoices',
    billingTransactions: '/billing/transactions',
    billingPaymentSettings: '/billing/payment-settings',
    billingAddress: '/billing/address',
    accountSettings: '/account-settings',
    uiKit: '/ui-kit',
    paymentSuccess: '/payment-successful',
    404: '/404',
};

export default ROUTES;
