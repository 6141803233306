import { ReactComponent as SettingsIcon } from 'icons/settings.svg';
import { ReactComponent as LogOutIcon } from 'icons/logout.svg';
import ROUTES from 'helpers/routes';
import { Button, Divider } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearUser } from 'store/slices/customer';
import AuthService from 'api/services/AuthService';
import showMessage from 'components/NoticeProvider/showMessage';

const className = 'user-actions-item';
const Hr = () => (
    <Divider
        style={{
            backgroundColor: 'var(--ping-Grey-grey-200)',
            margin: '6px 0',
            borderColor: 'transparent',
        }}
    />
);

const UserMenu = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await AuthService.logout()
                .then(response => {
                    showMessage(response.data.message, response.status);
                })
                .then(() => {
                    navigate(ROUTES.login);
                    dispatch(clearUser());
                    window.location.reload();
                });
        } catch (e) {
            return false;
        }
    };
    return (
        <>
            <Button
                fullWidth
                component={NavLink}
                className={className}
                startIcon={<SettingsIcon />}
                to={ROUTES.accountSettings}
            >
                Account Settings
            </Button>
            <Hr />
            <Button fullWidth startIcon={<LogOutIcon />} className={className} onClick={handleLogout}>
                Log out
            </Button>
        </>
    );
};

export default UserMenu;
