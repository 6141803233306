import $api from 'api/http';
import urls from 'api/urls-config.json';

export default class CheckoutService {
    static async calculate(params) {
        return $api.post(urls.cart.calculate, params);
    }
    static async create(params) {
        return $api.post(urls.checkout.create, params);
    }
}
