import Select from 'components/_common/Select/SimpleSelect';
import { proxyFilters } from 'helpers/constants';
import { useSelector } from 'react-redux';
import { selectProxyFilterOptions } from 'store/slices/proxy';

const ServicesFilter = ({ value, onChange }) => {
    const options = useSelector(selectProxyFilterOptions);
    const formattedOptions = options[proxyFilters.SERVICE_ID]?.map(option => {
        return { value: option, label: option };
    });

    const handleChange = value => onChange(value);

    return <Select label="Services" options={formattedOptions || []} value={value} onChange={handleChange} />;
};

export default ServicesFilter;
