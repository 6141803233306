import Button from 'components/_common/Button';
import AccountSettingsCard from './AccountSettingsCard';
import { useState } from 'react';
import showMessage from 'components/NoticeProvider/showMessage';
import AuthService from 'api/services/AuthService';
import { useNavigate } from 'react-router-dom';
import ROUTES from 'helpers/routes';
import { useDispatch } from 'react-redux';
import { clearUser } from 'store/slices/customer';

const WebSessions = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSingOutAll = async () => {
        setLoading(true);
        await AuthService.logoutAll()
            .then(response => {
                setLoading(false);
                showMessage(response.data.message, response.status);
                if (response.status === 200) {
                    navigate(ROUTES.login);
                    dispatch(clearUser());
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <AccountSettingsCard
            title="Web sessions"
            desc="Stay in control of your account's active sessions, ensuring a secure and personalized online experience."
        >
            <Button variant="outlined" loading={loading} onClick={handleSingOutAll}>
                Sign out all other sessions
            </Button>
        </AccountSettingsCard>
    );
};

export default WebSessions;
