import Autocomplete from 'components/_common/Autocomplete';
import { withoutEmptyValues } from 'helpers';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchASN, selectASNOptions, fetchASNDropdown } from 'store/slices/ASN';

const IspAsnDropdown = ({ value, onChange, countryCode = '' }) => {
    const dispatch = useDispatch();
    const options = useSelector(selectASNOptions);

    const params = withoutEmptyValues({
        min_asn_ip_address_count: 100000,
        asn_type: 'isp',
        country_id: countryCode,
    });

    useEffect(() => {
        // @ts-ignore
        dispatch(fetchASN(params));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryCode]);

    return (
        <Autocomplete
            fullWidth
            withFormLabel
            label="ISP / ASN"
            value={value}
            options={options}
            onChange={onChange}
            fetchData={query => fetchASNDropdown({ ...params, like_asn_name: `%${query}%` })}
        />
    );
};

export default IspAsnDropdown;
