import TooltipComponent from 'components/_common/Tooltip';

const StatisticItem = ({ title, tooltip, value, icon, action }) => (
    <div className="statistic-bar-item" key={title}>
        <div className="statistic-bar-item__content">
            <div className="statistic-bar-item__title">
                <span className="overline-text">{title}</span>
                <TooltipComponent text={tooltip} />
            </div>
            <div className="statistic-bar-item__value display-large">{value}</div>
        </div>
        <div className="statistic-bar-item__left-wrapper">
            <div className="statistic-bar-item__icon">{icon}</div>
            <div>{action}</div>
        </div>
    </div>
);

export default StatisticItem;
