import Button from 'components/_common/Button';

const Burger = ({ onClick }) => (
    <Button onClick={onClick} variant="text" className="burger-button">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M4 6H20M4 12H20M4 18H20"
                stroke="#384250"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    </Button>
);

export default Burger;
