import FormTemplate from 'components/FormTemplate';
import AuthLink from 'components/FormTemplate/Items/AuthLink';
import AuthText from 'components/FormTemplate/Items/AuthText';
import SubmitButton from 'components/FormTemplate/Items/SubmitButton';
import TextInput from 'components/_common/TextInput';
import ROUTES from 'helpers/routes';
import AuthService from 'api/services/AuthService';
import showMessage from 'components/NoticeProvider/showMessage';
import TwoFactorForm from './TwoFactorForm';
import { resetPasswordValidationSchema } from 'helpers/validations';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { setAuth } from 'store/slices/auth';
import { useDispatch } from 'react-redux';
import { setAuthToken } from 'api/helpers';

const initialValues = { customer_email_address: '', customer_password: '', repeat_password: '' };

const PasswordRecoveryForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [state, setState] = useState(1);
    const [otp, setOtp] = useState('');
    const [fields, setFields] = useState(null);
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues,
        validationSchema: resetPasswordValidationSchema,
        onSubmit: async params => {
            try {
                setLoading(true);
                const { customer_email_address, customer_password } = params;
                const newParams = { customer_email_address, customer_password };
                await AuthService.passwordReset(newParams)
                    .catch(({ response }) => {
                        const { two_factor_authentication_public_key } = response.data.data;
                        setLoading(false);
                        if (response.status === 499) {
                            setFields({ ...newParams, two_factor_authentication_public_key });
                            setState(2);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } catch (e) {
                return false;
            }
        },
    });

    const handleFinalSubmit = async () => {
        const params = { ...fields, two_factor_authentication_private_key: otp };
        setLoading(true);
        try {
            await AuthService.passwordReset(params)
                .then(response => {
                    localStorage.removeItem('shownInvoicesIds');
                    setLoading(false);
                    setAuthToken(response.data.data.access_token);
                    dispatch(setAuth(true));
                    navigate(ROUTES.dashboard);
                    showMessage(response.data.message, 'success');
                })
                .catch(() => {
                    setOtp('');
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            return false;
        }
    };

    if (state === 1) {
        return (
            <form onSubmit={formik.handleSubmit}>
                <FormTemplate title="Reset password" desc="Enter your email address and new password below">
                    <TextInput
                        withFormik
                        name="customer_email_address"
                        label="Account Email"
                        placeholder="Enter your account email"
                        value={formik.values.customer_email_address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.customer_email_address && Boolean(formik.errors.customer_email_address)}
                        helperText={formik.touched.customer_email_address && formik.errors.customer_email_address}
                    />
                    <TextInput
                        withFormik
                        name="customer_password"
                        label="New password"
                        type="password"
                        autoComplete="new-password"
                        value={formik.values.customer_password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.customer_password && Boolean(formik.errors.customer_password)}
                        helperText={formik.touched.customer_password && formik.errors.customer_password}
                    />
                    <TextInput
                        withFormik
                        name="repeat_password"
                        label="Repeat new password"
                        type="password"
                        autoComplete="new-password"
                        value={formik.values.repeat_password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.repeat_password && Boolean(formik.errors.repeat_password)}
                        helperText={formik.touched.repeat_password && formik.errors.repeat_password}
                    />
                    <SubmitButton loading={loading}>Submit</SubmitButton>
                    <AuthText>
                        Know your password? <AuthLink to={ROUTES.login}>Log in</AuthLink>
                    </AuthText>
                </FormTemplate>
            </form>
        );
    }
    if (state === 2) {
        return (
            <TwoFactorForm otp={otp} setOtp={setOtp} loading={loading} onSubmit={handleFinalSubmit}>
                <AuthLink to={ROUTES.login}>Return to login</AuthLink>
            </TwoFactorForm>
        );
    }
};

export default PasswordRecoveryForm;
