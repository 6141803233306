import AuthService from 'api/services/AuthService';
import FormTemplate from 'components/FormTemplate';
import AuthDivider from 'components/FormTemplate/Items/AuthDivider';
import AuthLink from 'components/FormTemplate/Items/AuthLink';
import AuthText from 'components/FormTemplate/Items/AuthText';
import GoggleButton from 'components/FormTemplate/Items/GoggleButton';
import SubmitButton from 'components/FormTemplate/Items/SubmitButton';
import TextInput from 'components/_common/TextInput';
import ROUTES from 'helpers/routes';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { setAuth } from 'store/slices/auth';
import { useFormik } from 'formik';
import { registrationValidationSchema } from 'helpers/validations';
import OTPInput from 'components/_common/OTPInput';
import { extractToltReferralUUID, setAuthToken } from 'api/helpers';
import { PRIVACY_POLICY_LINK, TERMS_OF_USE_LINK } from 'helpers/constants';

const initialValues = {
    customer_email_address: '',
    customer_password: '',
    customer_first_name: '',
    customer_last_name: '',
};

const SignUpForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {search} = useLocation();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [showSecondStep, setShowSecondStep] = useState(false);
    const [otp, setOtp] = useState('');
    const [fields, setFields] = useState(null);

    const queryToltId = searchParams.get('toltId')
    const access_token = searchParams.get('access_token');
    const productType = searchParams.get('product-type');

    const auth = token => {
        localStorage.removeItem('shownInvoicesIds');
        setAuthToken(token);
        dispatch(setAuth(true));
        navigate(productType ? `${ROUTES.products}${search}` : ROUTES.dashboard);
    };

    useEffect(() => {
        if (access_token) {
            auth(access_token);
        }
    }, [access_token]);

    const formik = useFormik({
        initialValues,
        validationSchema: registrationValidationSchema,
        onSubmit: async params => {
            try {
                setLoading(true);
                await AuthService.registration(params)
                    .catch(({ response }) => {
                        const { two_factor_authentication_public_key } = response.data.data;
                        setLoading(false);
                        if (response.status === 499) {
                            setFields({ ...params, two_factor_authentication_public_key });
                            setShowSecondStep(true);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } catch (e) {
                return false;
            }
        },
    });

    const handleFinalSubmit = async () => {
        const params = { ...fields, two_factor_authentication_private_key: otp };
        const toltReferralUUID = extractToltReferralUUID();
        if (toltReferralUUID) {
            params.customer_tolt_referral_id = toltReferralUUID;
        }
        if(queryToltId) {
            params.customer_tolt_referral_id = queryToltId;
        }
        setLoading(true);
        try {
            await AuthService.registration(params)
                .then(response => {
                    auth(response.data.data.access_token)
                })
                .catch(() => {
                    setOtp('');
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            return false;
        }
    };

    return !showSecondStep ? (
        <form onSubmit={formik.handleSubmit}>
            <FormTemplate title="Create Ping account" desc="Start your journey with the power of proxies">
                <TextInput
                    withFormik
                    name="customer_email_address"
                    label="Email"
                    value={formik.values.customer_email_address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.customer_email_address && Boolean(formik.errors.customer_email_address)}
                    helperText={formik.touched.customer_email_address && formik.errors.customer_email_address}
                />
                <TextInput
                    withFormik
                    name="customer_first_name"
                    label="First name"
                    value={formik.values.customer_first_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.customer_first_name && Boolean(formik.errors.customer_first_name)}
                    helperText={formik.touched.customer_first_name && formik.errors.customer_first_name}
                />
                <TextInput
                    withFormik
                    name="customer_last_name"
                    label="Last name"
                    value={formik.values.customer_last_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.customer_last_name && Boolean(formik.errors.customer_last_name)}
                    helperText={formik.touched.customer_last_name && formik.errors.customer_last_name}
                />
                <TextInput
                    withFormik
                    name="customer_password"
                    label="Password"
                    type="password"
                    value={formik.values.customer_password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.customer_password && Boolean(formik.errors.customer_password)}
                    helperText={formik.touched.customer_password && formik.errors.customer_password}
                />
                <SubmitButton loading={loading}>Continue with Email</SubmitButton>
                <AuthDivider />
                {process.env.REACT_APP_ENABLE_GOOGLE_LOGIN_SIGNUP_ACCOUNT === 'TRUE' && <GoggleButton to={search && `${ROUTES.products}${search}`} queryToltId={queryToltId}/>}
                <AuthText>
                    By signing up you agree to the{' '}
                    <AuthLink blank to={TERMS_OF_USE_LINK}>
                        Terms of Use
                    </AuthLink>{' '}
                    and{' '}
                    <AuthLink blank to={PRIVACY_POLICY_LINK}>
                        Privacy Policy
                    </AuthLink>
                </AuthText>
                <AuthText>
                    Already have an account? <AuthLink to={`${ROUTES.login}${search}`}>Log in</AuthLink>
                </AuthText>
            </FormTemplate>
        </form>
    ) : (
        <FormTemplate
            title="Two-factor authentication"
            desc="Enter the confirmation 8-digit code send to your contact method of choice."
        >
            <OTPInput value={otp} onChange={setOtp} label="8-digit code" sizeBig disabled={loading} />
            <SubmitButton loading={loading} onClick={handleFinalSubmit} disabled={otp.length < 8}>
                Submit code
            </SubmitButton>
            <AuthText>
                <AuthLink to={`${ROUTES.login}${search}`}>Return to login</AuthLink>
            </AuthText>
        </FormTemplate>
    );
};

export default SignUpForm;
