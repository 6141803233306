export const tableStyles = {
    borderColor: 'var(--ping-Grey-grey-200)',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    color: 'inherit',
    letterSpacing: 0,
    '& .MuiDataGrid-columnHeaderCheckbox.MuiDataGrid-columnHeader, & .MuiDataGrid-cellCheckbox.MuiDataGrid-cell': {
        padding: '0',
    },
    '& .MuiDataGrid-columnSeparator--sideRight': {
        display: 'none',
    },
    '& .MuiDataGrid-columnHeader:focus-within,& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus':
        {
            outline: 'none',
        },
    '& .MuiDataGrid-row': {
        backgroundColor: '#ffffff',
    },
    '& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-hovered': {
        backgroundColor: '#ffffff',
    },
    '& .MuiDataGrid-row--lastVisible .MuiDataGrid-cell': {
        borderBottomColor: 'rgba(224, 224, 224, 1) !important',
    },
    '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
        padding: '0 16px',
    },
    '& .MuiDataGrid-columnHeaders': {
        backgroundColor: 'var(--ping-Grey-grey-100)',
        color: 'var(--ping-Grey-grey-500)',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        textTransform: 'capitalize',
        fontSize: '14px',
        userSelect: 'none',
    },
    '& .MuiDataGrid-footerContainer': {
        backgroundColor: '#ffffff',
        borderTop: 0,
    },
    '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows, & .MuiDataGrid-selectedRowCount': {
        fontSize: '14px',
        fontFamily: 'inherit',
        color: 'inherit',
        fontWeight: 500,
        letterSpacing: 0,
    },
};
