import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import ControledDialog from 'components/_common/Dialog/ControledDialog';
import ServiceCell from 'components/_common/TableCells/ServiceCell';
import RadioGroupCards from 'components/_common/RadioGroupCards';
import SimpleSelect from 'components/_common/Select/SimpleSelect';
import CopyButton from 'components/Buttons/CopyButton';
import Spinner from 'components/_common/Spinner';
import ProxyUserDropdown from 'components/ProxyUserDropdown';
import { exportProxyAuthTypes, exportProxyAuthTypesOptions } from 'helpers/constants';

import ProxyService from 'api/services/ProxyService';

import './styles.scss';

const list_protocol_options = [
    { value: 'http', label: 'HTTP' },
    { value: 'socks5', label: 'SOCKS5' },
];

const QuickView = ({ isQuickViewModalOpen, setIsQuickViewModalOpen, service }) => {
    const [list_protocol, setListProtocol] = useState(list_protocol_options[0].value);
    const [authType, setAuthType] = useState(exportProxyAuthTypes.PROXY_USER_AUTH);
    const [user, setUser] = useState('');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [proxies, setProxies] = useState([]);

    const isUserAuthType = authType === exportProxyAuthTypes.PROXY_USER_AUTH;
    const isIPAuthType = authType === exportProxyAuthTypes.IP_AUTH;
    const isProxySpecificAuthType = authType === exportProxyAuthTypes.PROXY_SPECIFIC_AUTH;

    const loadData = async () => {
        try {
            setLoading(true);
            if (!proxies?.length) {
                return;
            }
            const params = {
                list_protocol,
                list_format: 'standard',
            };

            if (isUserAuthType && user) {
                params.list_authentication = 'username_and_password';
                params.proxy_user_id = user;
            }
            if (isIPAuthType) {
                params.list_authentication = 'ip_address';
            }
            if (isProxySpecificAuthType) {
                params.list_authentication = 'proxy_specific';
            }
            params.proxy_ids = proxies.map(i => i.proxy_id).join(',');

            await ProxyService.list_by_id(params)
                .then(response => {
                    setData(response.data.data);
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            setData([]);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const response = await ProxyService.search({ service_id: service.id, page: 1, per_page: 1000 });
                const proxies = response.data.data;
                setProxies(proxies);
            } catch (e) {
                return e;
            }
        })();
    }, []);

    useEffect(() => {
        loadData();
    }, [proxies, list_protocol, authType, user]);

    return (
        <ControledDialog
            setOpen={setIsQuickViewModalOpen}
            open={isQuickViewModalOpen}
            minHeight={750}
            withCancel={false}
            title={'Proxy List'}
        >
            <div className="modal-container-wrapper">
                <div className="modal-container">
                    <ServiceCell data={service.service} />
                </div>
                <div className="margin-top-20">
                    <h6>Proxy protocol:</h6>
                    <RadioGroupCards
                        items={list_protocol_options}
                        value={list_protocol}
                        onChange={setListProtocol}
                        horizontal={true}
                    />
                </div>
                <div className="margin-top-20">
                    <h6>Authentication:</h6>
                    <div className="margin-top-8">
                        <SimpleSelect
                            fullWidth
                            label="Authentication Type"
                            options={exportProxyAuthTypesOptions}
                            value={authType}
                            onChange={setAuthType}
                        />
                    </div>
                    {isUserAuthType && (
                        <div className="margin-top-8">
                            <ProxyUserDropdown onChange={setUser} value={user} />
                        </div>
                    )}
                </div>
                {loading ? (
                    <div className="spinner-container">
                        <Spinner />
                    </div>
                ) : Boolean(data?.length) ? (
                    <div className="spinner-container">
                        <div className="scrollable-container">
                            <Typography>
                                {data.map((i, index) => (
                                    <span key={`${index}-${i}`}>
                                        {i}
                                        <br />
                                    </span>
                                ))}
                            </Typography>
                        </div>
                        <div className="copy-button-container">
                            <CopyButton copyValue={data.join('\n')} customText={'Copy To Clipboard'} fullWidth={true} />
                        </div>
                    </div>
                ) : (
                    <div className="spinner-container centered">Data not found</div>
                )}
            </div>
        </ControledDialog>
    );
};

export default QuickView;
