import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { service_types } from 'helpers/services';
import { convertToDollar } from 'helpers/payments';
import { countries } from 'components/_common/CountryDropdown/constants';
import { isObjectEmpty } from 'helpers';
import ProductsService from 'api/services/ProductsService';

const datacenter_desc =
    'High-speed, reliable proxies sourced from data centers, ideal for managing high-volume, concurrent requests.';
const datacenter_list = ['Affordable datacenter proxies', 'Private & Dedicated', '99.97% Uptime'];

const isp_desc =
    'Combine the stability of datacenter proxies with the legitimacy of residential IPs, perfect for tasks requiring long sessions.';
const isp_list = ['Comcast, Spectrum, AT&T, DTAG & more', '1 Gbps+ Speed', 'Unlimited bandwidth'];

const residential_desc =
    'Offer genuine IP addresses from residential connections, providing high anonymity and low block-rate for web scraping.';
const residential_list = ['35 millions+ real residential IPs', '195+ countries', 'Less than 0.5 second connect times'];

export const fetchProducts = createAsyncThunk('fetchProducts', async () => {
    const response = await ProductsService.search();
    return response.data;
});

const ProductsSlice = createSlice({
    name: 'ProductsSlice',
    initialState: {
        products: {
            data: [],
            loading: false,
        },
        calculation: {
            data: {},
        },
    },
    reducers: {
        setCalculationInfo: (state, action) => {
            state.calculation.data = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchProducts.pending, state => {
                state.products.loading = true;
            })
            .addCase(fetchProducts.fulfilled, (state, action) => {
                state.products.loading = false;
                state.products.data = action.payload.data;
            });
    },
});

export const selectProducts = createSelector([state => state.productsState.products], products => products.data);
export const selectProductsLoading = createSelector(
    [state => state.productsState.products],
    products => products.loading,
);

const createCountriesOptions = (data, type) =>
    data
        .filter(product => product.product_type === type && product.product_is_active)
        .map(product => {
            const label = countries.find(country => country.code === product.country_id).label;
            return {
                country_id: product.country_id,
                product_instock: product.product_instock,
                product_stock: product.product_stock,
                label,
            };
        })
        .toSorted((x, y) => (x === y ? 0 : x ? -1 : 1));

export const selectCountriesOptionsISP = createSelector([selectProducts], data =>
    createCountriesOptions(data, service_types.isp),
);

export const selectCountriesOptionsDatacenter = createSelector([selectProducts], data =>
    createCountriesOptions(data, service_types.datacenter),
);

export const selectProductsIsLoading = createSelector(
    [state => state.productsState.products],
    products => products.loading,
);

export const selectDatacenterProxies = createSelector(
    [selectProducts],
    products =>
        products?.find(product => product.product_type === service_types.datacenter && product.country_id === 'us') ||
        {},
);

export const selectStaticResidentialProxies = createSelector(
    [selectProducts],
    products =>
        products?.find(product => product.product_type === service_types.isp && product.country_id === 'us') || {},
);

export const selectResidentialProxies = createSelector(
    [selectProducts],
    products => products?.find(product => product.product_type === service_types.residential) || {},
);

const getPricePerMonth = data =>
    data?.find(item => item.price_cycle_interval === 'month' && item.price_cycle_interval_count === 1).price_tiers?.[0]
        .price_tier_amount;

export const selectMainProducts = createSelector(
    [selectDatacenterProxies, selectStaticResidentialProxies, selectResidentialProxies],
    (datacenter, isp, residential) => {
        const datacenter_price = ['Starting at', convertToDollar(getPricePerMonth(datacenter.product_prices)), '/ IP'];
        const isp_price = ['Starting at', convertToDollar(getPricePerMonth(isp.product_prices)), '/ IP'];
        const residential_price = [
            'Starting at',
            convertToDollar(getPricePerMonth(residential.product_prices)),
            '/ GB',
        ];
        return {
            [datacenter.product_id]: {
                ...datacenter,
                title: 'Static Dedicated Datacenter Proxy',
                price: datacenter_price,
                list: datacenter_list,
                product_description: datacenter.product_description || datacenter_desc,
            },
            [isp.product_id]: {
                ...isp,
                title: 'Static Dedicated Residential Proxy',
                price: isp_price,
                product_description: isp.product_description || isp_desc,
                list: isp_list,
            },
            [residential.product_id]: {
                ...residential,
                title: 'Residential Bandwidth',
                price: residential_price,
                product_description: residential.product_description || residential_desc,
                list: residential_list,
            },
        };
    },
);

export const selectMainProductsArray = createSelector([selectMainProducts], data => Object.values(data));

export const selectCalculationInfo = createSelector([state => state.productsState.calculation], calculation => {
    const { data } = calculation;
    return {
        has_data: !isObjectEmpty(data),
        credit_balance: convertToDollar(data.credit_balance),
        before_discount_total: convertToDollar(data.before_discount_total),
        discounted: data.discounted,
        total_after_applied_credit: convertToDollar(data.total_after_applied_credit),
        customer_credit_balance_applied: data.customer_credit_balance_applied,
        customer_credit_balance_applied_amount: convertToDollar(data.customer_credit_balance_applied_amount),
    };
});

export const { setCalculationInfo } = ProductsSlice.actions;

export default ProductsSlice.reducer;
