import ClearFiltersButton from 'components/Buttons/ClearFilters';
import Select from 'components/_common/Select/SimpleSelect';
import { services_type_options, service_statuses } from 'helpers/services';
import { useSelector } from 'react-redux';

const SummaryFilters = ({ service, setService, status, setStatus }) => {
    const handleChangeServices = value => setService(value);
    const handleChangeStatuses = value => setStatus(value);

    const handleClear = () => {
        setService('');
        setStatus('');
    };

    const showClear = service || status;

    return (
        <>
            <Select label="Statuses" options={service_statuses} value={status} onChange={handleChangeStatuses} />
            <Select
                label="Services"
                options={services_type_options}
                value={service}
                onChange={handleChangeServices}
                withIcon={true}
            />
            <ClearFiltersButton onClick={handleClear} isShow={!!showClear} />
        </>
    );
};

export default SummaryFilters;
