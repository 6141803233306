import src1 from 'images/payment-methods-black-sidebar/PaymentMethod-1.svg';
import src2 from 'images/payment-methods-black-sidebar/PaymentMethod-2.svg';
import src3 from 'images/payment-methods-black-sidebar/PaymentMethod-3.svg';
import src4 from 'images/payment-methods-black-sidebar/PaymentMethod-4.svg';
import src5 from 'images/payment-methods-black-sidebar/PaymentMethod-5.svg';

const images = [src1, src2, src3, src4, src5];

const PaymentsMethods = () => (
    <div className="sidebar-payments">
        {images.map((src, index) => (
            <img key={index} src={src} alt="" />
        ))}
    </div>
);

export default PaymentsMethods;
