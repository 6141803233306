import './styles.scss';
import SidebarRow from './Items/SidebarRow';
import SidebarDivider from './Items/SidebarDivider';
import MessageText from 'components/MessageText';
import Button from 'components/_common/Button';
import PaymentsMethods from './Items/PaymentsMethods';
import PaymentCellByID from 'components/_common/TableCells/PaymentCellByID';
import DownloadButton from 'components/Buttons/DownloadButton';

const InvoiceSidebar = ({
    invoice_status,
    payment_method_id,
    datetime,
    invoice_amount_paid,
    invoice_total,
    handleNextStep,
    invoice_pdf,
}) => {
    return (
        <div className="order-summary-sidebar-container">
            {invoice_status === 'paid' && (
                <>
                    <div className="order-summary box">
                        <h5>Transactions</h5>
                        <MessageText type="good" text="Succeeded" small />
                        <SidebarRow name="Payment Method:">
                            <PaymentCellByID id={payment_method_id} />
                        </SidebarRow>
                        <SidebarDivider />
                        <SidebarRow name="Datetime:">{datetime}</SidebarRow>
                        <SidebarRow name="Invoice amount paid:">{invoice_amount_paid}</SidebarRow>
                        <SidebarDivider />
                        <SidebarRow name="Total amount:">
                            <h4>{invoice_total}</h4>
                        </SidebarRow>
                    </div>
                    <div className="order-submit-container">
                        <DownloadButton link={invoice_pdf} fullWidth />
                    </div>
                </>
            )}
            {invoice_status === 'open' && (
                <>
                    <div className="order-summary box">
                        <SidebarRow name="Total">
                            <h4>{invoice_total}</h4>
                        </SidebarRow>
                    </div>
                    <div className="order-submit-container">
                        <Button variant="contained" fullWidth size="large" onClick={handleNextStep}>
                            Continue to checkout
                        </Button>
                        <div className="mt-16">
                            <DownloadButton link={invoice_pdf} fullWidth />
                        </div>
                        <PaymentsMethods />
                    </div>
                </>
            )}

            {invoice_status === 'error' && (
                <div className="order-summary error box">
                    <h5>Transactions</h5>
                    <MessageText type="bad" text="An error occurred" small />
                    <p>
                        An error occurred during the payment, please try again later or contact our support team to
                        resolve this issue if it happens again.
                    </p>
                </div>
            )}
        </div>
    );
};

export default InvoiceSidebar;
