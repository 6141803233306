import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import { ReactComponent as Chevron } from 'icons/chevron-down.svg';
import { getMenuProps } from './constants';
import { FormLabel } from '@mui/material';
import Radio from '../Radio';

const SimpleSelect = ({
    label,
    options,
    value,
    onChange,
    withFormik = false,
    name = '',
    width = 140,
    menuWidth = 'auto',
    fullWidth = false,
    withFormLabel = false,
    withRadio = false,
    withIcon = false,
    defaultPlaceholder = '',
}) => {
    const handleChange = event => {
        onChange(event.target.value);
    };

    const menuProps = getMenuProps(menuWidth);

    return (
        <FormControl sx={{ width: fullWidth ? '100%' : width }} className="custom-select">
            {withFormLabel && <FormLabel focused={false}>{label}</FormLabel>}
            <Select
                // @ts-ignore
                MenuProps={menuProps}
                inputProps={{name}}
                size="small"
                value={value}
                disabled={!options.length}
                input={<OutlinedInput />}
                onChange={withFormik ? onChange : handleChange}
                IconComponent={Chevron}
                displayEmpty
                defaultValue=""
                renderValue={selected => {
                    if (!selected) return <span>{defaultPlaceholder || label}</span>;
                    return options.find(item => item.value === selected)?.label;
                }}
            >
                {options.map(item => {
                    return (
                        <MenuItem
                            value={item.value}
                            key={item.value}
                            className={withRadio ? 'custom-option-with-radio' : ''}
                        >
                            {withRadio && <Radio checked={item.value === value} />}
                            {withIcon && <div className="select-icon">{item.icon ? item.icon() : null}</div>}
                            {item.label}
                            {item.Label}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

export default SimpleSelect;
