import ExportButton from 'components/Buttons/ExportButton';
import Button from 'components/_common/Button';
import RadioGroup from 'components/_common/RadioGroup';
import { useState } from 'react';
import FileSaver from 'file-saver';
import dayjs from 'dayjs';
import ControledDialog from 'components/_common/Dialog/ControledDialog';

const options = [{ value: 'TXT', label: 'TXT' }];

const ModalExportTerminalInfo = ({ data, setOpen, open, disabled }) => {
    const [value, setValue] = useState(options[0].value);
    const handleClick = () => {
        try {
            const fileName = `Export ${dayjs().format('DD-MM-YYYY (HH-mm)')}.txt`;
            const blob = new Blob(
                data.map(row => `${row}\n`),
                { type: 'text/plain;charset=utf-8' },
            );
            FileSaver.saveAs(blob, fileName);
        } finally {
            setOpen(false);
        }
    };

    return disabled ? (
        <ExportButton disabled />
    ) : (
        <ControledDialog
            title="Export"
            Submit={<Button onClick={handleClick}>Export</Button>}
            setOpen={setOpen}
            open={open}
            Trigger={<ExportButton />}
        >
            <RadioGroup label="Output format" items={options} value={value} onChange={setValue} />
        </ControledDialog>
    );
};

export default ModalExportTerminalInfo;
