import './styles.scss';
import { useLocation } from 'react-router-dom';
import SidebarRow from 'components/Sidebars/Items/SidebarRow';

const SuccessfulSidebar = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    // @ts-ignore
    const line_items = { ...JSON.parse(params.get('line_items').replaceAll("'", '"'))[0] };

    const checkout_id = params.get('checkout_id');
    const { price, quantity } = line_items;

    return (
        <div className="order-summary-sidebar-container">
            <div className="order-summary box successful-sidebar">
                <h5>Order summary</h5>
                <SidebarRow name="Price ID">{price}</SidebarRow>
                <SidebarRow name="Quantity">{quantity}</SidebarRow>
                <SidebarRow name="Checkout ID">{checkout_id}</SidebarRow>
            </div>
        </div>
    );
};

export default SuccessfulSidebar;
