import ApiRequestChart from 'components/ApiRequestChart';
import Chart from 'components/_common/Chart';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filters from './Filters';
import { selectCustomer } from 'store/slices/customer';
import dayjs from 'dayjs';
import { calculateInterval, convertDate } from 'helpers';
import {
    fetchApiRequestsAnalytict,
    selectApiRequestsAnalytictChartData,
    selectApiRequestsAnalytictMetrics,
} from 'store/slices/apiRequests';

const Bandwidth = () => {
    const dispatch = useDispatch();
    const metrics = useSelector(selectApiRequestsAnalytictMetrics);
    const { labels, errors, requests, successful } = useSelector(selectApiRequestsAnalytictChartData);
    const { customer_creation_datetime } = useSelector(selectCustomer);

    const initialStart = dayjs(customer_creation_datetime);
    const initialEnd = dayjs();

    const [hasError, setError] = useState('');
    const [limits, setLimits] = useState({ minStartDateTime: null, maxEndDateTime: null });
    const [range, setRange] = useState({ start: null, end: null });

    const { minStartDateTime, maxEndDateTime } = limits;
    const { start, end } = range;

    useEffect(() => {
        setRange({ start: initialStart, end: initialEnd });
        setLimits({ minStartDateTime: initialStart, maxEndDateTime: initialEnd });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const min_api_request_creation_datetime = convertDate(start);
        const max_api_request_creation_datetime = convertDate(end);
        const interval = calculateInterval(min_api_request_creation_datetime, max_api_request_creation_datetime);
        const cond =
            dayjs(min_api_request_creation_datetime).isValid() &&
            dayjs(max_api_request_creation_datetime).isValid() &&
            dayjs(start).isBefore(end) &&
            dayjs(end).subtract(1, 'hour').isAfter(start) &&
            !hasError;

        if (cond) {
            const params = {
                min_api_request_creation_datetime,
                max_api_request_creation_datetime,
                interval,
            };
            // @ts-ignore
            dispatch(fetchApiRequestsAnalytict(params));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [start, end]);

    const chartData = [
        { data: errors, label: 'errors', area: true, showMark: false, color: '#ff6767' },
        { data: requests, label: 'requests', area: true, showMark: false, color: '#8b8bff' },
        { data: successful, label: 'successful', area: true, showMark: false, color: '#93ff93' },
    ];

    const handleSetRange = useCallback(values => {
        const [start, end] = values;
        setRange({ start, end });
    }, []);

    const formattedLabels = useMemo(() => {
        const min_log_request_datetime = convertDate(start);
        const max_log_request_datetime = convertDate(end);
        const interval = calculateInterval(min_log_request_datetime, max_log_request_datetime);

        const formatStorage = {
            '1 minute': 'HH:mm',
            '10 minute': 'HH:mm',
            '1 hour': 'HH:mm',
            '1 day': 'MM-DD',
        };

        return labels.map(item =>
            dayjs
                .utc(item)
                .local()
                .format(formatStorage[interval] || 'YYYY-MM-DD HH:mm'),
        );
    }, [labels]);

    return (
        <ApiRequestChart
            title="API Analytics"
            filters={
                <Filters
                    range={range}
                    onError={setError}
                    setRange={handleSetRange}
                    maxEndDateTime={maxEndDateTime}
                    minStartDateTime={minStartDateTime}
                />
            }
            metricsData={metrics}
            chart={<Chart xLabels={formattedLabels} data={chartData} />}
        />
    );
};

export default Bandwidth;
