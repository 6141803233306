import { createSlice, createSelector } from '@reduxjs/toolkit';

const PaymentsSlice = createSlice({
    name: 'PaymentsSlice',
    initialState: {
        summaryPayments: {
            loading: false,
            data: [],
            error: null,
        },
    },
    reducers: {
        pushMethodInfo: (state, action) => {
            if (!state.summaryPayments.data.some(item => item.payment_method_id === action.payload.payment_method_id)) {
                state.summaryPayments.data.push(action.payload);
            }
        },
    },
});

export const selectPayments = createSelector(
    [state => state.paymentsState.summaryPayments],
    summaryPayments => summaryPayments.data,
);
export const { pushMethodInfo } = PaymentsSlice.actions;

export default PaymentsSlice.reducer;
