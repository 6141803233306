import './styles.scss';
import CustomDateTimePicker from '../DateTimePicker';

const DateTimePickerRange = ({ range, setRange, minStartDateTime, maxEndDateTime, onError }) => {
    return (
        <div className="custom-date-time-picker-range">
            <CustomDateTimePicker
                value={[range?.start || null, range?.end || null]}
                onChange={setRange}
                minDateTime={minStartDateTime}
                maxDateTime={maxEndDateTime}
                onError={onError}
            />
        </div>
    );
};

export default DateTimePickerRange;
