import * as yup from 'yup';

const email_validation = yup
    .string()
    .email('Enter a valid email')
    .matches(/^[^+]*$/, 'We do not accept + alias email addresses')
    .required('Email is required');

const password_validation = yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .matches(/[0-9]/, 'Password must contain at least one digit')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter.')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter.')
    .matches(/^\S+$/, 'Password must not contain spaces.')
    .required('Password is required');

const name_validation = yup
    .string()
    .matches(/^[a-zA-Z]+$/, 'Only alphabetic characters')
    .min(1, 'This field should be of minimum 1 characters length')
    .max(25, 'This field should be of maximum 25 characters length')
    .required('This field is required');

const proxy_user_validation = yup
    .string()
    .matches(/^[a-zA-Z0-9]+$/, 'Only alphanumeric characters')
    .min(6, 'This field should be of minimum 6 characters length')
    .max(16, 'This field should be of maximum 16 characters length');

export const loginValidationSchema = yup.object({
    customer_email_address: email_validation,
    customer_password: password_validation,
});

export const registrationValidationSchema = yup.object({
    customer_email_address: email_validation,
    customer_password: password_validation,
    customer_first_name: name_validation,
    customer_last_name: name_validation,
});

export const editUserInfoValidationSchema = yup.object({
    customer_first_name: name_validation,
    customer_last_name: name_validation,
    customer_email_address: email_validation,
});

export const resetPasswordValidationSchema = yup.object({
    customer_email_address: email_validation,
    customer_password: password_validation,
    repeat_password: password_validation.oneOf([yup.ref('customer_password'), null], 'Passwords must match'),
});

export const proxyUserValidationSchema = yup.object({
    proxy_user_id: proxy_user_validation,
    proxy_user_password: proxy_user_validation,
});

export const proxyEditUserValidationSchema = yup.object({
    proxy_user_password: proxy_user_validation,
});

export const changePasswordValidationSchema = yup.object({
    customer_password: password_validation,
    repeat_password: password_validation.oneOf([yup.ref('customer_password'), null], 'Passwords must match'),
});
