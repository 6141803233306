import $api from 'api/http';
import urls from 'api/urls-config.json';

export default class CustomerService {
    static async retrieve() {
        return $api.get(urls.customer.retrieve);
    }
    static async edit(data) {
        return $api.patch(urls.customer.edit, data);
    }
}
