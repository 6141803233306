import { ReactComponent as Icon } from 'icons/colored/empty_bell.svg';

const NoData = ({ text }) => (
    <div className="messages-list-empty">
        <Icon />
        <div className="subtitle1">{text}</div>
    </div>
);

export default NoData;
