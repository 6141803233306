import {
    developerItems,
    servicesItems,
    helpItems,
    myProxyItems,
    staticProxyItems,
    billingItems,
} from 'helpers/constants';
import ButtonBuyNewProxies from './Items/ButtonBuyNewProxies';
import SidebarNavLink from './Items/SidebarNavLink';
import ROUTES from 'helpers/routes';

import MenuDropdown from './Items/MenuDropdown';

import { useSelector } from 'react-redux';
import { selectCustomer } from 'store/slices/customer';

const SidebarBody = () => {
    const { customer_id, customer_email_address } = useSelector(selectCustomer);

    const customerData = [
        { label: 'customer_id', value: String(customer_id) },
        { label: 'customer_email_address', value: customer_email_address },
    ];

    return (
        <div className="sidebar-body">
            <ButtonBuyNewProxies />
            <SidebarNavLink to={ROUTES.dashboard} text="dashboard" />
            <div>
                <div className="sidebar-title">Service Management</div>
                {servicesItems.map(data => {
                    if (data.to === ROUTES.billingSubscriptions) {
                        return (
                            <MenuDropdown
                                key={data.to}
                                path={'/billing'}
                                items={billingItems}
                                to={data.to}
                                text={data.text}
                            />
                        );
                    }
                    return <SidebarNavLink key={data.to || data.text} {...data} />;
                })}
            </div>
            <div>
                <div className="sidebar-title">My Proxies</div>
                {myProxyItems.map(data => {
                    if (data.to === ROUTES.proxyList) {
                        return (
                            <MenuDropdown
                                key={data.to}
                                path={'/static-proxy'}
                                items={staticProxyItems}
                                to={data.to}
                                text={data.text}
                            />
                        );
                    }
                    return <SidebarNavLink key={data.to || data.text} {...data} />;
                })}
            </div>
            {process.env.REACT_APP_ENABLE_API_SECTION === 'TRUE' && (
                <div>
                    <div className="sidebar-title">Developer Section</div>
                    {developerItems.map(({ to, text, blank }) => {
                        const handleClick = blank ? () => window.open(to, '_blank') : undefined;
                        const _to = !blank ? to : undefined;
                        return <SidebarNavLink key={to} to={_to} icon={to} text={text} onClick={handleClick} />;
                    })}
                </div>
            )}
            <div>
                <div className="sidebar-title">Help</div>
                {helpItems.map(({ to, text, blank, onClick }) => {
                    const handleClick = blank ? () => window.open(to, '_blank') : undefined;
                    const _to = !blank ? to : undefined;
                    return (
                        <SidebarNavLink
                            key={to || text}
                            to={_to}
                            icon={to}
                            text={text}
                            onClick={() => onClick(customerData) || handleClick}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default SidebarBody;
