import ServicesService from 'api/services/ServicesService';
import showMessage from 'components/NoticeProvider/showMessage';
import Button from 'components/_common/Button';
import ControledDialog from 'components/_common/Dialog/ControledDialog';
import SimpleSelect from 'components/_common/Select/SimpleSelect';
import TextInput from 'components/_common/TextInput';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchBillingOrders } from 'store/slices/billingOrders';
import MessageText from 'components/MessageText';

const other = 'other';

const reasons_options = [
    { value: 'too_expensive', label: 'Too expensive' },
    { value: 'missing_features', label: 'Missing features' },
    { value: 'switched_service', label: 'Switched service' },
    { value: 'unused', label: 'Unused' },
    { value: 'customer_service', label: 'Customer service' },
    { value: 'too_complex', label: 'Too complex' },
    { value: 'low_quality', label: 'Low quality' },
    { value: other, label: 'Other (write message)' },
];

const ModalCancelSubscription = ({ setOpen, open, data }) => {
    const dispatch = useDispatch();
    const [reason, setReason] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const showMessageInput = reason === other;

    const disabled = !reason || (showMessageInput && !message.length);

    useEffect(() => {
        setReason('');
        setMessage('');
    }, [open]);

    const handleSubmit = async () => {
        const params = { service_id: data.id, cancel_feedback: reason };
        if (message && reason === other) params.cancel_comment = message;

        try {
            setLoading(true);
            await ServicesService.cancel(params)
                .then(response => {
                    showMessage(response.data.message, response.status);
                })
                .then(() => {
                    // @ts-ignore
                    dispatch(fetchBillingOrders());
                })
                .finally(() => {
                    setLoading(false);
                    setOpen(false);
                });
        } catch (e) {
            return false;
        }
    };

    return (
        <ControledDialog
            title="Cancel your subscription"
            Submit={
                <Button color="error" loading={loading} disabled={disabled} onClick={handleSubmit}>
                    Cancel my subscription
                </Button>
            }
            setOpen={setOpen}
            open={open}
        >
            <p>Why are you cancelling?</p>
            <SimpleSelect
                fullWidth
                label="Select Reason"
                options={reasons_options}
                value={reason}
                onChange={setReason}
            />
            {showMessageInput && (
                <TextInput
                    label="Please write your reason"
                    placeholder="Message"
                    value={message}
                    onChange={setMessage}
                />
            )}
            <div className="box-error mt-8">
                <ul
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 10,
                        listStyle: 'disc',
                    }}
                >
                    <li style={{ listStyle: 'disc' }}>
                        Canceling your subscription will result in immediate suspension of your services and loss of
                        your proxies.
                    </li>
                    <li style={{ listStyle: 'disc' }}>No refund will be issued and this can not be reversed.</li>
                </ul>
            </div>
        </ControledDialog>
    );
};

export default ModalCancelSubscription;
