import { enqueueSnackbar } from 'notistack';

const getVariant = status => {
    const variants = {
        200: 'success',
        201: 'success',
        success: 'success',
        error: 'error',
        warning: 'warning',
        info: 'info',
        default: 'default',
    };
    return variants[status] || 'default';
};

const showMessage = (message, status) => {
    const variant = getVariant(status);
    return enqueueSnackbar(message, { variant });
};

export default showMessage;
