import './styles.scss';
import CreateApiKeyButton from 'components/Buttons/CreateApiKeyButton';
import Dialog from 'components/_common/Dialog';
import TextInput from 'components/_common/TextInput';

const ModalCreateApiKey = ({ loading, onCreate, apiKeyLabel, setApiKeyLabel }) => {
    if (loading) return <CreateApiKeyButton />;

    return (
        <Dialog
            title="Create API key"
            Trigger={<CreateApiKeyButton />}
            Submit={<CreateApiKeyButton onClick={onCreate} withoutIcon loading={loading} />}
        >
            <span className="subtitle">Enter a name for the API Key</span>
            <TextInput value={apiKeyLabel} onChange={setApiKeyLabel} placeholder='API Key name' maxLength={64}/>
        </Dialog>
    );
};

export default ModalCreateApiKey;
