import $api from 'api/http';
import urls from 'api/urls-config.json';

export default class ResidentialService {
    static async summary() {
        return $api.get(urls.residential.summary);
    }
    static async list(params) {
        return $api.get(urls.residential.list, { params });
    }
}
