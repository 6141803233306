import MessageItem from './MessageItem';
import NoData from './NoData';

const Messages = ({ messages, noDataText }) => {
    return (
        <div className="messages-list">
            {messages.length ? (
                messages.map(
                    ({
                        notification_creation_datetime,
                        notification_content,
                        notification_id,
                        notification_read_datetime,
                    }) => (
                        <MessageItem
                            key={notification_id}
                            text={notification_content}
                            date={notification_creation_datetime}
                            isNew={!notification_read_datetime}
                        />
                    ),
                )
            ) : (
                <NoData text={noDataText} />
            )}
        </div>
    );
};

export default Messages;
