import Button from 'components/_common/Button';
import './styles.scss';
import { ReactComponent as Icon } from 'icons/chevron-left.svg';
import { ReactComponent as Image } from 'images/Illustration 404.svg';
import ROUTES from 'helpers/routes';

const Page404 = () => (
    <div className="page-404">
        <div className="page-404-content">
            <Image className="mb-24" />
            <h3 className="pb-16">Page not found</h3>
            <h6 className="text-grey-500 mb-24">
                It is possible that an incorrect address was entered or the page was deleted
            </h6>
            <Button to={ROUTES.dashboard} Icon={<Icon />}>
                Back to dashboard
            </Button>
        </div>
    </div>
);

export default Page404;
