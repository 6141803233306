import { useEffect, useState } from 'react';
import ControledDialog from 'components/_common/Dialog/ControledDialog';
import Spinner from 'components/_common/Spinner';
import Tabs from 'components/_common/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchReconfigureOptions,
    selectReconfigureOptionsError,
    selectReconfigureOptionsLoading,
    selectReconfigureParams,
} from 'store/slices/reconfigureService';
import ReconfigureTab from './Items/ReconfigureTab';
import SubmitButton from './Items/SubmitButton';
import ServicesService from 'api/services/ServicesService';
import showMessage from 'components/NoticeProvider/showMessage';
import { FormLabel } from '@mui/material';
import FinalState from './Items/FinalState';
import { service_types } from 'helpers/services';
import { isEmpty } from 'lodash';

const ModalReconfigureService = ({
    setOpen,
    open,
    data = null,
    existingSecondData = null,
    isResidential = false,
    isExistingLoading = false,
    title = 'Reconfigure service',
    successMessage = 'Reconfiguration is complete',
}) => {
    const dispatch = useDispatch();
    const reconfigure_loading = useSelector(selectReconfigureOptionsLoading);
    const error = useSelector(selectReconfigureOptionsError);
    const params = useSelector(selectReconfigureParams);

    const { disabled } = params;
    const [loading, setLoading] = useState(false);
    const [secondData, setSecondData] = useState(existingSecondData);

    const isLoading = isExistingLoading || reconfigure_loading;

    const tabsData = [
        { value: '1', label: 'Immediate', content: <ReconfigureTab data={data} />, show: true },
        {
            value: '2',
            label: 'End of period',
            content: <ReconfigureTab end_of_period data={data} />,
            show: isResidential,
        },
    ];

    const initialTab = tabsData.find(i => i.show).value;

    const [activeTab, setActiveTab] = useState(initialTab);

    const handleChange = (event, newValue) => setActiveTab(newValue);

    useEffect(() => {
        if (open && data) {
            setSecondData(null);
            setActiveTab(initialTab);
            // @ts-ignore
            dispatch(fetchReconfigureOptions(data?.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
        if (error) {
            setOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const handleCalculate = async () => {
        try {
            setLoading(true);
            if (secondData || existingSecondData) {
                const calculatedParams = isEmpty(params)
                    ? {
                          service_id: existingSecondData?.service_id,
                          end_of_period: existingSecondData?.end_of_period,
                          service_price_id: existingSecondData?.service_price_id,
                          service_quantity: existingSecondData?.service_quantity,
                      }
                    : params;
                await ServicesService.reconfigure(calculatedParams)
                    .then(response => {
                        if (response.status === 200) {
                            showMessage(successMessage, 'success');
                        }
                        if (response.status === 201) {
                            showMessage('Subscription schedule created and reconfiguration is scheduled', 'success');
                        }
                        setOpen(false);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                await ServicesService.reconfigureCalculate(params)
                    .then(response => {
                        setSecondData(response.data);
                        showMessage(response.data.message, 'success');
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        } catch (e) {
            return false;
        }
    };

    const buttonText = secondData || existingSecondData ? 'Confirm' : 'Calculate';

    return (
        <ControledDialog
            title={title}
            open={open}
            setOpen={setOpen}
            Submit={
                <SubmitButton
                    onClick={handleCalculate}
                    loading={loading}
                    text={buttonText}
                    disabled={isLoading || disabled}
                />
            }
            minHeight={375}
        >
            {isLoading ? (
                <Spinner />
            ) : secondData || existingSecondData ? (
                <FinalState
                    secondData={existingSecondData || secondData}
                    isResidential={data?.service?.type === service_types.residential || isResidential}
                />
            ) : data ? (
                <div>
                    <FormLabel focused={false}>Schedule</FormLabel>
                    <Tabs
                        tabs={tabsData.filter(i => i.show)}
                        onChange={handleChange}
                        value={activeTab}
                        variant="fullWidth"
                    />
                </div>
            ) : null}
        </ControledDialog>
    );
};

export default ModalReconfigureService;
