import ServicesService from 'api/services/ServicesService';
import PageHeader from 'components/PageHeader';
import ProductCards from 'components/ProductCards';
import Spinner from 'components/_common/Spinner';
import ROUTES from 'helpers/routes';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { selectActiveResidentialServiceId } from 'store/slices/customer';
import { selectProductsLoading } from 'store/slices/products';

const AllProducts = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const productsLoading = useSelector(selectProductsLoading);
    const active_residential_service_id = useSelector(selectActiveResidentialServiceId);
    const [isActiveServiceLoading, setIsActiveServiceLoading] = useState(false);
    const [residentialServiceData, setResidentialServiceData] = useState(null);

    const isLoading = productsLoading || isActiveServiceLoading;
    const productTypeQuery = searchParams.get('product-type');
    const productValueQuery = searchParams.get('value');

    const retrieveResidentialService = async id => {
        try {
            setIsActiveServiceLoading(true);
            const response = await ServicesService.retrieve(id);
            setIsActiveServiceLoading(false);
            setResidentialServiceData(response?.data?.data?.[0]);
        } catch (error) {
            return false;
        }
    };

    useEffect(() => {
        if (active_residential_service_id) {
            retrieveResidentialService(active_residential_service_id);
        }
        if (productTypeQuery) {
            navigate(`${ROUTES.products}/${productTypeQuery}${productValueQuery ? `?value=${productValueQuery}` : ''}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <PageHeader title="All products" />
            {isLoading ? (
                <Spinner />
            ) : (
                <ProductCards
                    residentialServiceData={residentialServiceData}
                    retrieveResidentialService={retrieveResidentialService}
                />
            )}
        </div>
    );
};

export default AllProducts;
