import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { selectBillingOrdersTable } from './billingOrders';
import InvoicesService from 'api/services/InvoicesService';

export const fetchBillingInvoices = createAsyncThunk(
    'fetchBillingInvoices',
    async filters => {
        const response = await InvoicesService.search(filters);
        return response.data;
    },
    {
        condition: (_, { getState }) => {
            // @ts-ignore
            const { loading } = getState().billingInvoicesState.invoices;
            return !loading;
        },
    },
);

export const fetchOpenedBillingInvoice = async (page = 1) => {
    const response = await InvoicesService.search({ invoice_status: 'open', page, per_page: 1 });
    return response.data;
};

export const fetchSingleBillingInvoice = createAsyncThunk(
    'fetchSingleBillingInvoice',
    async slug => {
        const response = await InvoicesService.retrieve(slug);
        return response.data;
    },
    {
        condition: (_, { getState }) => {
            const { loading } = getState().billingInvoicesState.single;
            return !loading;
        },
    },
);

export const fetchSingleBillingInvoicePayOptions = createAsyncThunk(
    'fetchSingleBillingInvoicePayOptions',
    async id => {
        const response = await InvoicesService.payOptions(id);
        return response.data;
    },
    {
        condition: (_, { getState }) => {
            const { loading } = getState().billingInvoicesState.single;
            return !loading;
        },
    },
);

const billingInvoicesSlice = createSlice({
    name: 'billingInvoicesSlice',
    initialState: {
        invoices: {
            loading: false,
            total: 0,
            data: [],
            error: null,
        },
        single: {
            loading: false,
            data: [],
            error: null,
            payOptions: null,
        },
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchBillingInvoices.pending, state => {
                state.invoices.loading = true;
            })
            .addCase(fetchBillingInvoices.fulfilled, (state, action) => {
                state.invoices.loading = false;
                state.invoices.total = action.payload.total_count;
                state.invoices.data = action.payload.data;
            })
            .addCase(fetchBillingInvoices.rejected, (state, action) => {
                state.invoices.loading = false;
                state.invoices.error = action.error;
            });
        builder
            .addCase(fetchSingleBillingInvoice.pending, state => {
                state.single.loading = true;
            })
            .addCase(fetchSingleBillingInvoice.fulfilled, (state, action) => {
                state.single.loading = false;
                state.single.data = action.payload.data;
            })
            .addCase(fetchSingleBillingInvoice.rejected, (state, action) => {
                state.single.loading = false;
                state.single.error = action.error;
            });
        builder
            .addCase(fetchSingleBillingInvoicePayOptions.pending, state => {
                state.single.loading = true;
            })
            .addCase(fetchSingleBillingInvoicePayOptions.fulfilled, (state, action) => {
                state.single.loading = false;
                state.single.payOptions = action.payload.data;
            })
            .addCase(fetchSingleBillingInvoicePayOptions.rejected, (state, action) => {
                state.single.loading = false;
                state.single.error = action.error;
            });
    },
});

export const selectBillingInvoices = createSelector(
    [state => state.billingInvoicesState.invoices],
    invoices => invoices.data,
);

export const selectBillingInvoicesTable = createSelector(
    [selectBillingInvoices, selectBillingOrdersTable],
    (data, services) =>
        data.map(invoice => ({
            id: invoice.invoice_id,
            status: invoice.invoice_status,
            order_id: invoice.invoice_id,
            created: invoice.invoice_creation_datetime,
            payment_card: invoice.transactions[0]?.payment_method_id,
            service: services.find(service => service.id === invoice.service_id)?.service,
        })),
);

export const selectBillingInvoicesTotal = createSelector(
    [state => state.billingInvoicesState.invoices],
    invoices => invoices.total,
);

export const selectBillingInvoicesLoading = createSelector(
    [state => state.billingInvoicesState.invoices],
    invoices => invoices.loading,
);

export const selectSingleBillingInvoice = createSelector(
    [state => state.billingInvoicesState.single],
    single => single.data,
);

export const selectSingleBillingInvoiceLoading = createSelector(
    [state => state.billingInvoicesState.single],
    single => single.loading,
);

export const selectSingleBillingInvoicePayOptions = createSelector(
    [state => state.billingInvoicesState.single],
    single => single.payOptions,
);

export default billingInvoicesSlice.reducer;
