import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Burger from './Burger';

export const dontCloseClassName = 'dont-close-sidebar';

const SwipeableTemporaryDrawer = ({ open, setOpen, children }) => {
    const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
    const toggleDrawer = open => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpen(open);
    };
    const handleCloseWithCheck = e => {
        if (!e.target.classList.contains(dontCloseClassName)) setOpen(false);
    };

    return (
        <>
            <Burger onClick={toggleDrawer(true)} />

            <SwipeableDrawer
                open={open}
                anchor="right"
                onOpen={toggleDrawer(true)}
                onClose={toggleDrawer(false)}
                disableDiscovery={iOS}
                disableBackdropTransition={!iOS}
            >
                <Box
                    sx={{ width: 280 }}
                    role="presentation"
                    onClick={handleCloseWithCheck}
                    onKeyDown={toggleDrawer(false)}
                >
                    {children}
                </Box>
            </SwipeableDrawer>
        </>
    );
};

export default SwipeableTemporaryDrawer;
