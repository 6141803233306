import Table from 'components/_common/Table';
import TextCell from 'components/_common/TableCells/TextCell';
import React from 'react';
import ModalDeleteApiKey from 'components/Modals/ModalDeleteApiKey';
import StatusCell from 'components/_common/TableCells/StatusCell';

const ApiKeysTable = ({ data, dataLoading, fetchData, total, noDataComponent }) => {
    const columns = React.useMemo(
        () => [
            {
                field: 'status',
                headerName: 'status',
                flex: 1,
                minWidth: 100,
                renderCell: ({ value }) => <StatusCell status={value} />,
            },
            {
                field: 'label',
                headerName: 'Label',
                flex: 1,
                minWidth: 200,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'preview',
                headerName: 'Private key preview',
                flex: 1,
                minWidth: 200,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'api_key',
                headerName: 'API Key',
                flex: 1.2,
                minWidth: 300,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'created__sort',
                headerName: 'Created',
                flex: 1,
                minWidth: 180,
                renderCell: ({ value }) => <TextCell text={value} />,
            },
            {
                field: 'actions',
                type: 'actions',
                width: 80,
                renderCell: ({ row }) => {
                    return row.status !== 'deleted' && <ModalDeleteApiKey id={row.id} preview={row.preview} />;
                },
            },
        ],
        [],
    );

    return (
        <Table
            columns={columns}
            rows={data}
            dataLoading={dataLoading}
            fetchData={fetchData}
            total={total}
            noDataComponent={noDataComponent}
        />
    );
};

export default ApiKeysTable;