import ClearFiltersButton from 'components/Buttons/ClearFilters';
import SearchInput from '../../components/_common/SearchInput';
import FilterType from './Items/FilterType';
import ServicesFilter from './Items/ServicesFilter';
import CountryFilter from './Items/CountryFilter';

const ProxyListFilters = ({ type, setType, service, setService, country, setCountry, search, setSearch }) => {
    const handleClear = () => {
        setService('');
        setCountry('');
        setType('');
        setSearch('');
    };

    const showClear = Boolean(type.length || service.length || country.length || !!search);

    return (
        <>
            <ServicesFilter value={service} onChange={setService} />
            <CountryFilter value={country} onChange={setCountry} />
            <FilterType value={type} onChange={setType} />
            <SearchInput value={search} onChange={setSearch} placeholder='Search by IP' />
            <ClearFiltersButton onClick={handleClear} isShow={showClear} />
        </>
    );
};

export default ProxyListFilters;
