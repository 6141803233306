import React, { useState, useEffect } from 'react';

import { convertToDollar } from 'helpers/payments';

import ControledDialog from 'components/_common/Dialog/ControledDialog';
import Button from 'components/_common/Button';
import ModalPay from 'components/Modals/ModalPay';
import ServiceCell from 'components/_common/TableCells/ServiceCell';
import { fetchServiceById } from 'store/slices/services';
import InvoiceInfoBox from 'components/InvoiceInfoBox';
import List from 'components/InvoiceInfoBox/List';

const ModalInvoiceDetails = ({ data, setOpen, isOpen }) => {
    const [isPayModalOpen, setIsPayModalOpen] = useState(false);
    const [invoiceId, setInvoiceId] = useState('');
    const [service, setService] = useState(null);

    useEffect(() => {
        if (data.service_id) {
            (async () => {
                const response = await fetchServiceById(data.service_id);
                const item = response?.data?.[0];
                if (item) {
                    setService({
                        id: item.service_id,
                        type: item.service_type,
                        name: item.service_name,
                        value: item.service_quantity,
                    });
                }
            })();
        }
    }, [data.service_id]);

    const handlePay = invoiceId => {
        if (invoiceId) {
            setInvoiceId(invoiceId);
            setIsPayModalOpen(true);
        }
    };

    return (
        <ControledDialog
            title={data?.invoice_is_cart ? 'Complete Checkout' : 'Overdue Invoice'}
            setOpen={setOpen}
            open={isOpen}
            withCancel={false}
        >
            <div>
                {service && (
                    <div className="modal-container mb-24">
                        <ServiceCell data={service} />
                    </div>
                )}
                <div className="mb-24">
                    <h6>Total: {convertToDollar(data.invoice_total)}</h6>
                </div>
                {data?.service_id === null && (
                    <InvoiceInfoBox title="Invoice line items">
                        <List items={data?.invoice_line_items?.map(item => item.description)} />
                    </InvoiceInfoBox>
                )}
                <div className="box-error mt-24 mb-24">
                    <div style={{ textAlign: 'center' }}>
                        {data?.invoice_is_cart
                            ? 'Please pay invoice to complete your checkout. This invoice will expire in 24 hours if not paid.'
                            : 'Pay the invoice immediately to avoid termination or your proxies and services'}
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button onClick={() => handlePay(data.invoice_id)} variant="outlined">
                        Pay
                    </Button>
                </div>
                {isPayModalOpen && (
                    <ModalPay
                        isPayModalOpen={isPayModalOpen}
                        setIsPayModalOpen={setIsPayModalOpen}
                        invoiceId={invoiceId}
                    />
                )}
            </div>
        </ControledDialog>
    );
};

export default ModalInvoiceDetails;
