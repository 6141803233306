import Button from 'components/_common/Button';
import TextInput from 'components/_common/TextInput';
import './styles.scss';

const DiscountCodeForm = ({ value, onChange, loading, error, onSubmit, disabled }) => {
    return (
        <div className="discount-form">
            <TextInput
                error={error}
                helperText={error && 'Promotional Code not found'}
                disabled={loading || disabled}
                value={value}
                onChange={onChange}
                placeholder="Add discount code"
            />
            <Button
                variant="outlined"
                size="small"
                onClick={onSubmit}
                loading={loading}
                disabled={!value.length || disabled}
            >
                Apply
            </Button>
        </div>
    );
};

export default DiscountCodeForm;
