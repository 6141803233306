import Button from 'components/_common/Button';
import RadioGroup from 'components/_common/RadioGroup';
import { useState } from 'react';
import FileSaver from 'file-saver';
import dayjs from 'dayjs';
import ControledDialog from 'components/_common/Dialog/ControledDialog';

const options = [{ value: 'CSV', label: 'CSV' }];

const proxyReplacementFields = [
    'proxy_replacement_creation_datetime',
    'proxy_replacement_http_port',
    'proxy_replacement_id',
    'proxy_replacement_ip_address_ipv4',
    'proxy_replacement_ip_address_ipv6',
    'proxy_replacement_new_http_port',
    'proxy_replacement_new_ip_address_ipv4',
    'proxy_replacement_new_ip_address_ipv6',
    'proxy_replacement_new_socks5_port',
    'proxy_replacement_reason',
    'proxy_replacement_socks5_port',
];

const ModalExportServiceList = ({ open, setOpen, details }) => {
    const [value, setValue] = useState(options[0].value);

    const handleClick = async () => {
        try {
            const fileName = `Export ${dayjs().format('DD-MM-YYYY (HH-mm)')}.csv`;
            const blob = new Blob(
                [
                    proxyReplacementFields.join(','),
                    ...details.map(item => `\n ${proxyReplacementFields.map(key => item[key]).join(',')}`),
                ],
                { type: 'text/csv;charset=utf-8' },
            );
            FileSaver.saveAs(blob, fileName);
        } finally {
            setOpen(false);
        }
    };

    return (
        <ControledDialog
            title="Export"
            Submit={<Button onClick={handleClick}>Export</Button>}
            setOpen={setOpen}
            open={open}
        >
            <p className="body2">Export Replacement Details</p>
            <RadioGroup label="Output format" items={options} value={value} onChange={setValue} />
        </ControledDialog>
    );
};

export default ModalExportServiceList;
