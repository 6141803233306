import { useState } from 'react';
import TurnOffModal from './TurnOffModal';
import { useDispatch, useSelector } from 'react-redux';
import { customerRetrieve, selectCustomer } from 'store/slices/customer';
import Button from 'components/_common/Button';
import CustomerService from 'api/services/CustomerService';
import showMessage from 'components/NoticeProvider/showMessage';
import Modal2FA from 'components/Modals/Modal2FA';

const TwoStepAuthentication = () => {
    const dispatch = useDispatch();
    const { customer_email_two_factor_authentication } = useSelector(selectCustomer);

    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState('');
    const [open, setOpen] = useState(false);
    const [openTurnOff, setOpenTurnOff] = useState(false);
    const [fields, setFields] = useState(null);

    const handleSubmit = async () => {
        const params = { customer_email_two_factor_authentication: !customer_email_two_factor_authentication };
        try {
            setLoading(true);
            setOpenTurnOff(false);
            await CustomerService.edit(params)
                .catch(({ response }) => {
                    const { two_factor_authentication_public_key } = response.data.data;
                    setLoading(false);
                    if (response.status === 499) {
                        setFields({ ...params, two_factor_authentication_public_key });
                        setOpen(true);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            return false;
        }
    };

    const handleFinalSubmit = async () => {
        const params = { ...fields, two_factor_authentication_private_key: otp };
        try {
            setLoading(true);
            await CustomerService.edit(params)
                .then(response => {
                    setLoading(false);
                    showMessage(response.data.message, response.status);
                    setOpen(false);
                })
                .then(() => {
                    setOtp('');
                    // @ts-ignore
                    dispatch(customerRetrieve());
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            return false;
        }
    };
    return (
        <>
            {customer_email_two_factor_authentication ? (
                <TurnOffModal setOpen={setOpenTurnOff} open={openTurnOff} onClick={handleSubmit} />
            ) : (
                <Button variant="outlined" onClick={handleSubmit} loading={loading}>
                    Turn on two-step authentication
                </Button>
            )}
            <Modal2FA
                otp={otp}
                setOtp={setOtp}
                open={open}
                setOpen={setOpen}
                onSubmit={handleFinalSubmit}
                loading={loading}
                title="Confirm to enable two-step authentication"
                text="Are you sure you want to enable two-step authentication?"
            />
        </>
    );
};

export default TwoStepAuthentication;
