import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { ReactComponent as Dots } from 'icons/dots-vertical.svg';
import UserMenu from './UserMenu';
import { dontCloseClassName } from './SwipeableTemporaryDrawer';

const UserActions = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <div className={`user-actions ${dontCloseClassName}`}>
            <IconButton
                style={{ width: '40px' }}
                aria-haspopup="true"
                onClick={handleClick}
                className={dontCloseClassName}
            >
                <Dots className={dontCloseClassName} style={{ pointerEvents: 'none' }} />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    style: {
                        width: '180px',
                    },
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <UserMenu />
            </Menu>
        </div>
    );
};
export default UserActions;
