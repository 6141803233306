import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import ResidentialLedgerService from 'api/services/ResidentialLedgerService';
import ResidentialService from 'api/services/ResidentialService';
import dayjs from 'dayjs';
import { convertBandwidth } from 'helpers';
import { GB } from 'helpers/constants';

var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

export const fetchResidentialLedger = createAsyncThunk('fetchResidentialLedger', async params => {
    const response = await ResidentialLedgerService.search(params);
    return response.data;
});

export const fetchResidentialList = createAsyncThunk('fetchResidentialList', async params => {
    const response = await ResidentialService.list(params);
    return response.data;
});

const ResidentialLedgerSlice = createSlice({
    name: 'ResidentialLedgerSlice',
    initialState: {
        summary: {
            loading: false,
            data: [],
            error: null,
            total: 0,
        },
        list: {
            loading: false,
            data: [],
        },
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchResidentialLedger.pending, state => {
                state.summary.loading = true;
            })
            .addCase(fetchResidentialLedger.fulfilled, (state, action) => {
                state.summary.loading = false;
                state.summary.data = action.payload.data;
                state.summary.total = action.payload.total_count;
            })
            .addCase(fetchResidentialLedger.rejected, (state, action) => {
                state.summary.loading = false;
                state.summary.error = action.error;
            });
        builder
            .addCase(fetchResidentialList.pending, state => {
                state.list.loading = true;
            })
            .addCase(fetchResidentialList.fulfilled, (state, action) => {
                state.list.loading = false;
                state.list.data = action.payload.data;
            })
            .addCase(fetchResidentialList.rejected, state => {
                state.list.loading = false;
            });
    },
});

export const selectResidentialLedger = createSelector(
    [state => state.residentialLedgerState.summary],
    summary => summary.data,
);

export const selectResidentialLedgerTableData = createSelector([selectResidentialLedger], data =>
    data.map((item, index) => ({
        id: index,
        date: item.residential_ledger_creation_datetime,
        residential_ledger_reason: item.residential_ledger_reason,
        residential_ledger_creation_datetime: dayjs(item.residential_ledger_creation_datetime).format('YYYY-MM-DD'),
        residential_ledger_period_date: item.residential_ledger_period_date,
        residential_ledger_bytes: item.residential_ledger_bytes,
        bytes: `${convertBandwidth(Math.abs(item.residential_ledger_bytes), 'GB', 'byte')}`,
        requests: item.residential_ledger_requests || 0,
        last_usage: dayjs(item.residential_ledger_last_update_datetime).fromNow(),
    })),
);

export const selectResidentialLedgerLoading = createSelector(
    [state => state.residentialLedgerState.summary],
    summary => summary.loading,
);

export const selectResidentialLedgerTotal = createSelector(
    [state => state.residentialLedgerState.summary],
    summary => summary.total,
);

export const selectResidentialList = createSelector([state => state.residentialLedgerState.list], list => list.data);

export const selectResidentialListLoading = createSelector(
    [state => state.residentialLedgerState.list],
    list => list.loading,
);

export default ResidentialLedgerSlice.reducer;
