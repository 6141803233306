import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import './styles.scss';
import { ReactComponent as Icon1 } from 'icons/colored/statistic-bar-3.svg';
import { ReactComponent as Icon2 } from 'icons/colored/statistic-bar-2.svg';
import { ReactComponent as Icon3 } from 'icons/colored/statistic-bar-1.svg';
import StatisticItem from './StatisticItem';
import { selectCustomer } from 'store/slices/customer';
import { convertToDollar } from 'helpers/payments';
import { service_types } from 'helpers/services';

import ROUTES from 'helpers/routes';
import ModalTopUp from 'components/Modals/ModalTopUp';
import Label from 'components/_common/Label';

const StatisticBar = () => {
    const { residential_bytes_left, credit_balance, proxy_count, active_residential_service_id } =
        useSelector(selectCustomer);
    const formatBandwidth = bytes => {
        const units = ['MB', 'GB', 'TB', 'PB'];
        let unitIndex = 0;
        let value = bytes / (1024 * 1024); // Start with MB

        while (value >= 1000 && unitIndex < units.length - 1) {
            value /= 1024;
            unitIndex++;
        }

        return `${value.toFixed(2)} ${units[unitIndex]}`;
    };

    const residentialGB = residential_bytes_left / (1024 * 1024 * 1024);
    const convertedValue = formatBandwidth(residential_bytes_left);
    const convertedBalance = convertToDollar(credit_balance);

    const items = [
        {
            title: 'Proxies Number',
            tooltip: 'The number of static proxies assigned to your account',
            value: proxy_count,
            icon: <Icon1 />,
            action: (
                <NavLink to={proxy_count == 0 ? ROUTES.products : ROUTES.proxyList} replace={true}>
                    <Label color="green" text={proxy_count == 0 ? 'Buy Proxies' : 'View'} />
                </NavLink>
            ),
        },
        {
            title: 'Residential Bandwidth',
            tooltip: 'The amount of available residential bandwidth left on your account',
            value: convertedValue,
            icon: <Icon2 />,
            action: (
                <NavLink
                    to={
                        !active_residential_service_id
                            ? ROUTES.residential
                            : ROUTES.products + '/' + service_types.residential
                    }
                    replace={true}
                >
                    <Label
                        color="green"
                        text={active_residential_service_id ? 'Add Bandwidth' : 'Generate'}
                    />
                </NavLink>
            ),
        },
        {
            title: 'Balance',
            tooltip: 'The amount of store credit available on your account',
            value: convertedBalance,
            icon: <Icon3 />,
            action: <ModalTopUp labelButton={true} />,
        },
    ];

    return (
        <div className="statistic-bar">
            {items.map(props => (
                <StatisticItem key={props.title} {...props} />
            ))}
        </div>
    );
};

export default StatisticBar;
